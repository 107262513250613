// autogenerated
import FuelConsumption from "@/models/server/bout/FuelConsumption";

export default class SaveFuelConsumptionRequest {

    public fuelConsumptions: FuelConsumption[] | null = null;

    public readonly isSaveFuelConsumptionRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveFuelConsumptionRequest) || ((from as any).isSaveFuelConsumptionRequest === true));
    }

    public static as(from?: object | null): SaveFuelConsumptionRequest | null {
        return (SaveFuelConsumptionRequest.is(from)) ? from as SaveFuelConsumptionRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
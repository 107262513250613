// autogenerated

export default class BookingsDashboardInfoRequest {

    public from: Date = new Date();

    public readonly isBookingsDashboardInfoRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof BookingsDashboardInfoRequest) || ((from as any).isBookingsDashboardInfoRequest === true));
    }

    public static as(from?: object | null): BookingsDashboardInfoRequest | null {
        return (BookingsDashboardInfoRequest.is(from)) ? from as BookingsDashboardInfoRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated

export default class LogSaveCreditCardErrorRequest {

    public action: string | null = null;

    public responseJson: string | null = null;

    public errorJson: string | null = null;

    public readonly isLogSaveCreditCardErrorRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof LogSaveCreditCardErrorRequest) || ((from as any).isLogSaveCreditCardErrorRequest === true));
    }

    public static as(from?: object | null): LogSaveCreditCardErrorRequest | null {
        return (LogSaveCreditCardErrorRequest.is(from)) ? from as LogSaveCreditCardErrorRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

export default class CruisePackageImage {

    public id: string = "";

    public cruisePackageId: string = "";

    public fileId: string = "";

    public file: FileModel | null = null;

    public index: number = 0;

    public readonly isCruisePackageImage: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CruisePackageImage) || ((from as any).isCruisePackageImage === true));
    }

    public static as(from?: object | null): CruisePackageImage | null {
        return (CruisePackageImage.is(from)) ? from as CruisePackageImage : null;
    }

    // #region Placeholder

    public static create(file: FileModel, index: number = 0): CruisePackageImage {
        const image = new CruisePackageImage();
        image.file = file;
        image.fileId = image.id;
        image.index = index;
        return image;
    }

    public static alt(model?: CruisePackageImage | null): string {
        return model?.file?.description || model?.file?.name || "";
    }

    // #endregion
}
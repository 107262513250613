import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {LocalizationString, PageRoute} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize, PageContainer, PageRow} from "@reapptor-apps/reapptor-react-components";
import {UserPolicyType} from "@/models/Enums";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import Boat from "@/models/server/bout/Boat";
import ServiceProviderPolicy from "@/models/server/bout/ServiceProviderPolicy";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./Settings.module.scss";

interface ISettingsProps {
}

interface ISettingsState {
    homeLocationPageRoute: PageRoute | null;
    policies: ServiceProviderPolicy[];
}

export default class Settings extends AuthorizedPage<ISettingsProps, ISettingsState> {

    state: ISettingsState = {
        homeLocationPageRoute: null,
        policies: [],
    };

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.settingsPageTitle);
    }

    private getPolicyTitle(type: UserPolicyType): string {
        const policy: ServiceProviderPolicy | null = this.state.policies?.firstOrDefault(item => item.type == type) ?? null;
        return LocalizationString.value(policy?.title);
    }

    private getPolicyContent(type: UserPolicyType): string {
        const policy: ServiceProviderPolicy | null = this.state.policies?.firstOrDefault(item => item.type == type) ?? null;
        return LocalizationString.value(policy?.content);
    }

    public get user(): User {
        return this.getUser();
    }

    private get asCaptain(): boolean {
        return (AppController.asCaptain);
    }

    private get asPassenger(): boolean {
        return (AppController.asPassenger);
    }

    private get isEntrepreneur(): boolean {
        return (AppController.isEntrepreneur);
    }
    
    private async getHomeLocationPageRouteAsync(): Promise<PageRoute | null> {
        if (this.asCaptain) {
            const boats: Boat[] = await this.postAsync("/api/mobileApp/getMyBoats");
            
            if (boats.length == 1) {
                const boutId: string = boats[0].id;
                
                return PageDefinitions.editBoat(boutId, true);
            }
            
            return PageDefinitions.myBoatsRoute;
        }
        
        return null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const [homeLocationPageRoute, policies] = await Promise.all([
            this.getHomeLocationPageRouteAsync(),
            ServiceProviderController.listServiceProviderPoliciesAsync(),
        ]);
        
        await this.setState({ homeLocationPageRoute, policies });
    }

    public render(): React.ReactNode {

        const termsTitle: string = (this.asCaptain)
            ? this.getPolicyTitle(UserPolicyType.CaptainTerms)
            : this.getPolicyTitle(UserPolicyType.PassengerTerms);

        const termsContent: string = (this.asCaptain)
            ? this.getPolicyContent(UserPolicyType.CaptainTerms)
            : this.getPolicyContent(UserPolicyType.PassengerTerms);

        const cancellationPolicyTitle: string = this.getPolicyTitle(UserPolicyType.CancellationPolicy);
        const cancellationPolicyContent: string = this.getPolicyContent(UserPolicyType.CancellationPolicy);

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.settings, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                <PageRow>

                    <ButtonContainer>

                        {
                            (this.asCaptain) &&
                            (
                                <React.Fragment>

                                    <Button block
                                            right={false}
                                            type={ButtonType.Dark}
                                            icon={{name: "fal location"}}
                                            label={Localizer.genericHomeLocation}
                                            route={this.state.homeLocationPageRoute!}
                                    />

                                    {
                                        (this.isEntrepreneur) &&
                                        (
                                            <Button block
                                                    right={false}
                                                    type={ButtonType.Dark}
                                                    icon={{name: "fal fa-user-check"}}
                                                    label={Localizer.mobileKycPageTitle}
                                                    route={PageDefinitions.kycRoute}
                                            />
                                        )
                                    }

                                </React.Fragment>
                            )
                        }

                        {
                            ((this.asPassenger) || ((this.asCaptain) && (this.isEntrepreneur))) &&
                            (
                                <Button block
                                        right={false}
                                        type={ButtonType.Dark}
                                        icon={{name: "fal fa-credit-card"}}
                                        label={Localizer.myPaymentMethodsPageTitle}
                                        route={PageDefinitions.myPaymentMethodsRoute}
                                />
                            )
                        }

                        <Button block
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal user-headset"}}
                                label={Localizer.topNavContactSupport}
                                route={PageDefinitions.contactSupportRoute}
                        />

                        <Button block toggleModal
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal gavel"}}
                                disabled={!termsTitle}
                                dataTarget="agreementModal"
                                label={Localizer.mobileSettingsPageButtonTermsLabel}
                        />
                        
                        <Button block toggleModal
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal fa-ban"}}
                                disabled={!cancellationPolicyTitle}
                                dataTarget="cancellationPolicyModal"
                                label={Localizer.genericCancellationPolicy}
                        />

                        <Button block
                                right={false}
                                type={ButtonType.Dark}
                                icon={{name: "fal question-circle"}}
                                label={Localizer.genericFaq}
                                route={PageDefinitions.fAQRoute}
                        />

                    </ButtonContainer>

                </PageRow>
                
                <>

                    {
                        (termsContent) &&
                        (
                            <Modal id="agreementModal" info keepTextFormatting
                                   title={termsTitle}
                                   content={termsContent}
                                   size={ModalSize.Large}
                            />
                        )
                    }

                    {
                        (cancellationPolicyContent) &&
                        (
                            <Modal id="cancellationPolicyModal" info keepTextFormatting
                                   title={cancellationPolicyTitle}
                                   content={cancellationPolicyContent}
                                   size={ModalSize.Large}
                            />
                        )
                    }
                
                </>

            </PageContainer>
        );
    }
}
import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {Icon, PageContainer, PageRow,} from "@reapptor-apps/reapptor-react-components";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import {
    BasePageParameters,
    LocalizationString,
    PageRoute,
    PageRouteProvider
} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "@/models/base/AnonymousPage";
import CruisePackageCard from "@/components/CruisePackageCard/CruisePackageCard";
import PageDefinitions from "@/providers/PageDefinitions";
import FindCruisePackageRequest from "@/models/server/requests/FindCruisePackageRequest";
import FindCruisePackageResponse from "@/models/server/responses/FindCruisePackageResponse";
import AppConstants from "@/helpers/AppConstants";
import BookingReview from "@/models/server/bout/BookingReview";
import ImageProvider from "@/providers/ImageProvider";
import CruisePackageWizardController from "@/pages/Mobile/CruisePackageWizard/CruisePackageWizardController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./CruisePackageDetails.module.scss";

import thumbnail from "../../img/thumbnail.png";

export interface ICruisePackageDetailsProps extends BasePageParameters {
    path?: string | null;
}

interface ICruisePackageDetailsState {
    cruisePackage: CruisePackage | null;
    cruisePackageReviews: BookingReview[] | null;
}

export default class CruisePackageDetails extends AnonymousPage<ICruisePackageDetailsProps, ICruisePackageDetailsState> {

    state: ICruisePackageDetailsState = {
        cruisePackage: null,
        cruisePackageReviews: null,
    };

    private async closePreviewAsync(): Promise<void> {
        PageRouteProvider.back();
    }

    private async requestTripAsync(cruisePackage: CruisePackage): Promise<void> {
        await CruisePackageWizardController.requestCruisePackageAsync(cruisePackage);
    }
    
    private async fetchDataAsync(): Promise<void> {

        const cruisePackageId: string | null = ((this.cruisePackage) && (this.cruisePackage.id))
            ? this.cruisePackage.id
            : this.routeId;

        const cruisePackagePath: string | null = this.parameters?.path || null;

        if ((!cruisePackageId) && (!cruisePackagePath)) {
            await this.redirectHomeAsync();
            return;
        }

        const request = new FindCruisePackageRequest();
        request.id = cruisePackageId;
        request.path = cruisePackagePath;

        const response: FindCruisePackageResponse = await this.postAsync("/api/cruisePackage/findCruisePackage", request);

        if (!response.cruisePackage) {
            await this.redirectHomeAsync();
            return;
        }

        this.state.cruisePackage = response.cruisePackage;
        this.state.cruisePackageReviews = response.reviews;

        window.document.title = this.getTitle();

        await this.reRenderAsync();
    }

    private get cruisePackage(): CruisePackage | null {
        return this.state.cruisePackage;
    }

    private get cruisePackageReviews(): BookingReview[] {
        return this.state.cruisePackageReviews || [];
    }

    public getTitle(): string {
        return (this.cruisePackage)
            ? AppController.getFullPageTitle(LocalizationString.value(this.cruisePackage.name))
            : document.title
    }

    public static routeResolver(pathname: string): PageRoute | null {
        const prefix: string = AppConstants.cruisePackagesPrefix;
        if (pathname.toLowerCase().startsWith(prefix)) {
            const path: string = pathname.substring(prefix.length);
            return PageDefinitions.cruisePackageDetails(path);
        }

        return null;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        Utility.background(() => this.fetchDataAsync());
    }

    public renderReview(review: BookingReview): React.ReactNode {
        const name: string = review.name || Localizer.genericAnonymous;
        const rating: number[] = new Array<number>().repeat(0, review.rating);
        const date: string = "{0:MMM} {1:0000}".format(review.createdAt, review.createdAt.getFullYear());

        return (
            <div key={review.id} className={styles.review}>

                <div className={styles.header}>
                    <img src={ImageProvider.getRequiredImageSrc(review.avatar, thumbnail)}
                         alt={name}
                    />
                    <span>{name}</span>
                    <div className={styles.expander}/>
                    <div className={styles.likes}>
                        <Icon name="fal fa-thumbs-up" />
                        <span>{review.likes}</span>
                    </div>
                </div>

                <div className={styles.rating}>
                    {
                        rating.map((_, index) => <span key={index}>⭐</span>)
                    }
                </div>

                <span className={styles.title}>
                    {review.title}
                </span>

                <span className={styles.date}>
                    {date}
                </span>

                <span className={styles.text}>{review.text}</span>

                <hr/>
                
            </div>
        )
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={this.css(styles.cruisePackageDetails)}>
                
                <PageRow className={styles.pageRow}>
                    <div className="col">

                        {
                            (this.cruisePackage) &&
                            (
                                <CruisePackageCard details embedded
                                                   cruisePackage={this.cruisePackage}
                                                   onRequestTrip={(_, cruisePackage) => this.requestTripAsync(cruisePackage)}
                                                   onOrder={(_, cruisePackage) => this.requestTripAsync(cruisePackage)}
                                                   onClose={() => this.closePreviewAsync()}
                                />
                            )
                        }

                        {
                            (this.cruisePackageReviews.length > 0) &&
                            (
                                <div className={styles.reviews}>
                                    
                                    <hr/>

                                    <div className={styles.tab}>
                                        <span>{Localizer.genericReviews}</span>
                                    </div>

                                    { this.cruisePackageReviews.map(item => this.renderReview(item)) }
                                    
                                </div>
                            )
                        }

                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}
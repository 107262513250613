// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class EstimateShuttleBookingsRequest extends ETagRequest {

    public shuttleId: string = "";

    public sourceServicePointId: string = "";

    public destinationServicePointId: string = "";

    public bookingTime: Date = new Date();

    public passengers: number = 0;

    public adults: number = 0;

    public children: number = 0;

    public bikesAndTrolleys: number = 0;

    public pensioners: number = 0;

    public readonly isEstimateShuttleBookingsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof EstimateShuttleBookingsRequest) || ((from as any).isEstimateShuttleBookingsRequest === true));
    }

    public static as(from?: object | null): EstimateShuttleBookingsRequest | null {
        return (EstimateShuttleBookingsRequest.is(from)) ? from as EstimateShuttleBookingsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated

export default class ListCruisePackagesRequest {

    public countryId: string | null = null;

    public areaIds: string[] | null = null;

    public activeOnly: boolean = false;

    public showDeleted: boolean = false;

    public asCaptain: boolean = false;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListCruisePackagesRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListCruisePackagesRequest) || ((from as any).isListCruisePackagesRequest === true));
    }

    public static as(from?: object | null): ListCruisePackagesRequest | null {
        return (ListCruisePackagesRequest.is(from)) ? from as ListCruisePackagesRequest : null;
    }

    // #region Placeholder imports

    // #endregion
}
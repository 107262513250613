// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class GetShuttleBookingTicketRequest extends ETagRequest {

    public bookingId: string = "";

    public readonly isGetShuttleBookingTicketRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof GetShuttleBookingTicketRequest) || ((from as any).isGetShuttleBookingTicketRequest === true));
    }

    public static as(from?: object | null): GetShuttleBookingTicketRequest | null {
        return (GetShuttleBookingTicketRequest.is(from)) ? from as GetShuttleBookingTicketRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
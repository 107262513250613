// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {AuthType} from "@/models/Enums";

export default class SaveUserRequestBase {

    public id: string | null = null;

    public authType: AuthType = AuthType.Email;

    public organizationContractIds: string[] | null = null;

    public email: string | null = null;

    public phone: string | null = null;

    public firstname: string | null = null;

    public lastName: string | null = null;

    public externalId: string | null = null;

    public language: string | null = null;

    public roleNames: string[] | null = null;

    public waypointIds: string[] | null = null;

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public cancellationPolicyAccepted: boolean = false;

    public adult: boolean = false;

    public businessId: string | null = null;

    public serviceProviderId: string | null = null;

    public allowAsCaptain: boolean = false;

    public allowAsPassenger: boolean = false;

    public isEntrepreneur: boolean = false;

    public testUser: boolean = false;

    public countryId: string = "";

    public avatar: FileModel | null = null;

    public readonly isSaveUserRequestBase: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveUserRequestBase) || ((from as any).isSaveUserRequestBase === true));
    }

    public static as(from?: object | null): SaveUserRequestBase | null {
        return (SaveUserRequestBase.is(from)) ? from as SaveUserRequestBase : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
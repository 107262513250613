// autogenerated

export default class TimezoneInfoRequest {

    public time: Date | null = null;

    public timezoneOffset: number = 0;

    public readonly isTimezoneInfoRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof TimezoneInfoRequest) || ((from as any).isTimezoneInfoRequest === true));
    }

    public static as(from?: object | null): TimezoneInfoRequest | null {
        return (TimezoneInfoRequest.is(from)) ? from as TimezoneInfoRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import BoatImage from "@/models/server/bout/BoatImage";

export default class SaveMyBoatRequest {

    public id: string | null = null;

    public isElectricBoat: boolean = false;

    public isSpeedBoat: boolean = false;

    public isSailBoat: boolean = false;

    public petFriendly: boolean = false;

    public disabledFriendly: boolean = false;

    public toilet: boolean = false;

    public horsepower: number = 0;

    public maxCapacity: number = 0;

    public seats: number = 0;

    public cruiseSpeed: number = 0;

    public brand: string | null = null;

    public model: string | null = null;

    public images: BoatImage[] | null = null;

    public homeLocation: GeoLocation | null = null;

    public isBoat: boolean = false;

    public readonly isSaveMyBoatRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveMyBoatRequest) || ((from as any).isSaveMyBoatRequest === true));
    }

    public static as(from?: object | null): SaveMyBoatRequest | null {
        return (SaveMyBoatRequest.is(from)) ? from as SaveMyBoatRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {AccountHolderType} from "@/models/Enums";
import User from "@/models/server/User";
import Country from "@/models/server/bout/Country";

export default class BankAccount {

    public id: string = "";

    public holderName: string = "";

    public userId: string | null = null;

    public serviceProviderId: string | null = null;

    public user: User | null = null;

    public countryId: string = "";

    public country: Country | null = null;

    public accountHolderType: AccountHolderType = AccountHolderType.Individual;

    public bankName: string | null = null;

    public bic: string | null = null;

    public last4: number = 0;

    public default: boolean = false;

    public readonly isBankAccount: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof BankAccount) || ((from as any).isBankAccount === true));
    }

    public static as(from?: object | null): BankAccount | null {
        return (BankAccount.is(from)) ? from as BankAccount : null;
    }

    // #region Placeholder

    public static getIban(bankAccount: BankAccount): string {
        const countryCode: string = bankAccount.country?.code?.toUpperCase() ?? "";
        return "{0} **** **** **** {1:0000}".format(countryCode, bankAccount.last4).trim();
    }

    // #endregion
}
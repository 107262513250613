// autogenerated
import {PaymentPolicy} from "@/models/Enums";

export default abstract class BaseBooking {

    public paymentPolicy: PaymentPolicy = PaymentPolicy.CompletedByCaptain;

    public noRefund: boolean = false;

    public bookingTime: Date = new Date();

    public requestBookingTime: Date | null = null;

    public bookingTimeOffset: number | null = null;

    
    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated

export default class SaveServicePointRequest {

    public id: string | null = null;

    public place: string | null = null;

    public areaId: string = "";

    public areaAlias: string | null = null;

    public lat: number = 0;

    public lon: number = 0;

    public active: boolean = false;

    public readonly isSaveServicePointRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveServicePointRequest) || ((from as any).isSaveServicePointRequest === true));
    }

    public static as(from?: object | null): SaveServicePointRequest | null {
        return (SaveServicePointRequest.is(from)) ? from as SaveServicePointRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
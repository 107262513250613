// autogenerated

export default class AndroidAssetsSettings {

    public enabled: boolean = false;

    public packageName: string | null = null;

    public sha256CertFingerprints: string | null = null;

    public readonly isAndroidAssetsSettings: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof AndroidAssetsSettings) || ((from as any).isAndroidAssetsSettings === true));
    }

    public static as(from?: object | null): AndroidAssetsSettings | null {
        return (AndroidAssetsSettings.is(from)) ? from as AndroidAssetsSettings : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
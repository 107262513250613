// autogenerated

export default class SetFavoriteRequest {

    public id: string = "";

    public favorite: boolean = false;

    public readonly isSetFavoriteRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SetFavoriteRequest) || ((from as any).isSetFavoriteRequest === true));
    }

    public static as(from?: object | null): SetFavoriteRequest | null {
        return (SetFavoriteRequest.is(from)) ? from as SetFavoriteRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {NotificationType} from "@/models/Enums";

export default class SendPassengerCheckOutNotificationRequest {

    public passengerId: string = "";

    public message: string | null = null;

    public notificationType: NotificationType = NotificationType.Sms;

    public readonly isSendPassengerCheckOutNotificationRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SendPassengerCheckOutNotificationRequest) || ((from as any).isSendPassengerCheckOutNotificationRequest === true));
    }

    public static as(from?: object | null): SendPassengerCheckOutNotificationRequest | null {
        return (SendPassengerCheckOutNotificationRequest.is(from)) ? from as SendPassengerCheckOutNotificationRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
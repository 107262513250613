import React from "react";
import {BaseComponent, IBaseComponent, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import Container from "@/components/Container/Container";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import {BoatType} from "@/models/Enums";
import {Icon, IconSize} from "@reapptor-apps/reapptor-react-components";
import BookingAvatar, {BookingAvatarType} from "@/components/BookingAvatar/BookingAvatar";
import BoatImage from "@/models/server/BoatImage";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";
import Skeleton from "@/pages/Mobile/BoatSelection/Skeleton/Skeleton";
import Booking from "@/models/server/bout/Booking";
import Localizer from "@/localization/Localizer";

import styles from "./EstimatedBookingContainer.module.scss";

import boatTypeDefault from "./images/BoatTypeDefault.png"
import boatTypeContainer from "./images/BoatTypeContainer.png"
import boatTypeLarge from "./images/BoatTypeLarge.svg"
import boatTypeMedium from "./images/BoatTypeMedium.svg"
import boatTypeRib from "./images/BoatTypeRib.svg"
import boatTypeSail from "./images/BoatTypeSail.png"
import boatTypeSmall from "./images/BoatTypeSmall.svg"
import boatTypeClassic from "./images/BoatTypeClassic.png"
import boatTypeElectric from "./images/BoatTypeElectric.png"
import boatTypeWaterbus from "./images/BoatTypeWaterbus.png"
import boatTypeWaterbusElectric from "./images/BoatTypeWaterbusElectric.png"

export interface IEstimatedBookingContainerProps {
    bookings: EstimatedBooking[];
    maxAvailableCapacity: number;
    passengers: number;
    loading: boolean;
    isShuttle: boolean;
    onClick?(sender: IBaseComponent, item: EstimatedBooking): Promise<void>;
}

export default class EstimatedBookingContainer extends BaseComponent<IEstimatedBookingContainerProps> {

    private async onClickAsync(item: EstimatedBooking): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, item);
        }
    }

    private getBoatImage(boatType: BoatType): string {

        switch (boatType) {
            case BoatType.Waterbus:
                return boatTypeWaterbus;
            case BoatType.Large:
                return boatTypeLarge;
            case BoatType.Small:
                return boatTypeSmall;
            case BoatType.Medium:
                return boatTypeMedium;
            case BoatType.LargeSpeed:
            case BoatType.MediumSpeed:
                return boatTypeRib;
            case BoatType.Classic:
                return boatTypeClassic;
            case BoatType.SmallSail:
            case BoatType.MediumSail:
            case BoatType.LargeSail:
                return boatTypeSail;
            case BoatType.LargeElectrical:
            case BoatType.MediumElectrical:
            case BoatType.SmallElectrical:
                return boatTypeElectric;
            case BoatType.WaterbusElectical:
                return boatTypeWaterbusElectric;
        }

        return boatTypeDefault;
    }

    private renderAvatar(booking: EstimatedBooking, title: string): React.ReactNode {
        const singleBoatFound: boolean = (booking.boats.length == 1);

        const avatarId: string | null = (singleBoatFound)
            ? booking.boats.single().boat?.captain?.avatarId ?? null
            : null;

        const boatImage: BoatImage | null = (singleBoatFound)
            ? booking.boats.single().boat?.images?.firstOrDefault(item => item.primary) ?? null
            : null;

        const isShuttle: boolean = ShuttleEstimatedBooking.is(booking);
        const avatarType: BookingAvatarType = (isShuttle)
            ? BookingAvatarType.BoatOnly
            : BookingAvatarType.Boat;

        return (
            (singleBoatFound)
                ?
                (
                    <div className={styles.image}>

                        <BookingAvatar type={avatarType}
                                       avatar={avatarId}
                                       boatImage={boatImage}
                        />

                    </div>
                )
                :
                (
                    <div className={styles.image}>

                        <img className={styles.boatTypeContainer}
                             alt={title}
                             src={boatTypeContainer}
                        />

                        <img className={styles.boatType}
                             alt={title}
                             src={this.getBoatImage(booking.boatType)}
                        />

                    </div>
                )

        );
    }

    private renderShuttleAvailability(shuttleBooking: EstimatedBooking): React.ReactNode {
        const availabilityPercentage: number = 100 * (shuttleBooking.minCapacity / shuttleBooking.maxCapacity);

        let availabilityIndicator;
        let availabilityText;

        switch (true) {
            case availabilityPercentage > 50:
                availabilityIndicator = styles.greenAvailability;
                availabilityText = Localizer.mobileEstimatedBookingContainerHighAvailability;
                break;
            case availabilityPercentage > 20:
                availabilityIndicator = styles.yellowAvailability;
                availabilityText = Localizer.mobileEstimatedBookingContainerLimitedAvailability;
                break;
            case availabilityPercentage == 0:
                availabilityIndicator = styles.redAvailability;
                availabilityText = Localizer.mobileEstimatedBookingContainerNoMoreAvailability;
                break;
            default:
                availabilityIndicator = styles.redAvailability;
                availabilityText = Localizer.mobileEstimatedBookingContainerAlmostBooked;
        }

        return (
            <span className={styles.shuttleCapacity}>
                
                <span className={this.css(styles.circle, availabilityIndicator)}/>
                
                <span className={styles.availability}>{availabilityText}</span>
                
            </span>
        );
    }

    private renderRideCapacity(capacity: string): React.ReactNode {
        return (
            <span className={styles.rideCapacity}>

                <Icon name={"far fa-user"} size={IconSize.Large}/>

                <span>{capacity}</span>

            </span>
        );
    }

    private renderSkeletonItem(index: number): React.ReactNode {
        const shuttleStyle: any = (this.isShuttle) && styles.shuttle;
        
        return (
            <Container.Item key={index} className={this.css(styles.item, styles.skeleton, shuttleStyle)}>
                <Skeleton className={styles.context}>
                
                    <div className={styles.column1}>
                        <Skeleton.Circle size={56} />
                    </div>
    
                    <div className={styles.column2}>
    
                        <div className={styles.row1}>
                            <Skeleton.Rectangle height={14}/>
                            <Skeleton.Rectangle height={16} width={60}/>
                        </div>
    
                        <div className={styles.row2}>
                            <Skeleton.Rectangle height={14} width={80}/>
                            <Skeleton.Rectangle height={14} width={140}/>
                        </div>
    
                    </div>
    
                </Skeleton>
            </Container.Item>
        )
    }

    private renderEstimatedBooking(booking: EstimatedBooking | number, index: number): React.ReactNode {
        
        if (typeof booking === "number") {
            return this.renderSkeletonItem(booking);
        }
        
        let capacity: string = (booking.minCapacity != booking.maxCapacity)
            ? "{0:0} - {1:0}".format(booking.minCapacity, booking.maxCapacity)
            : "{0:0}".format(booking.minCapacity);

        let totalMinutes: string = (booking.minNetTimeInMinutes != booking.maxNetTimeInMinutes)
            ? "{0:0} - {1:0} min".format(booking.minNetTimeInMinutes, booking.maxNetTimeInMinutes)
            : "{0:0} min".format(booking.minNetTimeInMinutes);

        let stops: string = "";
        
        const price: string = (booking.minPrice != booking.maxPrice)
            ? "{0:# ##0.0} - {1:# ##0.0} {2}".format(booking.minPrice, booking.maxPrice, booking.country?.moneySymbol)
            : "{0:# ##0.0} {1}".format(booking.minPrice, booking.country?.moneySymbol);

        //const boatCategory: string = booking.boatType.format(nameof<BoatType>());
        const boatCategory: string = booking.boatPriceName;

        const shuttleBooking: ShuttleEstimatedBooking | null = ShuttleEstimatedBooking.as(booking);
        const isShuttle: boolean = (shuttleBooking != null);
        let available: boolean = true;

        if (shuttleBooking) {
            totalMinutes = Localizer.mobileBookingContainerTotalMinutes.format(booking.minNetTimeInMinutes);

            stops = (shuttleBooking.stopsCount > 0)
                ? Localizer.mobileBookingContainerStops.format(shuttleBooking.stopsCount)
                : "";

            capacity = "{0}/{1}".format(shuttleBooking.minCapacity, shuttleBooking.maxCapacity);

            available = (shuttleBooking.minCapacity >= this.passengers);
        }

        const shuttleStyle: any = (isShuttle) && styles.shuttle;
        const notAvailableStyle: any = (!available) && styles.notAvailable;

        return (
            <Container.Item key={index} className={this.css(styles.item, shuttleStyle, notAvailableStyle)}>
                <div key={index} className={styles.context} onClick={() => this.onClickAsync(booking)}>

                    {this.renderAvatar(booking, boatCategory)}

                    <div className={styles.type}>

                        {
                            (!shuttleBooking) &&
                            (
                                <span className={this.css(styles.value, styles.title)}>
                                    {ReactUtility.toTags(boatCategory)}
                                </span>
                            )
                        }

                        {
                            (shuttleBooking) &&
                            (
                                <span className={styles.schedule}>
                                    {ReactUtility.toTags("{0:t} - {1:t}".format(Booking.getCountryLocalTime(shuttleBooking, shuttleBooking.departureAt), Booking.getCountryLocalTime(shuttleBooking, shuttleBooking.arrivalAt)))}
                                </span>
                            )
                        }

                        {
                            (isShuttle)
                                ? this.renderShuttleAvailability(shuttleBooking!)
                                : this.renderRideCapacity(capacity)
                        }

                    </div>

                    <div className={styles.expander}/>

                    <div className={styles.price}>
                        {
                            (!isShuttle)
                                ?
                                (
                                    <span className={styles.totalMinutes}>{ReactUtility.toTags(totalMinutes)}</span>
                                )
                                :
                                (
                                    <span className={styles.totalMinutes}>
                                        <p>{ReactUtility.toTags(stops)}</p>
                                        <p>{ReactUtility.toTags(totalMinutes)}</p>
                                    </span>
                                )
                        }
                        <span className={styles.value}>{price}</span>
                    </div>

                </div>
            </Container.Item>
        );
    }

    public get bookings(): EstimatedBooking[] {
        return this.props.bookings;
    }

    public get maxAvailableCapacity(): number {
        return this.props.maxAvailableCapacity;
    }

    public get passengers(): number {
        return this.props.passengers;
    }

    public get loading(): boolean {
        return this.props.loading;
    }

    public get isShuttle(): boolean {
        return this.props.isShuttle;
    }
    
    private newIndexArray(length: number): number[] {
        const array: number[] = [];
        for (let index: number = 0; index < length; index++) {
            array.push(index);
        }
        return array;
    }

    public render(): React.ReactNode {
        const bookings: EstimatedBooking[] = this.bookings;
        const loading: boolean = this.loading;
        const hasData: boolean = (bookings.length > 0);

        const notEnoughCapacity: boolean = (!hasData) && (this.maxAvailableCapacity > 0);
        const noDataMessage: string = (notEnoughCapacity)
            ? Localizer.mobileEstimatedBookingContainerNoCapacity.format(this.maxAvailableCapacity)
            : Localizer.mobileEstimatedBookingContainerNoBouts;

        const items: (EstimatedBooking | number)[] = (loading)
            ? (bookings.length > 0)
                ? this.newIndexArray(bookings.length)
                : this.newIndexArray(2)
            : bookings;

        const noBookingsStyle: any = ((!loading) && (!hasData)) && styles.noBookings;
        
        return (
            <div id={this.id} className={this.css(styles.estimatedBookingContainer, noBookingsStyle)}>

                {
                    ((hasData) || (loading)) &&
                    (
                        <Container className={styles.container}>
                            {items.map((item, index) => this.renderEstimatedBooking(item, index))}
                        </Container>
                    )
                }

                {
                    ((!loading) && (!hasData)) &&
                    (
                        <span className={styles.message}>
                            {ReactUtility.toMultiLines(noDataMessage)}
                        </span>
                    )
                }

            </div>
        )
    }
}
// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class CompleteTripRequest {

    public bookingId: string = "";

    public location: GeoCoordinate | null = null;

    public readonly isCompleteTripRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CompleteTripRequest) || ((from as any).isCompleteTripRequest === true));
    }

    public static as(from?: object | null): CompleteTripRequest | null {
        return (CompleteTripRequest.is(from)) ? from as CompleteTripRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
import React from "react";
import AnonymousPage from "@/models/base/AnonymousPage";
import {Alert, PageContainer, PageRow} from "@reapptor-apps/reapptor-react-components";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import Booking from "@/models/server/bout/Booking";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import BasePageParameters from "@reapptor-apps/reapptor-react-common/src/models/BasePageParameters";
import {
    AlertModel,
    AlertType,
    ch,
    DataStorageType,
    PageRoute,
    PageRouteProvider,
    UserInteractionDataStorage
} from "@reapptor-apps/reapptor-react-common";
import BookingRateModal from "@/pages/Mobile/Home/BookingRateModal/BookingRateModal";
import PageDefinitions from "@/providers/PageDefinitions";
import {GeoCoordinate, Utility} from "@reapptor-apps/reapptor-toolkit";
import SetBookingCaptainRequest from "@/models/server/requests/SetBookingCaptainRequest";
import SetBookingCaptainResponse from "@/models/server/responses/SetBookingCaptainResponse";
import StartTripRequest from "@/models/server/requests/StartTripRequest";
import CompleteTripRequest from "@/models/server/requests/CompleteTripRequest";
import CompleteTripResponse from "@/models/server/responses/CompleteTripResponse";
import PayPendingPaymentRequest from "@/models/server/requests/PayPendingPaymentRequest";
import PayPendingPaymentResponse from "@/models/server/responses/PayPendingPaymentResponse";
import SetBookingRateRequest from "@/models/server/requests/SetBookingRateRequest";
import User from "@/models/server/User";
import {BookingStatus} from "@/models/Enums";
import GetMyBookingRequest from "@/models/server/requests/GetMyBookingRequest";
import GetMyBookingResponse from "@/models/server/responses/GetMyBookingResponse";
import ApplicationContext from "@/models/server/ApplicationContext";
import BookingInfo from "@/pages/Mobile/BookingDetails/BookingInfo/BookingInfo";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";
import BookTripResponse from "@/models/server/responses/BookTripResponse";
import BookTripRequest from "@/models/server/requests/BookTripRequest";
import DeclineTripRequest from "@/models/server/requests/DeclineTripRequest";
import NoShowRequest from "@/models/server/requests/NoShowRequest";
import CheckInRequest from "@/models/server/requests/CheckInRequest";
import TransformProvider from "@/providers/TransformProvider";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./BookingDetails.module.scss";

export interface IBookingDetailsProps extends BasePageParameters {
    booking?: EstimatedBooking | Booking | CruisePackageBooking | null;
    boatId?: string;
    notification?: boolean;
}

interface IBookingDetailsState {
    booking: EstimatedBooking | Booking | CruisePackageBooking | null;
    completeMessage: string | null;
    completedStatusAlertType: AlertType;
    seasonIsNotStarted: boolean;
    seasonStartsAt: Date | null;
    modified: boolean,
}

export default class BookingDetails extends AnonymousPage<IBookingDetailsProps, IBookingDetailsState> {

    state: IBookingDetailsState = {
        booking: null,
        completeMessage: null,
        completedStatusAlertType: AlertType.Success,
        seasonIsNotStarted: false,
        seasonStartsAt: null,
        modified: false,
    };

    private readonly _bookingRateModalRef: React.RefObject<BookingRateModal> = React.createRef();

    private async setBookingAsync(booking: EstimatedBooking | Booking | CruisePackageBooking, reRender: boolean = true): Promise<void> {
        const pageRoute: PageRoute = PageDefinitions.bookingDetails(booking);

        if ((reRender) && (this.isMounted)) {
            await this.setState({booking});
        } else {
            this.state.booking = booking;
        }

        UserInteractionDataStorage.set("booking", booking, DataStorageType.Session);

        PageRouteProvider.push(pageRoute, this.getTitle(), true);
    }

    private async onChangeAsync(): Promise<void> {
        if (this.booking) {
            this.state.modified = true;

            await this.hideAlertAsync();

            await this.setBookingAsync(this.booking, true);
        }
    }
    
    private async buyShuttleTicketAsync(booking: ShuttleEstimatedBooking): Promise<void> {
        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        const request = new BookTripRequest(booking, location);

        const response: BookTripResponse = await this.postAsync("/api/mobileApp/bookTrip", request);
        
        if (response.paymentFailed) {
            const message: string = Localizer.mobileBookingDetailsPageTicketPaymentFailed.format(response.paymentError);
            await this.alertErrorAsync(message);
            return;
        }
        
        if (response.notAvailable) {
            const route: PageRoute = PageDefinitions.shuttleSelection(booking.waypoint!);

            await PageRouteProvider.redirectAsync(route);

            await this.alertErrorAsync(Localizer.mobileBookingDetailsPageTicketsSold);
            
            return;
        }
        
        await this.alertMessageAsync(Localizer.mobileBookingDetailsPageTicketBought);

        await this.setState({booking: response.booking});
    }

    private async bookAsync(booking: EstimatedBooking): Promise<void> {
        const shuttleBooking: ShuttleEstimatedBooking | null = ShuttleEstimatedBooking.as(booking);
        
        if (shuttleBooking != null) {
            
            await this.buyShuttleTicketAsync(shuttleBooking);
            
            return;
        }
        
        const pageRoute: PageRoute = PageDefinitions.mobileHome(booking);
        
        await PageRouteProvider.redirectAsync(pageRoute);
    }

    private async setCaptainAsync(booking: Booking): Promise<void> {
        if (booking.captainNotAssignedNotification > 0) {
            const expirationTime: Date = booking.bookingTime.addMinutes(-booking.captainNotAssignedNotification);
            if (expirationTime.inPast()) {
                await this.alertWarningAsync(Localizer.mobileBookingDetailsPageTripExpired, true, true);
                return;
            }
        }

        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        const request = new SetBookingCaptainRequest();
        request.bookingId = booking.id;
        request.boatId = booking.boatId!;
        request.location = location;

        const response: SetBookingCaptainResponse = await this.postAsync("/api/mobileApp/setBookingCaptain", request);

        if (response.success) {
            await this.setBookingAsync(booking, false);

            await PageRouteProvider.redirectAsync(PageDefinitions.myTripsRoute);

            await this.alertMessageAsync(Localizer.mobileBookingDetailsPageAlertErrorTripAssigned, true, true);
        } else {
            if (response.bankAccountIsMissing) {
                await this.alertErrorAsync(Localizer.mobileBookingDetailsPageAlertErrorBankAccountIsMissing, true, true);
            } else if (response.captainAlreadyAssigned) {
                await this.alertErrorAsync(Localizer.mobileBookingDetailsPageAlertErrorCaptainAlreadyAssigned, true, true);
            }  else if (response.bookingAlreadyCanceled) {
                await this.alertErrorAsync(Localizer.mobileBookingDetailsPageAlertErrorBookingAlreadyCanceled, true, true);
            } else if (response.paymentFailed) {
                await this.alertErrorAsync(Localizer.mobileBookingDetailsPageAlertErrorPaymentFailed, true, true);
            } else {
                await this.alertErrorAsync(Localizer.mobileBookingDetailsPageAlertErrorSomethingWentWrong);
            }
        }
    }

    private async declineTripAsync(booking: Booking): Promise<void> {

        const message: string = Localizer.mobileBookingDetailsPageDeclineTripConfirm;

        const confirmed: boolean = await ch.confirmAsync(message);

        if (confirmed) {
            const request = new DeclineTripRequest();
            request.bookingId = booking.id;
            request.boatId = booking.boatId!;

            await this.postAsync("/api/mobileApp/declineTrip", request);

            await PageRouteProvider.redirectAsync(PageDefinitions.myTripsRoute);

            await this.alertMessageAsync(Localizer.mobileBookingDetailsPageAlertTripDeclined, true, true);
        }
    }

    private async cancelTripAsync(booking: Booking | EstimatedBooking | CruisePackageBooking): Promise<void> {
        if (Booking.is(booking)) {
            await AppController.cancelTripAsync(this, booking as Booking, PageDefinitions.myTripsRoute);
        }
    }

    private async startTripAsync(booking: Booking): Promise<void> {

        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        const request = new StartTripRequest();
        request.bookingId = booking.id;
        request.location = location;

        booking = await this.postAsync("/api/mobileApp/startTrip", request);
        
        if (booking.latestStatus == BookingStatus.CancelledByPassenger) {
            await this.setBookingAsync(booking);

            await this.alertErrorAsync(Localizer.mobileBookingDetailsPageAlertErrorCancelledByPassenger);
        } else {
            await this.setBookingAsync(booking);

            await this.alertMessageAsync(Localizer.mobileBookingDetailsPageAlertErrorTripStarted, true, true);
        }
    }
    
    private async checkInAsync(booking: Booking): Promise<void> {

        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        const request = new CheckInRequest();
        request.bookingId = booking.id;
        request.location = location;

        booking = await this.postAsync("/api/mobileApp/checkIn", request);

        await this.setBookingAsync(booking);
    }
    
    private async noShowAsync(booking: Booking): Promise<void> {

        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        const request = new NoShowRequest();
        request.bookingId = booking.id;
        request.location = location;

        booking = await this.postAsync("/api/mobileApp/noShow", request);

        await this.setBookingAsync(booking);
    }

    private async completeTripAsync(booking: Booking): Promise<void> {
        const location: GeoCoordinate | null = await Utility.getLocationAsync();

        const request = new CompleteTripRequest();
        request.bookingId = booking.id;
        request.location = location;

        const response: CompleteTripResponse = await this.postAsync("/api/mobileApp/completeTrip", request);

        if (response.booking) {
            await this.setBookingAsync(response.booking);
        }

        if (response.success) {
            await this.setState({completeMessage: Localizer.mobileBookingDetailsPageAlertErrorTripCompleted, completedStatusAlertType: AlertType.Success});
        } else {
            const stripeError: string = TransformProvider.stripeErrorCodeToString(response.errorCode);
            await this.setState({completeMessage: Localizer.mobileBookingDetailsPageAlertWarningTripCompleted.format(stripeError), completedStatusAlertType: AlertType.Warning});
        }
    }

    private async payTripAsync(booking: Booking): Promise<void> {
        const request = new PayPendingPaymentRequest();
        request.bookingId = booking.id;

        const response: PayPendingPaymentResponse = await this.postAsync("/api/mobileApp/payPendingPayment", request);

        if (response.booking) {
            await this.setBookingAsync(response.booking);
        }

        if (response.failed) {
            const error: string = TransformProvider.stripeErrorCodeToString(response.error);

            const message: string = (response.noCreditCard)
                ? Localizer.mobileBookingDetailsPagePayTripNoCreditCard
                : response.creditCardHasExpired
                    ? Localizer.mobileBookingDetailsPagePayTripCreditCardHasExpired
                    : Localizer.mobileBookingDetailsPagePayTripError.format(error);

            await this.alertErrorAsync(message, true, true);
        } else {
            await this.alertMessageAsync(Localizer.mobileBookingDetailsPagePayTripSuccess, true, true);
        }
    }

    private async rateCaptainAsync(): Promise<void> {
        await this.setState({completeMessage: null});

        if (this.asCaptain) {
            const booking: Booking = (this.booking! as Booking);

            const request = new SetBookingRateRequest();
            request.bookingId = booking.id;
            request.asCaptain = true;
            request.captainRatingPassenger = booking.captainRatingPassenger;

            await this.postAsync("/api/mobileApp/setBookingRate", request);
        }
    }

    private async closeAsync(): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.myTripsRoute);
    }

    private getSeasonNotStartedAlert(): AlertModel | null {
        if (this.state.seasonIsNotStarted) {
            const message: string = Localizer.mobileBookingDetailsPageSeasonNotStarted;
            const alert = new AlertModel(message, AlertType.Warning);
            alert.dismissible = false;

            return alert;
        }

        return null;
    }

    private getBookingWarningIntervalAlert(): AlertModel | null {
        const bookingWarningIntervalInHours: number | null = ServiceProviderController.bookingWarningIntervalInHours;
        const warning: boolean = (
            (bookingWarningIntervalInHours != null) &&
            (bookingWarningIntervalInHours > 0) &&
            (this.state.modified) &&
            (this.booking != null) && (EstimatedBooking.is(this.booking)) &&
            (Utility.diff(this.booking.bookingTime, Utility.now()).totalHours < bookingWarningIntervalInHours)
        );
        if (warning) {
            const message: string = Localizer.mobileBookingDetailsPageBookingWarningInterval;
            const alert = new AlertModel(message, AlertType.Warning);
            alert.dismissible = false;
            return alert;
        }
        return null;
    }

    private getPassengerNoValidPaymentMethodAlert(): AlertModel | null {
        const passenger: User | null = this.passenger;
        
        const bookingStatusForWarning: BookingStatus[] = [BookingStatus.New, BookingStatus.AcceptedByCaptain, BookingStatus.StartByCaptain];
        
        const warning: boolean = (
            (passenger != null) &&
            (!passenger.hasValidPaymentMethod) &&
            (
                EstimatedBooking.is(this.booking) || 
                CruisePackageBooking.is(this.booking) ||
                (
                    (bookingStatusForWarning.any(item => item == (this.booking as Booking).latestStatus)) &&
                    (!Booking.expired(this.booking as Booking))
                ) 
            )
        );

        if (warning) {
            const noPaymentMethodMessage: string = Localizer.mobileBookingDetailsPageNoPaymentMethod;
            const notApprovedTripMessage: string = Localizer.mobileBookingDetailsPageTripNotApprovedNoCreditCard;
            const alert: AlertModel = (this.asCaptain)
                ? new AlertModel(noPaymentMethodMessage, AlertType.Warning)
                : new AlertModel(notApprovedTripMessage, AlertType.Warning, false, false, null, PageDefinitions.editCreditCardRoute);
            alert.dismissible = false;
            return alert;
        }
        return null;
    }
    
    private getAlert(): AlertModel | null {
        return this.getPassengerNoValidPaymentMethodAlert() ?? this.getBookingWarningIntervalAlert() ?? this.getSeasonNotStartedAlert();
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public getTitle(): string {
        return Localizer.mobileBookingDetailsPageTitle;
    }

    public get booking(): EstimatedBooking | ShuttleEstimatedBooking | Booking | CruisePackageBooking | null {
        return this.state.booking;
    }

    private get cruisePackageBooking(): CruisePackageBooking | null {
        return CruisePackageBooking.as(this.booking);
    }

    private get passenger(): User | null {
        return (this.cruisePackageBooking?.passenger) ?? (Booking.as(this.booking)?.passenger) ?? null;
    }
    
    private get readonly(): boolean {
        return (this.state.seasonIsNotStarted);
    }

    public get valid(): boolean {
        return (this.booking != null) && (this.booking.bookingTime.inFuture());
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const params = this.parameters as IBookingDetailsProps | null;

        let booking: EstimatedBooking | ShuttleEstimatedBooking | Booking | CruisePackageBooking | null = null;

        if (!!this.routeId) {
            const request = new GetMyBookingRequest();
            request.bookingId = this.routeId;
            request.notification = params?.notification ?? null;

            const response: GetMyBookingResponse = await this.postAsync("/api/mobileApp/getMyBooking", request);

            if (response.assignedToOtherCaptain) {
                await PageRouteProvider.redirectAsync(PageDefinitions.notificationsRoute, true, false);

                await this.alertMessageAsync(Localizer.mobileBookingDetailsPageAlertErrorAssignedToOtherCaptain, true, true);

                return;
            }

            if (response.booking != null) {
                booking = response.booking;

                if ((!booking.boatId) && (params?.boatId)) {
                    booking.boatId = params.boatId;
                }
            }
        }

        if (!booking) {
            booking = (params?.booking || null) ?? (UserInteractionDataStorage.get("booking", null, DataStorageType.Session));
            Utility.restoreDate(booking);
        }

        if (!booking) {
            await PageRouteProvider.redirectAsync(PageDefinitions.mobileHomeRoute, true, true);

            return;
        }

        await this.setBookingAsync(booking);

        if (EstimatedBooking.is(booking)) {
            const user: User | null = ch.findUser();

            if ((user != null) && (!user.testUser)) {
                const context: ApplicationContext = this.getContext();

                const seasonStartsAt: Date | null = (context.settings.seasonStartsAt)
                    ? new Date(context.settings.seasonStartsAt)
                    : null;

                const seasonIsNotStarted: boolean = (seasonStartsAt != null) && (seasonStartsAt.inFuture());
                if (seasonIsNotStarted) {
                    await this.setState({seasonIsNotStarted, seasonStartsAt});
                }
            }
        }
    }

    public render(): React.ReactNode {

        const alert: AlertModel | null = this.getAlert();
        
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.bookingDetails, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                {
                    (alert) &&
                    (
                        <>
                            <Alert model={alert}
                                   className={styles.warning}
                            />
                        </>
                    )
                }

                <PageRow>

                    {
                        (this.booking) &&
                        (
                            <BookingInfo previewHasOnlyInfo
                                         key={ch.getComponentId()}
                                         item={this.booking}
                                         readonly={this.readonly}
                                         book={(_, booking) => this.bookAsync(booking as EstimatedBooking)}
                                         setCaptain={(_, booking) => this.setCaptainAsync(booking)}
                                         startTrip={(_, booking) => this.startTripAsync(booking)}
                                         declineTrip={(_, booking) => this.declineTripAsync(booking)}
                                         checkIn={(_, booking) => this.checkInAsync(booking)}
                                         noShow={(_, booking) => this.noShowAsync(booking)}
                                         cancelTrip={(_, booking) => this.cancelTripAsync(booking)}
                                         completeTrip={(_, booking) => this.completeTripAsync(booking)}
                                         payTrip={(_, booking) => this.payTripAsync(booking)}
                                         onChange={() => this.onChangeAsync()}
                                         close={() => this.closeAsync()}
                            />
                        )
                    }

                </PageRow>

                {
                    ((this.booking) && (this.asCaptain) && (this.state.completeMessage) && (ServiceProviderController.supportsRating)) &&
                    (
                        <BookingRateModal ref={this._bookingRateModalRef}
                                          booking={this.booking as Booking}
                                          asCaptain={this.asCaptain}
                                          alertMessage={this.state.completeMessage}
                                          alertType={this.state.completedStatusAlertType}
                                          onClose={() => this.rateCaptainAsync()}
                        />
                    )
                }

            </PageContainer>
        );
    }
}
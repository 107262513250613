// autogenerated

export default class SetBookingRateRequest {

    public bookingId: string = "";

    public asPassenger: boolean = false;

    public asCaptain: boolean = false;

    public captainRatingPassenger: number | null = null;

    public passengerRatingBoat: number | null = null;

    public passengerRatingCaptain: number | null = null;

    public readonly isSetBookingRateRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SetBookingRateRequest) || ((from as any).isSetBookingRateRequest === true));
    }

    public static as(from?: object | null): SetBookingRateRequest | null {
        return (SetBookingRateRequest.is(from)) ? from as SetBookingRateRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
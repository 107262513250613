// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class NoShowRequest {

    public bookingId: string = "";

    public location: GeoCoordinate | null = null;

    public readonly isNoShowRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof NoShowRequest) || ((from as any).isNoShowRequest === true));
    }

    public static as(from?: object | null): NoShowRequest | null {
        return (NoShowRequest.is(from)) ? from as NoShowRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
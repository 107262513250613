// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import AppConstants from "@/helpers/AppConstants";

export default class CruisePackagePoint {

    public id: string = "";

    public cruisePackageId: string = "";

    public locationId: string = "";

    public location: GeoLocation | null = null;

    public index: number = 0;

    public price: number = 0;

    public readonly isCruisePackagePoint: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CruisePackagePoint) || ((from as any).isCruisePackagePoint === true));
    }

    public static as(from?: object | null): CruisePackagePoint | null {
        return (CruisePackagePoint.is(from)) ? from as CruisePackagePoint : null;
    }

    // #region Placeholder

    public static initialize(model: CruisePackagePoint): CruisePackagePoint {
        model.id ||= AppConstants.defaultGuid;
        model.cruisePackageId ||= AppConstants.defaultGuid;
        model.locationId ||= AppConstants.defaultGuid;
        return model;
    }

    // #endregion
}
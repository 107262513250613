// autogenerated

export default class DeclineTripRequest {

    public bookingId: string = "";

    public boatId: string = "";

    public readonly isDeclineTripRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof DeclineTripRequest) || ((from as any).isDeclineTripRequest === true));
    }

    public static as(from?: object | null): DeclineTripRequest | null {
        return (DeclineTripRequest.is(from)) ? from as DeclineTripRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
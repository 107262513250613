import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {
    Button,
    ButtonContainer,
    ButtonType,
    PageContainer,
    PageHeader,
    PageRow
} from "@reapptor-apps/reapptor-react-components";
import AppConstants from "@/helpers/AppConstants";
import {DataStorageType, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./SelectRole.module.scss";

import loading from "@/img/gif/waving_boat.webp";

interface ISelectRoleProps {
}

interface ISelectRoleState {
}

export default class SelectRole extends AuthorizedPage<ISelectRoleProps, ISelectRoleState> {

    state: ISelectRoleState = {};

    private async selectRoleAsync(roleName: string): Promise<void> {
        await this.postAsync("/api/application/selectCurrentRole", roleName);

        UserInteractionDataStorage.set(AppConstants.hideTopNav, false, DataStorageType.Session);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public getTitle(): string {
        return Localizer.selectRolePageTitle;
    }

    public get isAdmin(): boolean {
        return this.getUser().isAdmin;
    }

    public get isCaptain(): boolean {
        return this.getUser().isCaptain;
    }

    public get isPassenger(): boolean {
        return this.getUser().isPassenger;
    }

    public get isInspector(): boolean {
        return this.getUser().isInspector;
    }

    public get isServiceProviderManager(): boolean {
        return this.getUser().isServiceProviderManager;
    }

    public get hasLeftNav(): boolean {
        return false;
    }

    public get hideTopNav(): boolean {
        return UserInteractionDataStorage.get(AppConstants.hideTopNav, false, DataStorageType.Session);
    }

    public get hasTopNav(): boolean {
        return (!this.hideTopNav);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.selectRole, styles.hideTopNav, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >
                {
                    (!this.mobile) &&
                    (
                        <PageHeader title={this.getTitle()} className={styles.header}/>
                    )
                }
                
                <PageRow>

                    <div className={this.css(styles.container, "col-lg-6")}>

                        <div className={styles.expander}/>
                        
                        <div className={styles.logo}>
                        
                            <img src={loading} alt="waving_boat" width={250} height={250} />
                        
                        </div>

                        <div className={styles.expander}/>
                        
                        <div className={styles.buttonContainer}>
                            
                            {
                                (this.isAdmin) &&
                                (
                                    <Button block
                                            icon={{name: "fas users-cog"}}
                                            type={ButtonType.Primary}
                                            label={Localizer.selectRolePageButtonAdminLabel}
                                            title={Localizer.selectRolePageButtonAdminDescription}
                                            onClick={() => this.selectRoleAsync(AppConstants.adminRole)}
                                    />
                                )
                            }

                            {
                                (this.isCaptain) &&
                                (
                                    <Button block
                                            type={ButtonType.Primary}
                                            icon={{name: "fas ship"}}
                                            label={Localizer.selectRolePageButtonCaptainLabel}
                                            title={Localizer.selectRolePageButtonCaptainDescription}
                                            onClick={() => this.selectRoleAsync(AppConstants.captainRole)}
                                    />
                                )
                            }

                            {
                                (this.isPassenger) &&
                                (
                                    <Button block
                                            type={ButtonType.Orange}
                                            icon={{name: "far users"}}
                                            label={Localizer.selectRolePageButtonPassengerLabel}
                                            title={Localizer.selectRolePageButtonPassengerDescription}
                                            onClick={() => this.selectRoleAsync(AppConstants.passengerRole)}
                                    />
                                )
                            }

                            {
                                (this.isInspector) &&
                                (
                                    <Button block
                                            type={ButtonType.Secondary}
                                            icon={{name: "far fa-qrcode"}}
                                            label={Localizer.selectRolePageButtonInspectorLabel}
                                            title={Localizer.selectRolePageButtonInspectorDescription}
                                            onClick={() => this.selectRoleAsync(AppConstants.inspectorRole)}
                                    />
                                )
                            }

                            {
                                (this.isServiceProviderManager) &&
                                (
                                    <Button block
                                            type={ButtonType.Secondary}
                                            icon={{name: "fas fa-user-tie"}}
                                            label={Localizer.selectRolePageButtonServiceProviderManagerLabel}
                                            title={Localizer.selectRolePageButtonServiceProviderManagerDescription}
                                            onClick={() => this.selectRoleAsync(AppConstants.serviceProviderManagerRole)}
                                    />
                                )
                            }

                        </div>

                    </div>
                    
                </PageRow>

            </PageContainer>
        );
    }
}
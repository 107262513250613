// autogenerated

export default class SaveBoatRequest {

    public id: string | null = null;

    public margin: number | null = null;

    public cruiseSpeed: number = 0;

    public horsepower: number = 0;

    public boatPriceId: string | null = null;

    public areaId: string | null = null;

    public readonly isSaveBoatRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveBoatRequest) || ((from as any).isSaveBoatRequest === true));
    }

    public static as(from?: object | null): SaveBoatRequest | null {
        return (SaveBoatRequest.is(from)) ? from as SaveBoatRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
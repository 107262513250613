// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {IUser} from "@reapptor-apps/reapptor-react-common";
import {AuthType} from "@/models/Enums";
import UserPolicyAcceptance from "@/models/server/UserPolicyAcceptance";
import UserInvitation from "@/models/server/UserInvitation";
import UserRole from "@/models/server/UserRole";
import StripeKycStatus from "@/models/server/stripe/kycstatus/StripeKycStatus";
import Country from "@/models/server/bout/Country";
import ServiceProvider from "@/models/server/ServiceProvider";
import AppConstants from "@/helpers/AppConstants";

export default class User implements IUser {

    public id: string = "";

    public username: string = "";

    public email: string = "";

    public emailVerified: string | null = null;

    public createdAt: Date = new Date();

    public policyAcceptance: UserPolicyAcceptance[] | null = null;

    public hasValidPaymentMethod: boolean = false;

    public isEntrepreneur: boolean = false;

    public isLocked: boolean = false;

    public isDeleted: boolean = false;

    public hasPassword: boolean = false;

    public invitations: UserInvitation[] | null = null;

    public authType: AuthType = AuthType.Email;

    public firstname: string | null = null;

    public lastName: string | null = null;

    public middleName: string | null = null;

    public language: string | null = null;

    public phone: string | null = null;

    public role: UserRole | null = null;

    public roles: UserRole[] = [];

    public externalId: string | null = null;

    public businessId: string | null = null;

    public serviceProviderId: string | null = null;

    public ratingAsCaptain: number = 0;

    public ratingAsPassenger: number = 0;

    public avatarId: string | null = null;

    public avatar: FileModel | null = null;

    public allowAsCaptain: boolean = false;

    public allowAsPassenger: boolean = false;

    public stripeKycVerified: boolean = false;

    public stripeAccountId: string | null = null;

    public stripeCustomerId: string | null = null;

    public stripeKycStatus: StripeKycStatus | null = null;

    public testUser: boolean = false;

    public countryId: string = "";

    public country: Country | null = null;

    public paymentBalance: number = 0;

    public hasPushUpNotifications: boolean = false;

    public lastLogin: Date | null = null;

    public waypointIds: string[] | null = null;

    public fullName: string | null = null;

    public roleNames: string[] | null = null;

    public isAdmin: boolean = false;

    public isServiceProviderManager: boolean = false;

    public isCaptain: boolean = false;

    public isPassenger: boolean = false;

    public isInspector: boolean = false;

    public readonly isUser: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof User) || ((from as any).isUser === true));
    }

    public static as(from?: object | null): User | null {
        return (User.is(from)) ? from as User : null;
    }

    // #region Placeholder

    constructor(language?: string | null, roleName?: string | null) {
        this.id = AppConstants.defaultGuid;
        this.avatarId = AppConstants.defaultGuid;
        this.language = language ?? "";
        this.role = new UserRole(roleName);
    }

    public static hasCaptainRole(user: User): boolean {
        return user.roles.some(role => role.roleName == AppConstants.captainRole);
    }

    public static hasPassengerRole(user: User): boolean {
        return user.roles.some(role => role.roleName == AppConstants.passengerRole);
    }

    public static hasInspectorRole(user: User): boolean {
        return user.roles.some(role => role.roleName == AppConstants.inspectorRole);
    }

    public static hasServiceProviderManagerRole(user: User): boolean {
        return user.roles.some(role => role.roleName == AppConstants.serviceProviderManagerRole);
    }

    public static rolesToString(user: User | null | undefined): string {
        if (user) {
            return user.roles.map(item => item.roleName).join(', ');
        }

        return "";
    }

    public static getFullName(user: User, def: string = ""): string {
        const firstname: string = (user.firstname != null) ? user.firstname : "";
        const lastName: string = (user.lastName != null) ? user.lastName : "";
        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return def;
    }
    
    public static isNew(user: User): boolean {
        return ((!user.id) || (user.id == AppConstants.defaultGuid));
    }
    
    public static isAcceptedRegulations(user: User, slug: ServiceProvider | null): boolean {
        return (
            (User.isAgreementAccepted(user, slug)) &&
            (User.isRegistrationAccepted(user, slug)) &&
            (User.isCancellationPolicyAccepted(user, slug))
        );
    }

    public static getPolicyAcceptance(user: User, slug: ServiceProvider | null): UserPolicyAcceptance {
        const policyAcceptances: UserPolicyAcceptance[] = user.policyAcceptance ?? (user.policyAcceptance = []);
        const slugId: string | null = slug?.id || null;
        let policyAcceptance: UserPolicyAcceptance | null = policyAcceptances.firstOrDefault(item => item.serviceProviderId == slugId);
        if (policyAcceptance == null) {
            policyAcceptance = new UserPolicyAcceptance();
            policyAcceptance.serviceProviderId = slugId;
            policyAcceptances.push(policyAcceptance);
        }
        return policyAcceptance;
    }
    
    public static isAgreementAccepted(user: User, slug: ServiceProvider | null): boolean {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        return policyAcceptance.agreementAccepted;
    }
    
    public static setAgreementAccepted(user: User, slug: ServiceProvider | null, value: boolean): void {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        policyAcceptance.agreementAccepted = value;
    }
    
    public static isRegistrationAccepted(user: User, slug: ServiceProvider | null): boolean {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        return policyAcceptance.registrationAccepted;
    }

    public static setRegistrationAccepted(user: User, slug: ServiceProvider | null, value: boolean): void {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        policyAcceptance.registrationAccepted = value;
    }
    
    public static isCancellationPolicyAccepted(user: User, slug: ServiceProvider | null): boolean {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        return policyAcceptance.cancellationPolicyAccepted;
    }

    public static setCancellationPolicyAccepted(user: User, slug: ServiceProvider | null, value: boolean): void {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        policyAcceptance.cancellationPolicyAccepted = value;
    }
    
    public static isAdult(user: User, slug: ServiceProvider | null): boolean {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        return policyAcceptance.adult;
    }

    public static setAdult(user: User, slug: ServiceProvider | null, value: boolean): void {
        const policyAcceptance: UserPolicyAcceptance = this.getPolicyAcceptance(user, slug);
        policyAcceptance.adult = value;
    }

    // #endregion
}
import React from "react";
import {BaseComponent, IBaseComponentProps} from "@reapptor-apps/reapptor-react-common";

import skeletonStyles from "../Skeleton.module.scss";
import styles from "./SkeletonCircle.module.scss";

export interface ISkeletonCircleProps extends IBaseComponentProps {
    size?: number | string;
}

export default class SkeletonCircle extends BaseComponent<ISkeletonCircleProps> {

    public render(): React.ReactNode {

        const inlineStyles: React.CSSProperties = {
            width: this.props.size,
            height: this.props.size,
        };

        return (
            <div id={this.id}
                 className={this.css(skeletonStyles.skeletonItem, styles.skeletonCircle, this.props.className)}
                 style={inlineStyles}
            />
        )
    }
}
// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListServiceProviderPoliciesRequest extends ETagRequest {

    public serviceProviderId: string | null = null;

    public readonly isListServiceProviderPoliciesRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListServiceProviderPoliciesRequest) || ((from as any).isListServiceProviderPoliciesRequest === true));
    }

    public static as(from?: object | null): ListServiceProviderPoliciesRequest | null {
        return (ListServiceProviderPoliciesRequest.is(from)) ? from as ListServiceProviderPoliciesRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
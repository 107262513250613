// autogenerated
import ShuttleWaypoint from "@/models/server/shuttle/ShuttleWaypoint";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";

export default class ShuttleEstimatedBooking extends EstimatedBooking {

    public waypoint: ShuttleWaypoint | null = null;

    public departureAt: Date = new Date();

    public arrivalAt: Date = new Date();

    public legInstanceIds: string[] | null = null;

    public stopsCount: number = 0;

    public readonly isShuttleEstimatedBooking: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ShuttleEstimatedBooking) || ((from as any).isShuttleEstimatedBooking === true));
    }

    public static as(from?: object | null): ShuttleEstimatedBooking | null {
        return (ShuttleEstimatedBooking.is(from)) ? from as ShuttleEstimatedBooking : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
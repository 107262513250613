import React from "react";
import {FileModel, GeoLocation, Utility} from "@reapptor-apps/reapptor-toolkit";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {
    AddressInput,
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox, 
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    DropdownRequiredType,
    Form,
    FourColumns,
    IconSize,
    IIconProps,
    ImageInput,
    Inline,
    InlineType,
    JustifyContent,
    NumberInput,
    NumberInputBehaviour,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    Tab,
    TabContainer,
    TabRenderType,
    TextInput,
    ThreeColumns,
    ToolbarContainer,
    TwoColumns,
} from "@reapptor-apps/reapptor-react-components";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import PageDefinitions from "@/providers/PageDefinitions";
import ServiceProvider from "@/models/server/ServiceProvider";
import Area from "@/models/server/bout/Area";
import Boat from "@/models/server/bout/Boat";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import ListBoatsRequest from "@/models/server/requests/ListBoatsRequest";
import LocalizationTextInput, {LocalizationTextInputType} from "@/components/LocalizationTextInput/LocalizationTextInput";
import CruisePackageImage from "@/models/server/cruise/CruisePackageImage";
import CruisePackagePrice from "@/models/server/cruise/CruisePackagePrice";
import ImageProvider from "@/providers/ImageProvider";
import AppConstants from "@/helpers/AppConstants";
import SaveCruisePackageRequest from "@/models/server/requests/SaveCruisePackageRequest";
import SaveCruisePackageResponse from "@/models/server/responses/SaveCruisePackageResponse";
import {
    DataStorageType,
    LocalizationString,
    PageRoute,
    PageRouteProvider
} from "@reapptor-apps/reapptor-react-common";
import GetCruisePackageRequest from "@/models/server/requests/GetCruisePackageRequest";
import {CruisePackageOwnerType, CruisePackagePriceInterval} from "@/models/Enums";
import CruisePackagePreviewModal from "@/components/CruisePackagePreviewModal/CruisePackagePreviewModal";
import ListAreasRequest from "@/models/server/requests/ListAreasRequest";
import ListAreasResponse from "@/models/server/responses/ListAreasResponse";
import ListBoatsResponse from "@/models/server/responses/ListBoatsResponse";
import ListServiceProvidersRequest from "@/models/server/requests/ListServiceProvidersRequest";
import ListServiceProvidersResponse from "@/models/server/responses/ListServiceProvidersResponse";
import Country from "@/models/server/bout/Country";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import AppController from "@/pages/AppController";

import styles from "./CruisePackageManagement.module.scss";

const MAX_PRICE: number = 99999;

interface ICruisePackageManagementProps {
}

interface ICruisePackageManagementState {
    cruisePackage: CruisePackage | null;
    hashCode: number;
    serviceProviders: ServiceProvider[];
    areas: Area[];
    boats: Boat[];
    splitPriceModel: boolean;
    hasSeason: boolean;
}

export default class CruisePackageManagement extends AuthorizedPage<ICruisePackageManagementProps, ICruisePackageManagementState> {

    state: ICruisePackageManagementState = {
        cruisePackage: null,
        hashCode: 0,
        serviceProviders: [],
        areas: [],
        boats: [],
        splitPriceModel: false,
        hasSeason: false,
    };

    private readonly _cruisePackagePreviewModalRef: React.RefObject<CruisePackagePreviewModal> = React.createRef();
    private readonly _seasonStartsAtInputRef: React.RefObject<DateInput> = React.createRef();
    private readonly _seasonEndsAtInputRef: React.RefObject<DateInput> = React.createRef();

    private async fetchServiceProvidersAsync(): Promise<ServiceProvider[]> {
        const request = new ListServiceProvidersRequest();

        const response: ListServiceProvidersResponse = await this.postAsync("/api/cruisePackage/listServiceProviders", request);

        return response.serviceProviders ?? [];
    }

    private async fetchAreasAsync(): Promise<Area[]> {
        const request = new ListAreasRequest();
        request.withBoatsOnly = true;
        request.asAdmin = AppController.asAdmin;
        request.asCaptain = AppController.asCaptain;

        const response: ListAreasResponse = await this.postAsync("/api/cruisePackage/listAreas", request);

        return response.areas ?? [];
    }

    private async fetchBoatsAsync(areaId: string | null, includeDeleted: boolean = false): Promise<Boat[]> {
        const request = new ListBoatsRequest();
        request.areaId = areaId;
        request.includeImages = true;
        request.includeDeleted = includeDeleted;
        request.asCaptain = this.asCaptain;

        const response: ListBoatsResponse = await this.postAsync("/api/cruisePackage/listBoats", request);

        return response.boats ?? [];
    }

    private async setOwnerTypeAsync(ownerType: CruisePackageOwnerType): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.ownerType = ownerType;

            await this.reRenderAsync();
        }
    }

    private async setServiceProviderAsync(serviceProvider: ServiceProvider): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.serviceProvider = serviceProvider;
            this.cruisePackage.serviceProviderId = serviceProvider.id;
        }
    }

    private async setAreaAsync(area: Area): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.area = area;
            this.cruisePackage.areaId = area.id;
            this.cruisePackage.boat = null;
            this.cruisePackage.boatId = "";

            this.state.boats = await this.fetchBoatsAsync(area.id, true);

            await this.reRenderAsync();
        }
    }

    private async setBoatAsync(boat: Boat): Promise<void> {
        if (this.cruisePackage) {

            const defaultCapacity: boolean = (
                ((this.cruisePackage.boat == null) && (this.cruisePackage.maxCapacity == 0)) ||
                ((this.cruisePackage.boat != null) && (this.cruisePackage.maxCapacity == this.cruisePackage.boat.maxCapacity))
            );

            const defaultPoint: boolean = (
                ((this.cruisePackage.points == null) || (this.cruisePackage.points.length == 0)) ||
                (
                    (this.cruisePackage.points.length == 1) &&
                    (
                        (this.cruisePackage.points[0].location == null) ||
                        ((this.cruisePackage.boat != null) && (this.cruisePackage.points[0].location == this.cruisePackage.boat.homeLocation))
                    )
                )
            );

            const defaultImages: boolean = (
                ((this.cruisePackage.images == null) || (this.cruisePackage.images.length == 0))
            );

            this.cruisePackage.boat = boat;
            this.cruisePackage.boatId = boat.id;

            if ((defaultCapacity) || (boat.maxCapacity < this.cruisePackage.maxCapacity)) {
                this.cruisePackage.maxCapacity = boat.maxCapacity;
            }

            if (defaultPoint) {
                const point = new CruisePackagePoint();
                point.location = boat.homeLocation;
                this.cruisePackage.points = [point];
            }

            if ((defaultImages) && (boat.images)) {
                const length: number = boat.images.length;
                this.cruisePackage.images = [];
                for (let i: number = 0; i < length; i++) {
                    const boatImage: FileModel | null = boat.images[i]?.file;
                    if (boatImage) {
                        const image = new CruisePackageImage();
                        image.file = boatImage;
                        this.cruisePackage.images.push(image);
                    }
                }
            }

            await this.reRenderAsync();
        }
    }

    private async setNameAsync(value: LocalizationString): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.name = value;
        }
    }

    private async setDetailsAsync(value: LocalizationString): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.details = value;
        }
    }

    private async setFoodOptionsAsync(value: boolean): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.food = value;
        }
    }

    private async setDrinksAsync(value: boolean): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.drinks = value;
        }
    }

    private async setCustomLocationAsync(customLocation: boolean): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.customLocation = customLocation;

            this.cruisePackage.customLocationPrice = (customLocation)
                ? this.cruisePackage.customLocationPrice ?? 0
                : null;

            await this.reRenderAsync();
        }
    }

    private async setMaxCapacityAsync(value: number): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.maxCapacity = value;
        }
    }

    private async setPointAsync(point: CruisePackagePoint, location: GeoLocation): Promise<void> {
        point.location = location;

        await this.reRenderAsync();
    }

    private async deletePointAsync(point: CruisePackagePoint): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.points?.remove(point);

            await this.reRenderAsync();
        }
    }

    private async upPointAsync(point: CruisePackagePoint): Promise<void> {
        if (this.cruisePackage) {
            const index: number = this.cruisePackage.points?.indexOf(point) ?? 0;

            this.cruisePackage.points?.remove(point);

            this.cruisePackage.points?.insert(point, index - 1);

            await this.reRenderAsync();
        }
    }

    private async downPointAsync(point: CruisePackagePoint): Promise<void> {
        if (this.cruisePackage) {
            const index: number = this.cruisePackage.points?.indexOf(point) ?? 0;

            this.cruisePackage.points?.remove(point);

            this.cruisePackage.points?.insert(point, index + 1);

            await this.reRenderAsync();
        }
    }

    private async addPointAsync(): Promise<void> {
        if (this.cruisePackage) {
            const point = new CruisePackagePoint();
            point.id = AppConstants.defaultGuid;
            point.cruisePackageId = this.cruisePackage.id || AppConstants.defaultGuid;
            point.locationId = AppConstants.defaultGuid;

            this.cruisePackage.points?.push(point);

            await this.reRenderAsync();
        }
    }

    private async setSplitPriceModelAsync(splitPriceModel: boolean): Promise<void> {
        if (this.cruisePackage) {
            const prices: CruisePackagePrice[] = this.cruisePackage.prices ?? [];
            const obsoletePrices: CruisePackagePrice[] = [];
            const newPrices: CruisePackagePrice[] = [];

            for (let i: number = 0; i < prices.length; i++) {
                const price: CruisePackagePrice = prices[i];

                if (splitPriceModel) {
                    price.interval = CruisePackagePriceInterval.MondayTuesday;

                    const newPrice = new CruisePackagePrice();
                    newPrice.hourIndex = price.hourIndex;
                    newPrice.price = price.price;
                    newPrice.interval = CruisePackagePriceInterval.WednesdaySunday;

                    newPrices.push(newPrice);
                } else {
                    if (price.interval == CruisePackagePriceInterval.MondayTuesday) {
                        price.interval = CruisePackagePriceInterval.MondaySunday;
                    } else {
                        obsoletePrices.push(price);
                    }
                }
            }

            prices.remove(obsoletePrices);
            prices.push(...newPrices);
            prices.sortBy(item => item.hourIndex);

            await this.setState({splitPriceModel});
        }
    }

    private async setHasSeasonAsync(hasSeason: boolean): Promise<void> {
        if (this.cruisePackage) {
            if (hasSeason) {
                this.cruisePackage.seasonStartsAt = Utility.today();
                this.cruisePackage.seasonEndsAt = Utility.today();
            }
            else {
                this.cruisePackage.seasonStartsAt = null;
                this.cruisePackage.seasonEndsAt = null;
            }
            
            await this.setState({hasSeason});
        }
    }

    private async setSeasonStartsAtAsync(value: Date): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.seasonStartsAt = value.date();
            
            if ((this.cruisePackage.seasonEndsAt) && (this.cruisePackage.seasonEndsAt <= this.cruisePackage.seasonStartsAt)) {
                this.cruisePackage.seasonEndsAt = this.cruisePackage.seasonStartsAt.addDays(1);
            }

            await this.reRenderAsync();
        }
    }

    private async setSeasonEndsAtAsync(value: Date): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.seasonEndsAt = value.date();

            await this.reRenderAsync();
        }
    }

    private async setPointPriceAsync(point: CruisePackagePoint, value: number): Promise<void> {
        point.price = value;
    }

    private async setPriceAsync(price: CruisePackagePrice, value: number): Promise<void> {
        price.price = value;
    }

    private async deletePriceAsync(price: CruisePackagePrice): Promise<void> {
        if (this.cruisePackage) {
            const prices: CruisePackagePrice[] = this.cruisePackage.prices ?? [];

            if (price.interval == CruisePackagePriceInterval.WednesdaySunday) {
                const leftPrices: CruisePackagePrice[] = prices.where(item => item.interval == CruisePackagePriceInterval.MondayTuesday);
                const rightPrices: CruisePackagePrice[] = prices.where(item => item.interval == CruisePackagePriceInterval.WednesdaySunday);

                const index: number = rightPrices.indexOf(price);
                const leftPrice: CruisePackagePrice = leftPrices[index];

                prices.remove(leftPrice);
            }

            prices.remove(price);

            await this.reRenderAsync();
        }
    }

    private async addPriceAsync(priceInterval: CruisePackagePriceInterval): Promise<void> {
        if (this.cruisePackage) {

            const price = new CruisePackagePrice();
            price.interval = priceInterval;

            this.cruisePackage.prices?.push(price);

            if (priceInterval == CruisePackagePriceInterval.MondayTuesday) {
                const price = new CruisePackagePrice();
                price.interval = CruisePackagePriceInterval.WednesdaySunday;
                this.cruisePackage.prices?.push(price);
            }

            if (priceInterval == CruisePackagePriceInterval.WednesdaySunday) {
                const price = new CruisePackagePrice();
                price.interval = CruisePackagePriceInterval.MondayTuesday;
                this.cruisePackage.prices?.push(price);
            }

            await this.reRenderAsync();
        }
    }

    private async setCustomLocationPriceAsync(customLocationPrice: number): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.customLocationPrice = customLocationPrice;
        }
    }

    private async setImageAsync(files: FileModel[]): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.images = files.select((item, index) => CruisePackageImage.create(item, index));

            await this.reRenderAsync();
        }
    }

    private async setMinDurationAsync(minDurationInHours: number): Promise<void> {
        if (this.cruisePackage) {
            this.cruisePackage.minDurationInHours = minDurationInHours;

            await this.reRenderAsync();
        }
    }

    private async reloadAsync(): Promise<void> {

        const cruisePackageId: string | null = ((this.cruisePackage) && (this.cruisePackage.id))
            ? this.cruisePackage.id
            : this.routeId;

        let cruisePackage: CruisePackage;
        let boats: Boat[] = [];

        if (cruisePackageId) {
            const request = new GetCruisePackageRequest();
            request.cruisePackageId = cruisePackageId;

            cruisePackage = await this.postAsync("/api/cruisePackage/getCruisePackage", request);

            boats = await this.fetchBoatsAsync(cruisePackage.areaId, true);

        } else {
            cruisePackage = new CruisePackage();
            cruisePackage.active = false;
            cruisePackage.minDurationInHours = 1;

            if (this.asCaptain) {
                cruisePackage.ownerType = CruisePackageOwnerType.Captain;
            }
        }

        if ((cruisePackage.points == null) || (cruisePackage.points.length == 0)) {
            cruisePackage.points = [new CruisePackagePoint()];
        }

        if ((cruisePackage.prices == null) || (cruisePackage.prices.length == 0)) {
            cruisePackage.prices = [new CruisePackagePrice()];
        }

        const hashCode: number = Utility.getHashCode(cruisePackage);

        const splitPriceModel: boolean = CruisePackage.isSplitPriceModel(cruisePackage);
        const hasSeason: boolean = cruisePackage.seasonStartsAt != null && cruisePackage.seasonEndsAt != null;

        await this.setState({cruisePackage, hashCode, boats, splitPriceModel, hasSeason});
    }

    private async previewAsync(): Promise<void> {
        if ((this.cruisePackage) && (this._cruisePackagePreviewModalRef.current)) {
            await this._cruisePackagePreviewModalRef.current.openAsync(this.cruisePackage);
        }
    }

    private async openLinkAsync(): Promise<void> {
        if (this.cruisePackage?.path) {
            window.open(this.cruisePackage.path, '_blank')?.focus();
        }
    }

    private imageUrl(file: FileModel): string {
        return ImageProvider.getRequiredImageSrc(file, "");
    }

    private async convertImageAsync(file: FileModel): Promise<FileModel> {
        //return await this.postAsync("/api/image/convertImage", file);
        return await this.postAsync("/api/image/convertCruisePackageImage", file);
    }

    private async submitCruisePackageAsync(): Promise<void> {
        if (this.cruisePackage) {

            const isValid: boolean = this.isValid();

            if (!isValid) {
                await this.alertErrorAsync(Localizer.cruiseManagementPageNotValidPackage, true);
                return;
            }

            const request = new SaveCruisePackageRequest();

            this.copyTo(this.cruisePackage, request);

            request.images = this.images;
            request.points = this.cruisePackage.points?.select(item => CruisePackagePoint.initialize(item)) ?? [];
            request.prices = this.cruisePackage.prices?.select(item => CruisePackagePrice.initialize(item)) ?? [];

            const isNew: boolean = (!this.cruisePackage.id);

            const response: SaveCruisePackageResponse = await this.postAsync("/api/cruisePackage/saveCruisePackage", request);

            if (response.alreadyExists) {
                await this.alertErrorAsync(Localizer.cruiseManagementPageAlreadyExists.format(this.cruisePackage.name), false);

                return;
            }

            const cruisePackage: CruisePackage = response.cruisePackage!;

            if (isNew) {
                const route: PageRoute = PageDefinitions.cruisePackageManagement(cruisePackage.id);
                await PageRouteProvider.redirectAsync(route);
            } else {
                const hashCode: number = Utility.getHashCode(cruisePackage);
                await this.setState({cruisePackage, hashCode});
            }

            await this.alertMessageAsync(Utility.format(Localizer.cruiseManagementPageSaved, this.cruisePackage.name), true, false);
        }
    }

    private get cruisePackage(): CruisePackage | null {
        return this.state.cruisePackage;
    }

    private isValid(): boolean {
        return ((this.cruisePackage != null) && (CruisePackage.isValid(this.cruisePackage)));
    }

    private get boat(): Boat | null {
        const boatId: string | null = this.cruisePackage?.boatId ?? null;
        return (boatId)
            ? this.state.boats.firstOrDefault(item => item.id == boatId)
            : null;
    }

    private get country(): Country | null {
        const area: Area | null = this.cruisePackage?.area ?? this.cruisePackage?.boat?.area ?? null;
        return area?.country || null;
    }

    private get moneySymbol(): string {
        return this.country?.moneySymbol ?? "";
    }

    private get images(): FileModel[] {
        const images: CruisePackageImage[] = (this.cruisePackage?.images ?? []).where(item => item?.file != null);
        images.sortBy(item => item.index);
        return images.select(item => item.file!);
    }
    
    private get seasonStartMinDate(): Date {
        return new Date(Utility.today().getFullYear(), 0, 1);
    }
    
    private get seasonStartMaxDate(): Date {
        return new Date(Utility.today().getFullYear() + 1, 11, 31);
    }
    
    private get seasonEndMinDate(): Date {
        const seasonStartsAt: Date | null = this.cruisePackage?.seasonStartsAt || null;
        return (seasonStartsAt)
            ? seasonStartsAt
            : new Date(Utility.today().getFullYear() + 1, 0, 1);
    }
    
    private get seasonEndMaxDate(): Date {
        const seasonStartsAt: Date | null = this.cruisePackage?.seasonStartsAt || null;
        return (seasonStartsAt)
            ? seasonStartsAt.addYears(1)
            : new Date(Utility.today().getFullYear() + 2, 11, 31);
    }

    private isModified(): boolean {
        return ((this.cruisePackage != null) && (this.state.hashCode != Utility.getHashCode(this.cruisePackage)));
    }

    private getTabIcon(validator: (model: CruisePackage) => boolean): IIconProps | undefined {
        return ((this.cruisePackage) && (!validator(this.cruisePackage)))
            ? {name: "far fa-exclamation-triangle", className: styles.warningIcon}
            : undefined;
    }

    private get packageTabIcon(): IIconProps | undefined {
        return this.getTabIcon(model => CruisePackage.isGenericValid(model));
    }

    private get optionsTabIcon(): IIconProps | undefined {
        return this.getTabIcon(model => CruisePackage.isOptionsValid(model));
    }

    private get pointsTabIcon(): IIconProps | undefined {
        return this.getTabIcon(model => CruisePackage.isPointsValid(model));
    }

    private get imagesTabIcon(): IIconProps | undefined {
        return this.getTabIcon(model => CruisePackage.isImagesValid(model));
    }

    private get priceTabIcon(): IIconProps | undefined {
        return this.getTabIcon(model => CruisePackage.isPriceValid(model));
    }

    private get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    private get hasSeason(): boolean {
        return this.state.hasSeason;
    }

    private get seasonStartsAt(): Date | null {
        return this.cruisePackage?.seasonStartsAt ?? null;
    }

    private get seasonEndsAt(): Date | null {
        return this.cruisePackage?.seasonEndsAt ?? null;
    }

    public getTitle(): string {
        return Localizer.cruiseManagementPageTitle;
    }

    public getSubtitle(): string {
        return this.cruisePackage
            ? (!!this.cruisePackage.id)
                ? LocalizationString.value(this.cruisePackage.name)
                : "{0} *".format(this.cruisePackage.name)
            : "...";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.asCaptain) {
            this.state.serviceProviders = await this.fetchServiceProvidersAsync();
        }

        this.state.areas = await this.fetchAreasAsync();

        await this.reloadAsync();
    }

    private renderPoint(point: CruisePackagePoint, index: number, count: number): React.ReactNode {

        const first: boolean = (index == 0);
        const last: boolean = (index == count - 1);
        const single: boolean = (count == 1);

        const address: string = point.location?.formattedAddress || "";
        const valid: boolean = !!address;

        return (
            <Inline key={`${this.id}_point_${index}`}>

                <AddressInput locationPicker append inline
                              className={styles.point}
                              id={`${this.id}_point_${index}_location`}
                              value={address}
                              onChange={(location) => this.setPointAsync(point, location)}
                />

                <NumberInput inline
                             id={`${this.id}_point_${index}_price`}
                             className={styles.pointPrice}
                             value={point.price}
                             min={0}
                             max={MAX_PRICE}
                             append={this.moneySymbol}
                             format={"0.00"}
                             onChange={(_, value) => this.setPointPriceAsync(point, value)}
                />

                <Button icon="far fa-trash-alt"
                        type={ButtonType.Blue}
                        disabled={(single)}
                        onClick={() => this.deletePointAsync(point)}
                />

                <Button icon="fas fa-plus"
                        type={ButtonType.Blue}
                        disabled={(!last)}
                        onClick={() => this.addPointAsync()}
                />

                <Button icon="far fa-arrow-up"
                        type={ButtonType.Info}
                        disabled={(first || !valid)}
                        onClick={() => this.upPointAsync(point)}
                />

                <Button icon="far fa-arrow-down"
                        type={ButtonType.Info}
                        disabled={(last || !valid)}
                        onClick={() => this.downPointAsync(point)}
                />

            </Inline>
        );
    }

    private renderPrice(price: CruisePackagePrice, minDuration: number, index: number, count: number, priceInterval: CruisePackagePriceInterval): React.ReactNode {

        const last: boolean = (index == count - 1);
        const single: boolean = (count == 1);
        const duration: number = index / 2 + minDuration;
        const label: string = "{0} hour(s)".format(duration);
        const visibleControls: boolean = (priceInterval != CruisePackagePriceInterval.MondayTuesday);
        const canAddMore: boolean = (duration < AppConstants.maxTripDurationInHours);

        return (
            <Inline key={`${this.id}_price_${index}`}>

                <NumberInput inline
                             id={`${this.id}_price_${index}`}
                             className={styles.price}
                             label={label}
                             value={price.price}
                             min={0}
                             max={MAX_PRICE}
                             append={this.moneySymbol}
                             onChange={(sender, value) => this.setPriceAsync(price, value)}
                />

                {
                    (visibleControls) &&
                    (
                        <Button icon="far fa-trash-alt"
                                type={ButtonType.Blue}
                                disabled={(single)}
                                onClick={() => this.deletePriceAsync(price)}
                        />
                    )
                }

                {
                    (visibleControls) &&
                    (
                        <Button icon="fas fa-plus"
                                type={ButtonType.Blue}
                                disabled={(!last) || (!canAddMore)}
                                onClick={() => this.addPriceAsync(priceInterval)}
                        />
                    )
                }

            </Inline>
        );
    }

    private renderPriceColumn(prices: CruisePackagePrice[], minDuration: number, interval: CruisePackagePriceInterval): React.ReactNode {
        return (
            <div>

                <span className={styles.splitPriceInterval}>{Utility.formatValue(interval, nameof<CruisePackagePriceInterval>())}</span>

                {
                    prices.map((item: CruisePackagePrice, index: number) => this.renderPrice(item, minDuration, index, prices.length, interval))
                }

            </div>
        );
    }

    public render(): React.ReactNode {

        const points: CruisePackagePoint[] = this.cruisePackage?.points || [];
        const prices: CruisePackagePrice[] = this.cruisePackage?.prices || [];
        const leftPrices: CruisePackagePrice[] = prices.where(item => item.interval == CruisePackagePriceInterval.MondayTuesday);
        const rightPrices: CruisePackagePrice[] = prices.where(item => item.interval == CruisePackagePriceInterval.WednesdaySunday);
        const minDurationInHours: number = this.cruisePackage?.minDurationInHours ?? 1;

        return (
            <PageContainer className={this.css(styles.cruisePackageManagement, "ignore-mobile")}>

                <PageHeader title={this.getTitle()} subtitle={this.getSubtitle()}/>

                <PageRow>
                    <div className="col">

                        <ToolbarContainer className={styles.toolbar}>

                            <Inline justify={JustifyContent.End} className={styles.inline}>

                                <Button title={Localizer.genericReload} className="ml-1"
                                        icon={{name: "far history", size: IconSize.Large}}
                                        type={ButtonType.Info}
                                        confirm={this.isModified() ? Localizer.cruiseManagementPageSureWantToReload : undefined}
                                        onClick={() => this.reloadAsync()}
                                />

                                <Button title={Localizer.genericPreview}
                                        icon={{name: "search", size: IconSize.Large}}
                                        type={ButtonType.Light}
                                        disabled={!this.isValid()}
                                        onClick={() => this.previewAsync()}
                                />
                                <Button title={Localizer.genericPreview}
                                        icon={{name: "fad fa-external-link", size: IconSize.Large}}
                                        type={ButtonType.Light}
                                        disabled={!this.isValid() || (!this.cruisePackage?.path)}
                                        onClick={() => this.openLinkAsync()}
                                />

                                <Button right
                                        title={Localizer.genericBack}
                                        icon={{name: "fas arrow-alt-circle-left"}}
                                        type={ButtonType.Primary}
                                        route={PageDefinitions.cruisePackagesManagementRoute}
                                />

                            </Inline>

                        </ToolbarContainer>

                        {
                            (this.cruisePackage) &&
                            (
                                <Form onSubmit={() => this.submitCruisePackageAsync()}>

                                    <TabContainer id={"tabs"} renderType={TabRenderType.Always} dataStorageType={DataStorageType.Route}>

                                        <Tab id={"package"} title={Localizer.cruiseManagementPagePackage} icon={this.packageTabIcon}>

                                            <ThreeColumns>

                                                <Dropdown required noWrap
                                                          requiredType={DropdownRequiredType.AutoSelect}
                                                          align={DropdownAlign.Left}
                                                          orderBy={DropdownOrderBy.None}
                                                          id="ownerType"
                                                          label={Localizer.cruiseManagementPageOwnerType}
                                                          items={EnumProvider.getCruisePackageOwnerTypeItems()}
                                                          disabled={this.asCaptain}
                                                          selectedItem={this.cruisePackage.ownerType}
                                                          onChange={(sender, item) => this.setOwnerTypeAsync(parseInt(item!.value))}
                                                />

                                                {
                                                    (this.cruisePackage.ownerType == CruisePackageOwnerType.ServiceProvider)
                                                        ?
                                                        (
                                                            <Dropdown required noWrap
                                                                      requiredType={DropdownRequiredType.Restricted}
                                                                      align={DropdownAlign.Left}
                                                                      id="serviceProviders"
                                                                      label={Localizer.cruiseManagementPageServiceProvider}
                                                                      items={this.state.serviceProviders}
                                                                      selectedItem={this.cruisePackage.serviceProvider}
                                                                      onChange={(sender, item) => this.setServiceProviderAsync(item!)}
                                                            />
                                                        )
                                                        :
                                                        (
                                                            <TextInput id={"captain"}
                                                                       readonly
                                                                       label={Localizer.cruiseManagementPageCaptain}
                                                                       value={"{0}".format(this.boat?.captain)}
                                                            />
                                                        )
                                                }

                                            </ThreeColumns>

                                            <ThreeColumns>

                                                <Dropdown required noWrap
                                                          requiredType={DropdownRequiredType.Restricted}
                                                          align={DropdownAlign.Left}
                                                          id="areas"
                                                          label={Localizer.cruiseManagementPageArea}
                                                          items={this.state.areas}
                                                          selectedItem={this.cruisePackage.area}
                                                          orderBy={DropdownOrderBy.None}
                                                          onChange={(sender, item) => this.setAreaAsync(item!)}
                                                />

                                                <Dropdown required noWrap
                                                          requiredType={DropdownRequiredType.Restricted}
                                                          align={DropdownAlign.Left}
                                                          id="boats"
                                                          label={Localizer.cruiseManagementPageBoat}
                                                          items={this.state.boats}
                                                          selectedItem={this.cruisePackage.boat}
                                                          disabled={(this.state.boats.length == 0)}
                                                          onChange={(sender, item) => this.setBoatAsync(item!)}
                                                />

                                            </ThreeColumns>

                                            <OneColumn>

                                                <LocalizationTextInput id="name" required noAutoComplete
                                                                       label={Localizer.cruiseManagementPageName}
                                                                       value={this.cruisePackage.name}
                                                                       onChange={(sender, value) => this.setNameAsync(value)}
                                                />

                                            </OneColumn>

                                            <OneColumn>

                                                <LocalizationTextInput id="details" required noAutoComplete
                                                                       rows={5}
                                                                       maxLength={AppConstants.descriptionLength}
                                                                       label={Localizer.cruiseManagementPageDetails}
                                                                       type={LocalizationTextInputType.TextAreaInput}
                                                                       value={this.cruisePackage.details}
                                                                       onChange={(sender, value) => this.setDetailsAsync(value)}
                                                />

                                            </OneColumn>

                                        </Tab>

                                        <Tab id={"options"} title={Localizer.cruiseManagementPageOptions} icon={this.optionsTabIcon}>

                                            <FourColumns>

                                                <Checkbox id="petFriendly" readonly
                                                          label={Localizer.cruiseManagementPagePetFriendly}
                                                          value={this.cruisePackage.boat?.petFriendly}
                                                />

                                                <Checkbox id="disabledFriendly" readonly
                                                          label={Localizer.cruiseManagementPageWheelchair}
                                                          value={this.cruisePackage.boat?.disabledFriendly}
                                                />

                                                <Checkbox id="toilet" readonly
                                                          label={Localizer.cruiseManagementPageToilet}
                                                          value={this.cruisePackage.boat?.toilet}
                                                />

                                                <TextInput id={"boatMaxCapacity"} readonly
                                                           className={styles.boatMaxCapacity}
                                                           label={Localizer.cruiseManagementPageBoatMaxCapacity}
                                                           value={this.boat?.maxCapacity?.toString()}
                                                />

                                            </FourColumns>

                                            <FourColumns>

                                                <Checkbox id="foodOptions"
                                                          label={Localizer.cruiseManagementPageFoodOptions}
                                                          value={this.cruisePackage.food}
                                                          onChange={(sender, value) => this.setFoodOptionsAsync(value)}
                                                />

                                                <Checkbox id="drinks"
                                                          label={Localizer.cruiseManagementPageDrinks}
                                                          value={this.cruisePackage.drinks}
                                                          onChange={(sender, value) => this.setDrinksAsync(value)}
                                                />

                                                <Checkbox id="customLocation"
                                                          label={Localizer.cruiseManagementPageCustomLocation}
                                                          value={this.cruisePackage.customLocation}
                                                    // Temporary locked: the customer price specification is needed!
                                                          readonly={!this.cruisePackage.customLocation}
                                                          onChange={(sender, value) => this.setCustomLocationAsync(value)}
                                                />

                                                <TextInput id={"boatSeats"} readonly
                                                           className={styles.boatSeats}
                                                           label={Localizer.cruiseManagementPageBoatSeats}
                                                           value={this.boat?.seats?.toString()}
                                                />

                                            </FourColumns>

                                            <FourColumns>

                                                <NumberInput id={"maxCapacity"}
                                                             label={Localizer.cruiseManagementPageMaxCapacity}
                                                             value={this.cruisePackage.maxCapacity}
                                                             min={1}
                                                             max={this.boat?.maxCapacity || AppConstants.boatMaxCapacity}
                                                             className={styles.maxCapacity}
                                                             onChange={(sender, value) => this.setMaxCapacityAsync(value)}
                                                />

                                            </FourColumns>

                                            <FourColumns>

                                                <Checkbox id="hasSeason"
                                                          label={Localizer.cruiseManagementPageHasSeason}
                                                          value={this.hasSeason}
                                                          onChange={(sender, value) => this.setHasSeasonAsync(value)}
                                                />

                                            </FourColumns>

                                            { 
                                                (this.hasSeason) && 
                                                (
                                                    <FourColumns>
                                                        
                                                        <div className={styles.seasonDateContainer}>

                                                            <DateInput showMonthDropdown
                                                                       id={"seasonStartDate"}
                                                                       ref={this._seasonStartsAtInputRef}
                                                                       label={Localizer.cruiseManagementPageSeasonStartsAt}
                                                                       value={this.seasonStartsAt}
                                                                       //format={"dd.MM"}
                                                                       minDate={this.seasonStartMinDate}
                                                                       maxDate={this.seasonStartMaxDate}
                                                                       onChange={(value: Date) => this.setSeasonStartsAtAsync(value)}
                                                            />
                                                            
                                                        </div>

                                                        <div className={styles.seasonDateContainer}>

                                                            <DateInput showMonthDropdown
                                                                       id={"seasonEndDate"}
                                                                       label={Localizer.cruiseManagementPageSeasonEndsAt}
                                                                       ref={this._seasonEndsAtInputRef}
                                                                       value={this.seasonEndsAt}
                                                                       //format={"dd.MM"}
                                                                       minDate={this.seasonEndMinDate}
                                                                       maxDate={this.seasonEndMaxDate}
                                                                       onChange={(value: Date) => this.setSeasonEndsAtAsync(value)}
                                                            />

                                                        </div>

                                                    </FourColumns>
                                                )
                                            }

                                        </Tab>

                                        <Tab id={"points"} title={Localizer.cruiseManagementPagePoints} icon={this.pointsTabIcon}>

                                            {
                                                points.map((item, index) => this.renderPoint(item, index, points.length))
                                            }

                                        </Tab>

                                        <Tab id={"images"} title={Localizer.cruiseManagementPageImages} icon={this.imagesTabIcon}>

                                            <ImageInput multi
                                                        thumbnails={{width: 328, height: 160, borderRadius: 15}}
                                                        noSelectionToolbar={{uploadButton: true}}
                                                        selectionToolbar={{editButton: true, deleteButton: true, previewButton: true, uploadButton: true, moveUpButton: true, moveDownButton: true, moveToTopButton: true}}
                                                        maxImageRequestSizeInBytes={AppConstants.maxFileUploadSizeInBytes}
                                                        pictures={this.images}
                                                        imageUrl={(file) => this.imageUrl(file)}
                                                        convertImage={(file) => this.convertImageAsync(file)}
                                                        onChange={(sender, values) => this.setImageAsync(values)}
                                            />

                                        </Tab>

                                        <Tab id={"price"} title={Localizer.cruiseManagementPagePrice} icon={this.priceTabIcon}>

                                            {
                                                (this.cruisePackage.customLocation) &&
                                                (
                                                    <NumberInput id={"customLocationPrice"} noAutoComplete
                                                                 inline
                                                                 step={0.01}
                                                                 min={0}
                                                                 max={MAX_PRICE}
                                                                 className={styles.customLocationPrice}
                                                                 behaviour={NumberInputBehaviour.Restricted}
                                                                 label={Localizer.cruiseManagementPageCustomLocationPrice}
                                                                 value={this.cruisePackage.customLocationPrice}
                                                                 onChange={(_, value) => this.setCustomLocationPriceAsync(value)}
                                                    />
                                                )
                                            }

                                            <div className={styles.splitContainer}>

                                                <Checkbox id="splitPriceModel" inline
                                                          inlineType={InlineType.Right}
                                                          className={styles.splitPriceModel}
                                                          label={Localizer.cruiseManagementPageSplitPriceModel}
                                                          value={this.state.splitPriceModel}
                                                          onChange={(_, value) => this.setSplitPriceModelAsync(value)}
                                                />

                                                <NumberInput id="minDuration"
                                                             inline
                                                             behaviour={NumberInputBehaviour.Restricted}
                                                             className={styles.minDuration}
                                                             label={Localizer.cruiseManagementPageMinDuration}
                                                             min={0.5}
                                                             max={AppConstants.maxTripDurationInHours}
                                                             step={0.5}
                                                             format={"0.00"}
                                                             value={this.cruisePackage.minDurationInHours}
                                                             onChange={(sender, value) => this.setMinDurationAsync(value)}
                                                />

                                            </div>

                                            <TwoColumns>

                                                {
                                                    (this.state.splitPriceModel)
                                                        ?
                                                        (
                                                            <>

                                                                {this.renderPriceColumn(leftPrices, minDurationInHours, CruisePackagePriceInterval.MondayTuesday)}

                                                                {this.renderPriceColumn(rightPrices, minDurationInHours, CruisePackagePriceInterval.WednesdaySunday)}

                                                            </>
                                                        )
                                                        :
                                                        (
                                                            this.renderPriceColumn(prices, minDurationInHours, CruisePackagePriceInterval.MondaySunday)
                                                        )
                                                }

                                            </TwoColumns>

                                        </Tab>

                                    </TabContainer>

                                    <ButtonContainer>

                                        <Button submit
                                                icon={{name: "save", size: IconSize.Large}}
                                                label={Localizer.genericSave}
                                                type={ButtonType.Primary}
                                        />

                                    </ButtonContainer>

                                </Form>
                            )
                        }

                    </div>
                </PageRow>

                <CruisePackagePreviewModal id={"cruisePackagePreviewModal"}
                                           ref={this._cruisePackagePreviewModalRef}
                />

            </PageContainer>
        );
    }
}
// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListBoatsRequest extends ETagRequest {

    public areaId: string | null = null;

    public boatPriceId: string | null = null;

    public includeDeleted: boolean = false;

    public asCaptain: boolean = false;

    public includeImages: boolean = false;

    public readonly isListBoatsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListBoatsRequest) || ((from as any).isListBoatsRequest === true));
    }

    public static as(from?: object | null): ListBoatsRequest | null {
        return (ListBoatsRequest.is(from)) ? from as ListBoatsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
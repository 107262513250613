import React from "react";
import {ApiProvider, BasePageParameters, LdData} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    Form,
    IStringInputModel,
    LdDataHeader,
    PageContainer,
    PageHeader,
    PageRow,
    PasswordInput,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import LoginRequest from "../../models/server/requests/LoginRequest";
import {LoginResultStatus} from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

import styles from "./Login.module.scss";

import loading from "../../img/gif/waving_boat.webp";

export interface ILoginProps extends BasePageParameters {
}

interface ILoginState {
    applicationLdData: LdData | null;
}

export default class Login extends AnonymousPage<ILoginProps, ILoginState> {
    
    state: ILoginState = {
        applicationLdData: null,
    };

    private _usernameInput: IStringInputModel = {value: ""};
    private _passwordInput: IStringInputModel = {value: ""};
    public _formRef: React.RefObject<any> = React.createRef();

    private async loginAsync(username: string, password: string): Promise<void> {

        const login = new LoginRequest(username, password);
        const loginResult: LoginResultStatus = await this.postAsync("/api/Application/Login", login);

        if (loginResult !== LoginResultStatus.Success) {
            const form: Form | null = this._formRef.current;
            if (form != null) {
                const validationError: string = EnumProvider.getLoginResultStatusName(loginResult);
                await form.setValidationErrorsAsync(validationError);
            }
        }
    }

    private async onSsoClickAsync(action: string): Promise<void> {
        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => this.getAsync<string>(`/api/application/${action}`), true);
    }

    private onGoogleClickAsync(): Promise<void> {
        return this.onSsoClickAsync("GetGoogleSsoLogin");
    }

    private async fetchLdDataAsync(): Promise<void> {
        const pageUrl: string = window.location.origin;

        const applicationLdData: LdData = (ServiceProviderController.isHds)
            ? await this.getAsync("/api/application/getLandingPageLdData")
            : await this.postAsync("/api/application/getApplicationLdData", pageUrl);

        await this.setState({ applicationLdData });
    }

    public get canonical(): string {
        return (ServiceProviderController.isPjta) 
            ? "/login"
            : "/";
    }

    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.topNavLogin);
    }

    public get hasLanguageSelector(): boolean {
        return true;
    }

    private async handleSubmitAsync(): Promise<void> {
        await this.loginAsync(this._usernameInput.value, this._passwordInput.value);
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        Utility.background(() => this.fetchLdDataAsync());
    }
    
    public render(): React.ReactNode {
        const mobileAppContent: boolean = AppController.mobileAppContent;

        return (
            <PageContainer transparent
                           fullWidth={this.mobile}
                           className={this.css(styles.login, mobileAppContent && styles.mobileApp)}>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={this.canonical}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />

                {
                    (!mobileAppContent) &&
                    (
                        <PageHeader title={Localizer.loginPageTitle}
                                    subtitle={Localizer.loginPageSubtitle}
                                    className={styles.header}
                        />
                    )
                }

                <PageRow>

                    <div className={this.css(styles.container, "col-lg-6")}>

                        <Form id="form" ref={this._formRef}
                              className={styles.form}
                              onSubmit={() => this.handleSubmitAsync()}
                        >

                            <div className={styles.logo}>

                                <img src={loading} alt="waving_boat" width={250} height={250} />

                            </div>

                            <TextInput id="username" required autoFocus
                                       label={Localizer.loginPageEmailInput}
                                       model={this._usernameInput}
                            />

                            <PasswordInput id="password" required
                                           label={Localizer.loginPagePasswordInput}
                                           model={this._passwordInput}
                            />

                            <div className={styles.buttonContainer}>

                                <Button type={ButtonType.Link}
                                        label={Localizer.loginPageForgotPasswordButton}
                                        route={PageDefinitions.forgotPasswordRoute}
                                />

                                <Button type={ButtonType.Link}
                                        label={Localizer.topNavContactSupport}
                                        route={PageDefinitions.contactSupportRoute}
                                />

                                <div className={styles.expander}/>

                                <Button submit
                                        className={styles.signIn}
                                        type={ButtonType.Blue}
                                        label={Localizer.loginPageLoginButton}
                                        icon={{name: "fas sign-in-alt"}}
                                />

                                {
                                    (ServiceProviderController.userSignUpAvailable) &&
                                    (
                                        <Button type={ButtonType.Orange}
                                                className={styles.signUp}
                                                icon={{name: "fas user-plus"}}
                                                label={Localizer.loginPageSignUpButton}
                                                route={PageDefinitions.signUpRoute}
                                        />
                                    )
                                }

                                {/*<Button type={ButtonType.Light}*/}
                                {/*        label={"Continue with Google"}*/}
                                {/*        icon={"fab fa-google"}*/}
                                {/*        onClick={() => this.onGoogleClickAsync()}*/}
                                {/*/>*/}

                            </div>

                        </Form>
                    </div>
                </PageRow>

            </PageContainer>
        );
    }
}
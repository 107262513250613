import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {Button, ButtonType, PageContainer, PageRow, Tab, TabContainer, TabRenderType} from "@reapptor-apps/reapptor-react-components";
import Booking from "@/models/server/bout/Booking";
import BookingContainer from "@/pages/Mobile/MyTrips/BookingContainer/BookingContainer";
import {BookingStatus} from "@/models/Enums";
import ListMyBookingsRequest from "@/models/server/requests/ListMyBookingsRequest";
import ListMyBookingsResponse from "@/models/server/responses/ListMyBookingsResponse";import {
    BaseComponent, ch,
    DataStorageType,
    IBasePage,
    PageRoute,
    PageRouteProvider
} from "@reapptor-apps/reapptor-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./MyTrips.module.scss";

export interface IMyTripsProps extends BaseComponent {
    bookedOnly?: boolean;
    confirmedOnly?: boolean;
}

export interface IMyTripsPage extends IBasePage {
    reloadAsync(): Promise<void>;
}

interface IMyTripsState {
    initialized: boolean;
    bookings: Booking[];
    completedCount: number;
    reservations: Booking[];
    approved: Booking[];
    completed: Booking[];
}

export default class MyTrips extends AuthorizedPage<IMyTripsProps, IMyTripsState> implements IMyTripsPage {

    state: IMyTripsState = {
        initialized: false,
        bookings: [],
        completedCount: 10,
        reservations: [],
        approved: [],
        completed: [],
    };
    
    private async fetchMyBookingsAsync(): Promise<Booking[]> {
        const request = new ListMyBookingsRequest();
        request.asCaptain = AppController.asCaptain;
        request.activeOnly = false;

        const response: ListMyBookingsResponse = await this.postAsync("/api/mobileApp/listMyBookings", request);

        return response.bookings;
    }
    
    private async openBookingAsync(booking: Booking): Promise<void> {
        const route: PageRoute = PageDefinitions.bookingDetails(booking);
        await PageRouteProvider.redirectAsync(route);
    }

    private async showMoreCompletedAsync(): Promise<void> {
        const completedCount: number = this.completedCount + 10;
        await this.setState({ completedCount });
    }

    private getReservations(bookings: Booking[]): Booking[] {
        const statuses: BookingStatus[] = [BookingStatus.New];
        const expiredTripCanBeStartedAfterInHours: number = AppController.settings.expiredTripCanBeStartedAfterInHours;
        return bookings.where(item => statuses.includes(item.latestStatus) && (!Booking.expired(item, expiredTripCanBeStartedAfterInHours)));
    }

    private getApproved(bookings: Booking[]): Booking[] {
        const statuses: BookingStatus[] = [ BookingStatus.AcceptedByCaptain, BookingStatus.StartByCaptain ];
        const expiredTripCanBeStartedAfterInHours: number = AppController.settings.expiredTripCanBeStartedAfterInHours;
        return bookings.where(item => statuses.includes(item.latestStatus) && (!Booking.expired(item, expiredTripCanBeStartedAfterInHours)));
    }

    private getCompleted(bookings: Booking[]): Booking[] {
        return bookings.where(item => Booking.completed(item));
    }

    public getTitle(): string {
        return Localizer.myTripsPageTitle;
    }

    public get initialized(): boolean {
        return this.state.initialized;
    }

    public get asCaptain(): boolean {
        return AppController.asCaptain;
    }

    public get isEntrepreneur(): boolean {
        return AppController.isEntrepreneur;
    }

    public get asPassenger(): boolean {
        return AppController.asPassenger;
    }

    public get bookedOnly(): boolean {
        return ((this.parameters as IMyTripsProps)?.bookedOnly == true);
    }

    public get confirmedOnly(): boolean {
        return ((this.parameters as IMyTripsProps)?.confirmedOnly == true);
    }

    public get approved(): Booking[] {
        return this.state.approved;
    }

    public get reservations(): Booking[] {
        return this.state.reservations;
    }

    public get completed(): Booking[] {
        return this.state.completed;
    }

    public get completedCount(): number {
        return this.state.completedCount;
    }

    public get bookings(): Booking[] {
        return this.state.bookings;
    }

    public get visibleCompleted(): Booking[] {
        return this.completed.take(this.completedCount);
    }
    
    public get hasMoreCompleted(): boolean {
        return (this.completed.length > this.completedCount);
    }
    
    public async reloadAsync(): Promise<void> {
        const bookings: Booking[] = await this.fetchMyBookingsAsync();

        const reservations: Booking[] = this.getReservations(bookings);

        const approved: Booking[] = this.getApproved(bookings);

        const completed: Booking[] = this.getCompleted(bookings);

        await this.setState({bookings, reservations, approved, completed, initialized: true});
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
        
       await this.reloadAsync();
    }

    public static routeResolver(pathname: string): PageRoute | null {
        pathname = pathname.toLowerCase();
        pathname = `/${ServiceProviderController.trimUrl(pathname)}`;
        if (pathname.endsWith(`/${PageDefinitions.myTripsAlias}`)) {
            PageRouteProvider.changeUrlWithoutReload(ServiceProviderController.applicationUrl).then();
            return PageDefinitions.myTripsRoute;
        }
        return null;
    }

    public render(): React.ReactNode {
        
        const reservationsVisible: boolean = (!this.confirmedOnly) && (!ServiceProviderController.isShuttleApplicationOnly);
        const approvedVisible: boolean = (!this.bookedOnly);
        const completedVisible: boolean = ((!this.bookedOnly) && (!this.confirmedOnly));
        
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.myTrips, this.mobile && styles.mobile)}
                           alertClassName={boutStyles.alert}
            >

                <span className={styles.header}>{this.getTitle()}</span>

                <PageRow>
                    
                    {
                        (this.initialized) &&
                        (
                            <TabContainer id="myTrips" className={styles.tabContainer} dataStorageType={DataStorageType.Page} renderType={TabRenderType.ActiveOnly}>

                                {
                                    (reservationsVisible) &&
                                    (
                                        <Tab id={"reservations"}
                                             title={Localizer.myTripsTabReservationsLanguageItemName}
                                             count={this.reservations.length > 0 ? this.reservations.length : undefined}
                                             countClassName={this.css(styles.count, styles.reservations)}
                                        >

                                            <BookingContainer bookings={this.reservations}
                                                              asCaptain={this.asCaptain}
                                                              isEntrepreneur={this.isEntrepreneur}
                                                              onClick={(_, booking) => this.openBookingAsync(booking)}
                                            />

                                        </Tab>
                                    )
                                }

                                {
                                    (approvedVisible) &&
                                    (
                                        <Tab id={"approved"}
                                             title={Localizer.myTripsTabApprovedLanguageItemName}
                                             count={this.approved.length > 0 ? this.approved.length : undefined}
                                             countClassName={this.css(styles.count, styles.approved)}
                                        >

                                            <BookingContainer bookings={this.approved}
                                                              asCaptain={this.asCaptain}
                                                              isEntrepreneur={this.isEntrepreneur}
                                                              onClick={(_, booking) => this.openBookingAsync(booking)}
                                            />

                                        </Tab>
                                    )
                                }

                                {
                                    (completedVisible) &&
                                    (
                                        <Tab id={"completed"} title={Localizer.myTripsTabCompletedLanguageItemName}>

                                            <BookingContainer bookings={this.visibleCompleted}
                                                              asCaptain={this.asCaptain}
                                                              isEntrepreneur={this.isEntrepreneur}
                                                              onClick={(_, booking) => this.openBookingAsync(booking)}
                                            />

                                            {
                                                (this.hasMoreCompleted) &&
                                                (
                                                    <Button label={Localizer.genericShowMore}
                                                            type={ButtonType.Link}
                                                            className={styles.showMore}
                                                            onClick={() => this.showMoreCompletedAsync()}
                                                    />
                                                )
                                            }

                                        </Tab>
                                    )
                                }

                            </TabContainer>
                        )
                    }
                    
                </PageRow>

            </PageContainer>
        );
    }
}
// autogenerated

export default class FuelConsumption {

    public id: string | null = null;

    public horsePower: number = 0;

    public consumption: number = 0;

    public readonly isFuelConsumption: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof FuelConsumption) || ((from as any).isFuelConsumption === true));
    }

    public static as(from?: object | null): FuelConsumption | null {
        return (FuelConsumption.is(from)) ? from as FuelConsumption : null;
    }

    // #region Placeholder

    public isNew: boolean = false;

    public markedAsDeleted: boolean = false;

    // #endregion
}
import {BaseTransformProvider, Utility, GeoLocation, TFormat, GeoCoordinate, Language, ILanguage} from "@reapptor-apps/reapptor-toolkit";
import {SelectListGroup, SelectListItem, StatusListItem, ITitleModel, IGoogleMapMarker, TGoogleMapMarkerCallback, AddressHelper} from "@reapptor-apps/reapptor-react-components";
import Organization from "../models/server/Organization";
import User from "../models/server/User";
import UserRole from "../models/server/UserRole";
import OrganizationContract from "../models/server/OrganizationContract";
import ServicePoint from "@/models/server/bout/ServicePoint";
import Waypoint from "@/models/server/bout/Waypoint";
import Area from "@/models/server/bout/Area";
import StripeKycStatusItem from "@/models/server/stripe/kycstatus/StripeKycStatusItem";
import {LocalizationString, LocalizationStringItem} from "@reapptor-apps/reapptor-react-common";
import Country from "@/models/server/bout/Country";
import ServiceProvider from "@/models/server/ServiceProvider";
import Boat from "@/models/server/bout/Boat";
import {BoatType} from "@/models/Enums";
import BoatImage from "@/models/server/BoatImage";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import Localizer from "../localization/Localizer";

import servicePointImage from "../img/SourceServicePoint.svg";

import daFlag from "../img/flags/da.png";
import deFlag from "../img/flags/de.png";
import enFlag from "../img/flags/en.png";
import dkFlag from "../img/flags/dk.png";
import fiFlag from "../img/flags/fi.png";
import nbFlag from "../img/flags/nb.png";
import noFlag from "../img/flags/no.png";
import plFlag from "../img/flags/pl.png";
import seFlag from "../img/flags/se.png";
import svFlag from "../img/flags/sv.png";
import ruFlag from "../img/flags/ru.png";
import ukFlag from "../img/flags/uk.png";
import uaFlag from "../img/flags/ua.png";
import frFlag from "../img/flags/fr.png";
import gbFlag from "../img/flags/gb.png";
import usFlag from "../img/flags/us.png";
import itFlag from "../img/flags/it.png";
import esFlag from "../img/flags/es.png";
import nlFlag from "../img/flags/nl.png";

const flags: any = {
    //Language codes:
    fi: fiFlag,
    sv: svFlag,
    nb: nbFlag,
    da: daFlag,
    pl: plFlag,
    ru: ruFlag,
    uk: ukFlag,
    de: deFlag,
    fr: frFlag,
    en: enFlag,
    it: itFlag,
    es: esFlag,
    nl: nlFlag,
    // Country codes
    dk: dkFlag,
    no: noFlag,
    se: seFlag,
    ua: uaFlag,
    gb: gbFlag,
    us: usFlag,
};

class TransformProvider extends BaseTransformProvider {

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite == true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }

    public constructor() {
        super();
    }

    public toImage(language: ILanguage): string {
        return flags[language.code];
    }
    
    public toTitle(item: any): ITitleModel {

        let label: string | null = null;
        let description: string | null = null;

        if (item != null) {
            label = Utility.findStringValueByAccessor(item, ["label", "name"]);
            description = Utility.findStringValueByAccessor(item, ["description", "text"]);
        }

        return {
            description: description || "",
            label: label || "",
            icon: null
        };
    }

    public toMarker(item: any, image?: string | null, size?: number | null, offsetY?: number | null, title?: string | null, onClick?: TGoogleMapMarkerCallback | null, onDoubleClick?: TGoogleMapMarkerCallback | null): IGoogleMapMarker {
        if ((item instanceof ServicePoint) || (item.isServicePoint)) {
            return this.servicePointToMarker(item as ServicePoint, image, size, offsetY, title, onClick, onDoubleClick);
        }

        if ((item instanceof GeoCoordinate) || (item.isGeoCoordinate)) {
            return this.geoCoordinateToMarker(item as GeoCoordinate, image, size, offsetY, onClick, onDoubleClick);
        }

        return {};
    }

    public userToString(item: User): string {
        return User.getFullName(item, item.username);
    }

    public stripeErrorCodeToString(code: string | null): string {
        const id: string = `Stripe.ErrorCode.${code}`;
        return Localizer.contains(id)
            ? Localizer.get(id)
            : Localizer.stripeErrorCodeGenericError;
    }

    public userRoleToString(item: UserRole): string {
        const id: string = `RoleName.${item.roleName}`;
        return Localizer.contains(id)
            ? Localizer.get(id)
            : item.roleName;
    }

    public localizationStringToString(item: LocalizationString): string {
        return LocalizationString.value(item);
    }

    public localizationStringItemToString(item: LocalizationStringItem): string {
        return item.value;
    }

    public areaToString(item: Area): string {
        return item.name;
    }

    public organizationToString(item: Organization): string {
        return (item.vatId)
            ? `${item.name}, ${item.vatId}`
            : item.name;
    }

    public organizationContractToString(item: OrganizationContract): string {
        return (item.externalId)
            ? `${item.name}, ${item.externalId}`
            : item.name;
    }

    public locationToString(location: GeoLocation | null, compact: boolean = false): string {
        return (location != null)
            ? ((location.address) || (location.city))
                ? (compact)
                    ?  [location.address, location.city].where(item => !!item).join(", ")
                    : [location.address, location.postalCode, location.city].where(item => !!item).join(", ")
                : location.formattedAddress
            : "";
    }

    public servicePointToString(servicePoint: ServicePoint | null): string {
        let areaAlias: string;
        return (servicePoint != null)
            ? ((servicePoint.areaAlias) && (areaAlias = servicePoint.areaAlias.trim()))
                ? areaAlias
                : servicePoint.name.trim()
            : "";
    }

    public waypointToString(waypoint: Waypoint | null): string {
        return ((waypoint != null) && (waypoint.source != null) && (waypoint.destination != null))
            ? `${waypoint.source.name} - ${waypoint.destination.name}`
            : Localizer.transformProviderNewWaypoint;
    }

    public cruisePackageToString(cruisePackage: CruisePackage): string {
        return LocalizationString.value(cruisePackage.name);
    }

    public boatToString(boat: Boat): string {
        return ((boat.model) && (boat.brand))
            ? (boat.model != boat.brand)
                ? `${boat.model} ${boat.brand}`
                : boat.model
            : (boat.model)
                ? boat.model ?? ""
                : boat.brand ?? "";
    }

    public serviceProviderToString(serviceProvider: ServiceProvider): string {
        return LocalizationString.value(serviceProvider.name);
    }

    public countryToString(country: Country): string {
        return country.name!;
    }

    public toString(item: any, format?: TFormat | null): string {

        if (item == null) {
            return "";
        }

        if ((item instanceof LocalizationString) || (item.isLocalizationString)) {
            return this.localizationStringToString(item as LocalizationString);
        }

        if ((item instanceof LocalizationStringItem) || (item.isLocalizationStringItem)) {
            return this.localizationStringItemToString(item as LocalizationStringItem);
        }

        if ((item instanceof Area) || (item.isArea)) {
            return this.areaToString(item as Area);
        }

        if ((item instanceof Organization) || item.isOrganization) {
            return this.organizationToString(item as Organization);
        }

        if ((item instanceof OrganizationContract) || item.isOrganizationContract) {
            return this.organizationContractToString(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser === true)) {
            return this.userToString(item as User);
        }

        if ((item instanceof UserRole) || (item.isUserRole)) {
            return this.userRoleToString(item as UserRole);
        }

        if ((item instanceof GeoLocation) || item.isGeoLocation) {
            return this.locationToString(item as GeoLocation);
        }

        if ((item instanceof ServicePoint) || item.isServicePoint) {
            return this.servicePointToString(item as ServicePoint);
        }

        if ((item instanceof Waypoint) || item.isWaypoint) {
            return this.waypointToString(item as Waypoint);
        }

        if ((item instanceof CruisePackage) || item.isCruisePackage) {
            return this.cruisePackageToString(item as CruisePackage);
        }

        if ((item instanceof Boat) || item.isBoat) {
            return this.boatToString(item as Boat);
        }

        if ((item instanceof ServiceProvider) || item.isServiceProvider) {
            return this.serviceProviderToString(item as ServiceProvider);
        }

        if ((item instanceof Country) || item.isCountry) {
            return this.countryToString(item as Country);
        }

        return super.toString(item, format);
    }

    public servicePointToMarker(servicePoint: ServicePoint, image?: string | null, size?: number | null, offsetY?: number | null, title?: string | null, onClick?: TGoogleMapMarkerCallback | null, onDoubleClick?: TGoogleMapMarkerCallback | null): IGoogleMapMarker {
        const location: GeoLocation = servicePoint.location!;

        if (title == null) {
            //title = servicePoint.name;
            const address: string = Utility.formatValue(location)
                .replace(", ", "<br/>")
                .replace(", ", " ");
            const mapsLink: string | null = ServicePoint.generateGoogleMapsLink(servicePoint);
            title = `<b>${servicePoint.name}</b><br/>${address}<br/><a href="${mapsLink}" target="_blank">${Localizer.transformProviderViewOnGoogleMaps}</a>`;
        }

        return AddressHelper.toMarker(location, title, image ?? servicePointImage, size, offsetY, onClick || undefined, onDoubleClick || undefined);
    }

    public geoCoordinateToMarker(geoCoordinate: GeoCoordinate, image?: string | null, size?: number | null, offsetY?: number | null, onClick?: TGoogleMapMarkerCallback | null, onDoubleClick?: TGoogleMapMarkerCallback | null): IGoogleMapMarker {
        return AddressHelper.toMarker(geoCoordinate, null, image ?? servicePointImage, size, offsetY, onClick || undefined, onDoubleClick || undefined);
    }

    public localizeStripeKycStatusItem(item: StripeKycStatusItem): string {
        const localizedItemCode: string = item.code;

        if (item.itemLocalizationName) {
            const localizedString: string = Localizer.get(item.itemLocalizationName)

            if (localizedString) {
                return localizedString;
            }
        }

        return localizedItemCode;
    }

    public toSelectListItem(item: any): SelectListItem {

        if ((item instanceof Organization) || (item.isOrganization)) {
            return this.toOrganizationListItem(item as Organization);
        }

        if ((item instanceof OrganizationContract) || (item.isOrganizationContract)) {
            return this.toOrganizationContractListItem(item as OrganizationContract);
        }

        if ((item instanceof User) || (item.isUser)) {
            return this.toUserListItem(item as User);
        }

        if ((item instanceof Language) || (item.isLanguage)) {
            return this.toLanguageListItem(item as Language);
        }

        if ((item instanceof Country) || (item.isCountry)) {
            return this.toCountryListItem(item as Country);
        }
        
        if ((item instanceof UserRole) || (item.isUserRole)) {
            return this.toUserRoleListItem(item as UserRole);
        }

        if ((item instanceof ServicePoint) || (item.isServicePoint)) {
            return this.toServicePointListItem(item as ServicePoint);
        }

        if ((item instanceof Waypoint) || (item.isWaypoint)) {
            return this.toWaypointListItem(item as Waypoint);
        }

        if ((item instanceof Area) || (item.isArea)) {
            return this.toAreaListItem(item as Area);
        }

        if ((item instanceof ServiceProvider) || (item.isServiceProvider)) {
            return this.toServiceProviderListItem(item as ServiceProvider);
        }

        if ((item instanceof Boat) || (item.isBoat)) {
            return this.toBoatListItem(item as Boat);
        }

        if ((item instanceof CruisePackagePoint) || (item.isCruisePackagePoint)) {
            return this.toCruisePackagePointListItem(item as CruisePackagePoint);
        }

        return super.toSelectListItem(item) as SelectListItem;
    }

    public toUserListItem(item: User, createdAt: boolean = false): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = ((createdAt) && (item.lastLogin))
            ? "{0} <i>{1:dt}</i>".format(item, item.lastLogin)
            : this.toString(item);
        selectedItem.subtext = (createdAt)
            ? "{0} <b>{1:d}</b>".format(item.email || item.phone, item.createdAt)
            : item.email ?? item.phone ?? "";
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;

        return selectedItem;
    }

    public toLanguageListItem(item: Language): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.code;
        selectedItem.text = item.label;
        selectedItem.completed = flags[item.code];
        return selectedItem;
    }

    public toCountryListItem(item: Country, codeAsValue: boolean = true): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = (codeAsValue) ? item.code : item.id;
        selectedItem.text = item.name!;
        selectedItem.subtext = item.nativeName!;
        selectedItem.completed = flags[item.code];
        selectedItem.lineThrough = (!item.active);
        return selectedItem;
    }

    public toUserRoleListItem(item: UserRole): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.roleName;
        selectedItem.text = Localizer.get(`RoleName.${item.roleName}`);
        return selectedItem;
    }

    public toServicePointListItem(item: ServicePoint): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.subtext = (item.areaAlias)
            ? item.areaAlias
            : (item.area && item.area.name)
                ? item.area.name
                : "";
        selectedItem.favorite = item.favorite;
        return selectedItem;
    }

    public toWaypointListItem(item: Waypoint): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.waypointToString(item);
        selectedItem.favorite = item.favorite;
        selectedItem.lineThrough = (item.source?.isActive == false) || (item.destination?.isActive == false);
        selectedItem.completed = true;
        return selectedItem;
    }

    public toAreaListItem(item: Area): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.areaToString(item);
        selectedItem.subtext = item.country?.name || "";
        return selectedItem;
    }

    public toServiceProviderListItem(item: ServiceProvider): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = LocalizationString.value(item.name);
        selectedItem.subtext = item.companyName ?? "";
        selectedItem.completed = item.logo;
        return selectedItem;
    }

    public toBoatListItem(item: Boat): SelectListItem {
        const primaryImage: BoatImage | null = item.images?.firstOrDefault(item => item.primary) || null;

        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = `${item.model} ${item.brand}`;
        selectedItem.subtext = Utility.formatValue(item.boatType, nameof(BoatType));
        selectedItem.completed = (primaryImage?.file) ? primaryImage.file : true;
        selectedItem.lineThrough = item.isDeleted;
        return selectedItem;
    }

    public toCruisePackagePointListItem(item: CruisePackagePoint, compact: boolean = true, moneySymbol?: string): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.locationToString(item.location, compact);
        if (!!moneySymbol) {
            selectedItem.subtext = "+{0:C} {1}".format(item.price, moneySymbol);
        }
        return selectedItem;
    }

    public toOrganizationListItem(item: Organization): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.vatId;
        selectedItem.subtext = item.vatId;
        selectedItem.favorite = item.favorite;
        return selectedItem;
    }

    public toOrganizationContractListItem(item: OrganizationContract): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.externalId;
        selectedItem.subtext = item.externalId;
        selectedItem.favorite = item.favorite;
        return selectedItem;
    }
}

export default new TransformProvider();
// autogenerated

export default class ManuallyCheckOutPassengerRequest {

    public bookingId: string = "";

    public shuttleInstanceLegId: string = "";

    public readonly isManuallyCheckOutPassengerRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ManuallyCheckOutPassengerRequest) || ((from as any).isManuallyCheckOutPassengerRequest === true));
    }

    public static as(from?: object | null): ManuallyCheckOutPassengerRequest | null {
        return (ManuallyCheckOutPassengerRequest.is(from)) ? from as ManuallyCheckOutPassengerRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated

export default class GetBookingReceiptPdfRequest {

    public bookingId: string = "";

    public asCaptain: boolean = false;

    public readonly isGetBookingReceiptPdfRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof GetBookingReceiptPdfRequest) || ((from as any).isGetBookingReceiptPdfRequest === true));
    }

    public static as(from?: object | null): GetBookingReceiptPdfRequest | null {
        return (GetBookingReceiptPdfRequest.is(from)) ? from as GetBookingReceiptPdfRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
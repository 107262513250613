// autogenerated

export default class SaveAreaRequest {

    public id: string | null = null;

    public countryId: string = "";

    public name: string | null = null;

    public cancelHours: number = 0;

    public cancelPrice: number = 0;

    public margin: number = 0;

    public readonly isSaveAreaRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveAreaRequest) || ((from as any).isSaveAreaRequest === true));
    }

    public static as(from?: object | null): SaveAreaRequest | null {
        return (SaveAreaRequest.is(from)) ? from as SaveAreaRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
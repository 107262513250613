// autogenerated
import FuelConsumption from "@/models/server/bout/FuelConsumption";

export default class CalculateFuelConsumptionRequest {

    public horsepower: number = 0;

    public fuelConsumptions: FuelConsumption[] | null = null;

    public readonly isCalculateFuelConsumptionRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CalculateFuelConsumptionRequest) || ((from as any).isCalculateFuelConsumptionRequest === true));
    }

    public static as(from?: object | null): CalculateFuelConsumptionRequest | null {
        return (CalculateFuelConsumptionRequest.is(from)) ? from as CalculateFuelConsumptionRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, GoogleMap, IGoogleMapMarker} from "@reapptor-apps/reapptor-react-components";
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

import styles from "./LocationCard.module.scss";

interface ILocationCardProps {
    id?: string;
    className?: string;
    markerIconUrl?: string;
    minimized?: boolean;
    coordinate: GeoCoordinate;
    title: string;
    subTitle: string;
    footer: string;
}

interface ILocationCardState {
}

export default class LocationCard extends BaseComponent<ILocationCardProps, ILocationCardState> {

    state: ILocationCardState = {
    };

    private readonly _mapRef: React.RefObject<GoogleMap> = React.createRef();

    public get coordinate(): GeoCoordinate {
        return this.props.coordinate;
    }

    private get marker(): IGoogleMapMarker {
        return AddressHelper.toMarker(this.coordinate, null, this.props.markerIconUrl);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public renderInfo(): React.ReactNode {
        return (
            <div>

                <h5>
                    {this.props.title}
                </h5>

                <span>
                    {this.props.subTitle}
                </span>

                <hr/>

                <span className={styles.footer}>
                     {this.props.footer}
                </span>

            </div>
        )
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.servicePointMap)}>

                {
                    (!this.props.minimized) &&
                    (
                        <GoogleMap autoCloseInfoWindows
                                   streetViewControl={false}
                                   className={this.props.className}
                                   ref={this._mapRef}
                                   height={240}
                                   zoom={12}
                                   center={this.coordinate}
                                   markers={() => [this.marker]}
                        />
                    )
                }
                
                {
                    this.renderInfo()
                }

            </div>
        )
    }
}

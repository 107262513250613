// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

export default class BoatImage {

    public id: string | null = null;

    public primary: boolean = false;

    public file: FileModel | null = null;

    public fileId: string = "";

    public readonly isBoatImage: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof BoatImage) || ((from as any).isBoatImage === true));
    }

    public static as(from?: object | null): BoatImage | null {
        return (BoatImage.is(from)) ? from as BoatImage : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {BoatType} from "@/models/Enums";

export default class SaveBoatPriceRequest {

    public id: string | null = null;

    public name: string | null = null;

    public areaId: string = "";

    public boatType: BoatType = BoatType.Small;

    public averageSpeed: number = 0;

    public minutePrice: number = 0;

    public basePrice: number = 0;

    public addonPrice: number | null = null;

    public minimumTime: number = 0;

    public prebookNotificationReminderLastMinutes: number = 0;

    public prebookNotificationReminderFirstHours: number = 0;

    public closestCaptainCount: number = 0;

    public priceIntervalLimit: number = 0;

    public lowerLimit: number = 0;

    public upperLimit: number = 0;

    public minimumPassengerCapacity: number = 0;

    public maximumPassengerCapacity: number = 0;

    public readonly isSaveBoatPriceRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveBoatPriceRequest) || ((from as any).isSaveBoatPriceRequest === true));
    }

    public static as(from?: object | null): SaveBoatPriceRequest | null {
        return (SaveBoatPriceRequest.is(from)) ? from as SaveBoatPriceRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
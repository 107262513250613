// autogenerated

export default class CreditCard {

    public id: string = "";

    public userId: string = "";

    public last4: number = 0;

    public expirationMonth: number = 0;

    public expirationYear: number = 0;

    public default: boolean = false;

    public fingerprint: string | null = null;

    public brand: string | null = null;

    public holderName: string | null = null;

    public isExpired: boolean = false;

    public readonly isCreditCard: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CreditCard) || ((from as any).isCreditCard === true));
    }

    public static as(from?: object | null): CreditCard | null {
        return (CreditCard.is(from)) ? from as CreditCard : null;
    }

    // #region Placeholder

    public static getNumber(creditCard: CreditCard): string {
        return "**** **** **** {0:0000}".format(creditCard.last4).trim();
    }

    public static getExpiration(creditCard: CreditCard): string {
        const year = (creditCard.expirationYear >= 2000)
            ? creditCard.expirationYear - 2000
            : creditCard.expirationYear - 1900;
        return  "{0:00}/{1:00}".format(creditCard.expirationMonth, year);
    }

    // #endregion
}
// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {BoatType} from "@/models/Enums";
import User from "@/models/server/User";
import Area from "@/models/server/bout/Area";
import BoatImage from "@/models/server/bout/BoatImage";
import BoatPrice from "@/models/server/bout/BoatPrice";
import AppConstants from "@/helpers/AppConstants";

export default class Boat {

    public id: string = "";

    public isSpeedBoat: boolean = false;

    public isElectricBoat: boolean = false;

    public isSailBoat: boolean = false;

    public captainId: string = "";

    public captain: User | null = null;

    public maxCapacity: number = 0;

    public seats: number = 0;

    public brand: string | null = null;

    public model: string = "";

    public horsepower: number = 0;

    public cruiseSpeed: number = 0;

    public petFriendly: boolean = false;

    public disabledFriendly: boolean = false;

    public toilet: boolean = false;

    public rating: number | null = null;

    public margin: number | null = null;

    public boatType: BoatType = BoatType.Small;

    public areaId: string | null = null;

    public area: Area | null = null;

    public images: BoatImage[] | null = null;

    public locationId: string = "";

    public homeLocation: GeoLocation | null = null;

    public boatPriceId: string | null = null;

    public boatPrice: BoatPrice | null = null;

    public isDeleted: boolean = false;

    public readonly isBoat: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Boat) || ((from as any).isBoat === true));
    }

    public static as(from?: object | null): Boat | null {
        return (Boat.is(from)) ? from as Boat : null;
    }

    // #region Placeholder

    public static initializeImages(boat: Boat, count: number = 4): void {
        boat.images = boat.images ?? [];
        while(boat.images.length < count) {
            const boatImage = new BoatImage();
            boatImage.fileId = AppConstants.defaultGuid;
            boat.images.push(boatImage);
        }
        boat.images.sortBy(item => !item.file && !item.primary);
        boat.images.map((item, index) => item.primary = (index == 0));
    }

    public static findPrimaryImage(boat: Boat | null): BoatImage | null {
        return ((boat != null) && (boat.images != null) && (boat.images.length > 0))
            ? boat.images.firstOrDefault(item => item.primary) ?? boat.images.first()
            : null;
    }

    // #endregion
}
import React from "react";
import {BaseComponent, IBaseComponentProps, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {Icon} from "@reapptor-apps/reapptor-react-components";
import UserNotificationMessage from "@/models/server/UserNotificationMessage";

import styles from "./NotificationItem.module.scss";

export interface INotificationItemProps extends IBaseComponentProps {
    message: UserNotificationMessage;
    onClick?(sender: NotificationItem, message: UserNotificationMessage): Promise<void>;
}

export default class NotificationItem extends BaseComponent<INotificationItemProps> {

    private async invokeOnClickAsync(): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(this, this.message);
        }
    }

    public get message(): UserNotificationMessage {
        return this.props.message;
    }

    public render(): React.ReactNode {
        const createdAt: Date = this.message.createdAt;
        const text: string | null = this.message.message;
        const subtext: string | null = this.message.text;
        
        const activeStyle: any = (!this.message.read) && styles.active;
        const pageRouteStyle: any = (!!this.message.pageRoute) && styles.pageRoute;
        
        return (
            <div id={this.id} className={this.css(this.props.className, styles.notificationItem)}>
                <div className={this.css(styles.context, activeStyle, pageRouteStyle)} onClick={() => this.invokeOnClickAsync()}>

                    <div className={styles.createdAt}>
                        
                        {
                            (!this.message.read) && 
                            (
                                <div className={styles.bellContainer}>
                                    <Icon className={styles.bell} 
                                      name={"fa-solid fa-bell"} 
                                    />
                                </div>
                            )
                        }
                        
                        <span>{createdAt.format("D")}</span>
                        <span>{createdAt.format("t")}</span>
                        
                    </div>

                    <span className={this.css(styles.message)}>
                        {ReactUtility.toMarks(text)}
                    </span>
                    
                    {
                        (subtext) &&
                        (
                            <span className={this.css(styles.message, styles.subtext)}>
                                {ReactUtility.toMarks(subtext)}
                            </span>
                        )
                    }

                </div>
            </div>
        )
    }
}
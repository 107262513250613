import React from "react";
import {BaseComponent, TextAlign} from "@reapptor-apps/reapptor-react-common";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";
import {
    DateInput,
    Dropdown,
    DropdownRequiredType,
    SelectListItem,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import {DateUtility, TimeSpan, Utility} from "@reapptor-apps/reapptor-toolkit";
import Booking from "@/models/server/bout/Booking";
import CruisePackageBooking from "@/models/server/cruise/CruisePackageBooking";
import {BookingType} from "@/models/Enums";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import AppConstants from "@/helpers/AppConstants";
import ShuttleEstimatedBooking from "@/models/server/shuttle/ShuttleEstimatedBooking";
import AppController, {RideBookingRules} from "@/pages/AppController";
import Localizer from "@/localization/Localizer";

import styles from "./DateInfo.module.scss";

export interface IDateInfoProps {
    readonly?: boolean;
    booking: EstimatedBooking | Booking | CruisePackageBooking;
    onChange: (sender: DateInfo) => Promise<void>;
}

interface IDateInfoState {
    rideBookingRules: RideBookingRules;
}

export default class DateInfo extends BaseComponent<IDateInfoProps, IDateInfoState> {

    state: IDateInfoState = {
        rideBookingRules: AppController.getRideBookingRules(),
    };

    private readonly _dateInputRef: React.RefObject<DateInput> = React.createRef();
    private readonly _timeInputRef: React.RefObject<DateInput> = React.createRef();

    private async openDateAsync(): Promise<void> {
        await this._dateInputRef.current?.openAsync();
    }

    private async openTimeAsync(): Promise<void> {
        await this._timeInputRef.current?.openAsync();
    }

    private async setDateAsync(value: Date): Promise<void> {
        if (this.item) {
            const date: Date = value.date();
            const time: TimeSpan = DateUtility.time(this.bookingTime);
            
            this.bookingTime = DateUtility.add(date, time);

            await this.reRenderAsync();

            await this.props.onChange(this);
        }
    }

    private async setTimeAsync(value: Date): Promise<void> {
        if (this.item) {
            const date: Date = this.bookingTime.date();
            const time: TimeSpan = DateUtility.time(value);
            
            this.bookingTime = DateUtility.add(date, time);

            await this.reRenderAsync();

            await this.props.onChange(this);
        }
    }

    private async setDurationAsync(value: number): Promise<void> {
        if (this.cruisePackageBooking) {
            this.cruisePackageBooking.durationInHours = value;

            await this.props.onChange(this);
        }
    }

    private get item(): EstimatedBooking | ShuttleEstimatedBooking | CruisePackageBooking | Booking {
        return this.props.booking;
    }

    private get booking(): Booking | null {
        return Booking.as(this.item);
    }

    private get isBooking(): boolean {
        return Booking.is(this.item);
    }

    private get isRide(): boolean {
        return (
            (this.booking?.bookingType == BookingType.Ride) ||
            ((EstimatedBooking.is(this.item) && (!ShuttleEstimatedBooking.is(this.item))))
        );
    }

    private get rideEstimatedBooking(): EstimatedBooking | null {
        return EstimatedBooking.as(this.item);
    }

    private get cruisePackageBooking(): CruisePackageBooking | null {
        return CruisePackageBooking.as(this.item);
    }

    private get cruisePackage(): CruisePackage | null {
        return this.cruisePackageBooking?.cruisePackage ?? null;
    }
    
    private get bookingTime(): Date {
        return this.item.bookingTime;
    }

    private set bookingTime(value: Date) {
        this.item.bookingTime = value;
    }

    private get useRequestBookingTime(): boolean {
        return (this.rideEstimatedBooking != null);
    }
    
    private get countryLocalBookingTime(): Date {
        return Booking.getCountryLocalTime(this.item, this.item.bookingTime);
    }

    private durationToSelectListItem(count: number): SelectListItem {
        const text: string = Localizer.mobileDateInfoBookingTimeHours.format(count);
        return new SelectListItem(count.toString(), text);
    }

    private get durationItems(): SelectListItem[] {
        const minDurationInHours: number = this.cruisePackageBooking?.cruisePackage?.minDurationInHours ?? 1;
        const length: number = (AppConstants.maxTripDurationInHours - minDurationInHours) * 2 + 1;
        
        return Array.from({length}, (_, index) => this.durationToSelectListItem(minDurationInHours + index / 2));
    }

    private get supportsDuration(): boolean {
        return (this.cruisePackageBooking != null) || (this.booking?.bookingType == BookingType.CruisePackage);
    }

    private get durationInHours(): number {
        return (this.cruisePackageBooking?.durationInHours) ?? ((this.booking?.netTimeInMinutes ?? 30) / 60);
    }

    private get rideBookingRules(): RideBookingRules {
        return this.state.rideBookingRules;
    }

    private get minDate(): Date | null {
        return ((this.cruisePackage) && (this.cruisePackage.hasSeason) && (!this.cruisePackage.isInSeason))
            ? CruisePackage.nextSeasonStartsAt(this.cruisePackage)
            : (this.isRide)
                ? this.rideBookingRules.getMinDate()
                : Utility.today();
    }

    private get maxDate(): Date | null {
        return ((this.cruisePackage) && (this.cruisePackage.hasSeason) && (this.cruisePackage.isInSeason))
            ? this.cruisePackage.seasonEndsAt
            : (this.isRide)
                ? this.rideBookingRules.getMaxDate()
                : null
    }
    
    private get minTime(): Date {
        const bookingDate: Date = this.bookingTime.date();
        return (this.isRide)
            ? this.rideBookingRules.getMinTime(this.bookingTime)
            : (bookingDate.inFuture(true))
                ? bookingDate
                : Utility.now();
    }

    private get maxTime(): Date {
        if (this.isRide) {
            return this.rideBookingRules.getMaxTime(this.bookingTime);
        }
        const date: Date = Utility.today();
        date.setHours(23, 59, 59, 999);
        return date;
    }
    
    private get readonly(): boolean {
        return ((this.props.readonly === true) || (this.isBooking));
    }

    private get editable(): boolean {
        return (!this.readonly);
    }

    public render(): React.ReactNode {
        const bookingTime: Date = (this.useRequestBookingTime)
            ? this.bookingTime
            : this.countryLocalBookingTime;
        const date: string = "{0:dddd}, {1:D}".format(bookingTime, bookingTime);
        const time: string = Localizer.mobileDateInfoBookingTime.format(bookingTime);
        
        return (
            <div className={styles.dateInfo}>

                <span>{Localizer.mobileDateInfoBookingDateAndTimeSpan}</span>
                
                <div className={styles.dateInput}>

                    <DateInput id={"dateInput"}
                               ref={this._dateInputRef}
                               className={this.css(styles.input)}
                               title={Localizer.mobileDateInfoBookingDateTitle}
                               minDate={this.minDate}
                               maxDate={this.maxDate}
                               value={bookingTime}
                               //value={countryLocalBookingTime}
                               readonly={this.readonly}
                               onChange={(value: Date) => this.setDateAsync(value)}
                    />

                    <TextInput readonly
                               id={"dateInputLabel"}
                               className={this.css(styles.label, this.readonly && styles.readonly)}
                               title={Localizer.mobileDateInfoBookingDateTitle}
                               value={date}
                               onClick={() => this.openDateAsync()}
                    />

                </div>

                <div className={styles.timeInput}>

                    <DateInput showTime showOnlyTime
                               id={"timeInput"}
                               ref={this._timeInputRef}
                               className={styles.input}
                               title={Localizer.mobileDateInfoBookingTimeTitle}
                               timeIntervals={15}
                               readonly={this.readonly}
                               minDate={Utility.now()}
                               minTime={this.minTime}
                               maxTime={this.maxTime}
                               value={bookingTime}
                               //value={countryLocalBookingTime}
                               onChange={(value: Date) => this.setTimeAsync(value)}
                    />

                    <TextInput readonly
                               id={"timeInputLabel"}
                               className={this.css(styles.label, this.readonly && styles.readonly)}
                               title={Localizer.mobileDateInfoBookingTimeTitle}
                               value={time}
                               onClick={() => this.openTimeAsync()}
                    />

                </div>

                {
                    (this.supportsDuration) &&
                    (
                        (this.editable)
                            ?
                            (
                                <Dropdown id={"duration"} noFilter required
                                          requiredType={DropdownRequiredType.Restricted}
                                          disabled={this.readonly}
                                          textAlign={TextAlign.Center}
                                          className={styles.duration}
                                          items={this.durationItems}
                                          selectedItem={this.durationInHours}
                                          onChange={(_, item) => this.setDurationAsync(parseFloat(item!.value))}
                                />
                            )
                            :
                            (
                                <TextInput id={"duration"} readonly
                                           className={styles.duration}
                                           value={Localizer.mobileDateInfoBookingTimeHours.format(this.durationInHours)}
                                />
                            )
                    )
                }

            </div>
        );
    }
}
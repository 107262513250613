// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";
import {LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {CruisePackageOwnerType} from "@/models/Enums";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import CruisePackagePrice from "@/models/server/cruise/CruisePackagePrice";

export default class SaveCruisePackageRequest {

    public id: string | null = null;

    public ownerType: CruisePackageOwnerType = CruisePackageOwnerType.ServiceProvider;

    public serviceProviderId: string | null = null;

    public areaId: string = "";

    public boatId: string = "";

    public name: LocalizationString | null = null;

    public details: LocalizationString | null = null;

    public active: boolean = false;

    public maxCapacity: number = 0;

    public seasonStartsAt: Date | null = null;

    public seasonEndsAt: Date | null = null;

    public minDurationInHours: number = 0;

    public customLocation: boolean = false;

    public customLocationPrice: number | null = null;

    public food: boolean = false;

    public drinks: boolean = false;

    public images: FileModel[] | null = null;

    public points: CruisePackagePoint[] | null = null;

    public prices: CruisePackagePrice[] | null = null;

    public readonly isSaveCruisePackageRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveCruisePackageRequest) || ((from as any).isSaveCruisePackageRequest === true));
    }

    public static as(from?: object | null): SaveCruisePackageRequest | null {
        return (SaveCruisePackageRequest.is(from)) ? from as SaveCruisePackageRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";

export default class CompleteCheckInRequest {

    public shuttleInstanceLegId: string = "";

    public location: GeoCoordinate | null = null;

    public readonly isCompleteCheckInRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CompleteCheckInRequest) || ((from as any).isCompleteCheckInRequest === true));
    }

    public static as(from?: object | null): CompleteCheckInRequest | null {
        return (CompleteCheckInRequest.is(from)) ? from as CompleteCheckInRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
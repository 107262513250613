// autogenerated

export default class UserPolicyAcceptance {

    public userId: string | null = null;

    public serviceProviderId: string | null = null;

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public cancellationPolicyAccepted: boolean = false;

    public adult: boolean = false;

    public acceptedAt: Date | null = null;

    public readonly isUserPolicyAcceptance: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof UserPolicyAcceptance) || ((from as any).isUserPolicyAcceptance === true));
    }

    public static as(from?: object | null): UserPolicyAcceptance | null {
        return (UserPolicyAcceptance.is(from)) ? from as UserPolicyAcceptance : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {AccountHolderType} from "@/models/Enums";

export default class AddMyBankAccountRequest {

    public accountHolderName: string | null = null;

    public accountHolderType: AccountHolderType = AccountHolderType.Individual;

    public iban: string | null = null;

    public default: boolean = false;

    public readonly isAddMyBankAccountRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof AddMyBankAccountRequest) || ((from as any).isAddMyBankAccountRequest === true));
    }

    public static as(from?: object | null): AddMyBankAccountRequest | null {
        return (AddMyBankAccountRequest.is(from)) ? from as AddMyBankAccountRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
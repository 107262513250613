// autogenerated

export default class GetUsersRequest {

    public showDeleted: boolean = false;

    public showLockedOnly: boolean = false;

    public showExpired: boolean = false;

    public acceptedRegistration: boolean = false;

    public validCreditCard: boolean = false;

    public hasBoats: boolean = false;

    public roleNames: string[] | null = null;

    public orderByCreatedAt: boolean = false;

    public includeDetails: boolean = false;

    public serviceProviderId: string | null = null;

    public readonly isGetUsersRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof GetUsersRequest) || ((from as any).isGetUsersRequest === true));
    }

    public static as(from?: object | null): GetUsersRequest | null {
        return (GetUsersRequest.is(from)) ? from as GetUsersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
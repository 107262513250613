// autogenerated
import {GeoLocation, GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import ServicePoint from "@/models/server/bout/ServicePoint";
import IntermediateWaypoint from "@/models/server/bout/IntermediateWaypoint";
import WaypointPrice from "@/models/server/bout/WaypointPrice";

export default class Waypoint {

    public id: string = "";

    public source: ServicePoint | null = null;

    public sourceId: string = "";

    public destination: ServicePoint | null = null;

    public destinationId: string = "";

    public createdAt: Date = new Date();

    public intermediateWaypoints: IntermediateWaypoint[] | null = null;

    public fixedPrices: WaypointPrice[] | null = null;

    public favorite: boolean = false;

    public readonly isWaypoint: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Waypoint) || ((from as any).isWaypoint === true));
    }

    public static as(from?: object | null): Waypoint | null {
        return (Waypoint.is(from)) ? from as Waypoint : null;
    }

    // #region Placeholder
    
    public static getRoute(waypoint: Waypoint | null): GeoCoordinate[] {
        if ((waypoint != null) && (waypoint.source?.location != null) && (waypoint.destination?.location != null)) {
            const sourceLocation: GeoLocation = waypoint.source.location;
            const destinationLocation: GeoLocation = waypoint.destination.location;
            const intermediateWaypoints: IntermediateWaypoint[] = waypoint.intermediateWaypoints ?? [];
            return [sourceLocation, ...intermediateWaypoints, destinationLocation];
        }
        return [];
    }

    public static generateGoogleMapsLink(waypoint: Waypoint | null | undefined): string | null {
        return ServicePoint.generateGoogleMapsLink(waypoint?.source);
    }

    // #endregion
}
import React from "react";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {
    Button,
    ButtonType, Icon,
    IconSize,
    PageContainer,
    PageRow,
    QrWidget,
    QrWidgetHighResolutionType,
    QrWidgetType
} from "@reapptor-apps/reapptor-react-components";
import GetOngoingShuttlesResponse from "@/models/server/responses/GetOngoingShuttlesResponse";
import GetOngoingShuttlesRequest from "@/models/server/requests/GetOngoingShuttlesRequest";
import ShuttleLegInstance from "@/models/server/shuttle/ShuttleLegInstance";
import ScanTicketResponse from "@/models/server/responses/ScanTicketResponse";
import ScanTicketRequest from "@/models/server/requests/ScanTicketRequest";
import CompleteCheckInRequest from "@/models/server/requests/CompleteCheckInRequest";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ch, IManualProps, Justify, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import AppConstants from "@/helpers/AppConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import ServiceProvider from "@/models/server/ServiceProvider";
import Localizer from "@/localization/Localizer";

import boutStyles from "../../../bout.module.scss";
import styles from "./Inspector.module.scss";

const RepeatedQrCodeDelay: number = 3;

interface IInspectorProps {
}

interface IInspectorState {
    checkIn: boolean;
    closestShuttleLegInstance: ShuttleLegInstance | null;
    shuttleLegsInstances: ShuttleLegInstance[];
    selectedShuttleLegInstance: ShuttleLegInstance | null;
    currentIndex: number;
    lastQrCode: string | null;
    lastQrCodeAt: Date | null;
    scanQrEvents: ScanTicketResponse[];
    scanning: boolean;
    success: boolean | null;
    failed: boolean | null;
    warning: boolean | null;
    scans: number;
}

export default class Inspector extends AuthorizedPage<IInspectorProps, IInspectorState> {

    state: IInspectorState = {
        checkIn: true,
        closestShuttleLegInstance: null,
        shuttleLegsInstances: [],
        selectedShuttleLegInstance: null,
        currentIndex: 0,
        lastQrCode: null,
        lastQrCodeAt: null,
        scanQrEvents: [],
        scanning: false,
        success: null,
        failed: null,
        warning: null,
        scans: 0,
    };

    private readonly _qrWidget: React.RefObject<QrWidget> = React.createRef();

    private get currentIndex(): number {
        return this.state.currentIndex;
    }

    private get selectedShuttleLegInstance(): ShuttleLegInstance | null {
        return this.state.selectedShuttleLegInstance;
    }

    private get shuttleLegsInstances(): ShuttleLegInstance[] {
        return this.state.shuttleLegsInstances;
    }

    private async clearStatuses(): Promise<void> {
        await this.setState({success: null, failed: null, warning: null});
    }

    private async onQrAsync(code: string): Promise<void> {
        if (this.selectedShuttleLegInstance) {
            
            await this.setState({ scans: this.state.scans + 1 });

            if ((this.state.lastQrCode == code) &&
                (this.state.lastQrCodeAt) &&
                (Utility.diff(Utility.now(), this.state.lastQrCodeAt).totalSeconds < RepeatedQrCodeDelay)
            ) {
                return;
            }

            this.state.lastQrCode = code;
            this.state.lastQrCodeAt = Utility.now();

            const request = new ScanTicketRequest();
            request.checkIn = this.state.checkIn;
            request.qrCode = code;
            request.shuttleInstanceLegId = this.selectedShuttleLegInstance.id;

            await this.setState({scanning: true, success: null, failed: null, warning: null});

            const response: ScanTicketResponse = await this.postAsync("/api/mobileApp/scanTicket", request);

            await this.setState({scanning: false});

            this.state.success = response.success;
            this.state.failed = response.failed;
            this.state.warning = response.repeated;
            
            if (
                (this.state.scanQrEvents.length == 0) || 
                (this.state.scanQrEvents[0].booking?.id != response.booking?.id) ||
                (this.state.scanQrEvents[0].repeated != response.repeated)) {
                this.state.scanQrEvents.insert(response);
            }
            
            const shuttleLegInstance: ShuttleLegInstance | null = response.shuttleLegInstance;

            if (shuttleLegInstance) {
                this.state.shuttleLegsInstances[this.currentIndex] = shuttleLegInstance;
                this.state.selectedShuttleLegInstance = shuttleLegInstance;
            }

            setTimeout(async () => this.clearStatuses(), 1000);

            await this.reRenderAsync();
        }
    }

    private async onCompleteCheckInAsync(): Promise<void> {
        if (this.selectedShuttleLegInstance) {

            const confirmed: boolean = await ch.confirmAsync(Localizer.inspectorPageAlertMessageCompleteCheckIn);

            if (confirmed) {
                const request = new CompleteCheckInRequest();
                request.shuttleInstanceLegId = this.selectedShuttleLegInstance.id;

                await this.postAsync("/api/mobileApp/completeCheckIn", request);

                await ch.alertMessageAsync(Localizer.inspectorPageAlertMessageCheckInCompleted, true, true);
            }
        }
    }

    private async onNextAsync(): Promise<void> {
        if (this.canNext) {

            const nextIndex: number = this.currentIndex + 1;

            const nextLegInstance: ShuttleLegInstance = this.shuttleLegsInstances[nextIndex];

            await this.setState({currentIndex: nextIndex, selectedShuttleLegInstance: nextLegInstance, checkIn: true});
        }
    }

    private get canNext(): boolean {
        return (this.currentIndex < this.state.shuttleLegsInstances.length - 1);
    }

    private async onPrevAsync(): Promise<void> {
        if (this.canPrev) {
            const currentIndex: number = this.currentIndex - 1;

            const selectedShuttleLegInstance: ShuttleLegInstance = this.shuttleLegsInstances[currentIndex];

            await this.setState({currentIndex, selectedShuttleLegInstance: selectedShuttleLegInstance, checkIn: true});
        }
    }

    private get canPrev(): boolean {
        return (this.currentIndex > 0);
    }

    private async setCheckAsync(checkIn: boolean): Promise<void> {
        await this.setState({checkIn: checkIn});
    }
    
    public getTitle(): string {
        return Localizer.inspectorPageTitle;
    }

    private async redirectToDetailsPageAsync(selectedShuttleLegInstance: string | null): Promise<void> {
        await PageRouteProvider.redirectAsync(PageDefinitions.passengersOnBoard(selectedShuttleLegInstance));
    }

    public getManualProps(): IManualProps {
        const serviceProvider: ServiceProvider | null = this.serviceProviderSlug;
        const isHds: boolean = (serviceProvider?.slug == AppConstants.hdsSlug);
        if (isHds) {
            return {
                icon: "far fa-users",
                onClick: async () => this.redirectToDetailsPageAsync(this.state.selectedShuttleLegInstance?.id || null)
            };
        }

        return {};
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const request = new GetOngoingShuttlesRequest();

        const response: GetOngoingShuttlesResponse = await this.postAsync("/api/mobileApp/getOngoingShuttles", request);

        const shuttleLegsInstances: ShuttleLegInstance[] = response.items;

        this.state.shuttleLegsInstances = shuttleLegsInstances;

        if (shuttleLegsInstances.length > 0) {
            this.state.selectedShuttleLegInstance = shuttleLegsInstances[this.currentIndex];
        }

        this.state.closestShuttleLegInstance = this.findClosestShuttleLegInstance(shuttleLegsInstances);
        
        await this.setState(this.state);

        await this._qrWidget.current?.showContentAsync();
    }

    private findClosestShuttleLegInstance(shuttleLegsInstances: ShuttleLegInstance[]): ShuttleLegInstance {
        const currentDate: Date = Utility.now();
        
        const ongoingShuttle: ShuttleLegInstance | null = shuttleLegsInstances.firstOrDefault(item => (item.departureAt <= currentDate) && (item.arrivalAt >= currentDate));
        
        if (ongoingShuttle) {
            return ongoingShuttle;
        }

        const currentTime: number = currentDate.getTime();

        let closestDeparture: ShuttleLegInstance = shuttleLegsInstances[0];
        let closestTimeDifference: number = Math.abs(closestDeparture.departureAt.getTime() - currentTime);

        for (const departure of shuttleLegsInstances) {
            const timeDifference: number = Math.abs(departure.departureAt.getTime() - currentTime);

            if (timeDifference < closestTimeDifference) {
                closestTimeDifference = timeDifference;
                closestDeparture = departure;
            }
        }
        
        return closestDeparture;
    }
    
    private renderLegs(leg: ShuttleLegInstance): React.ReactNode {

        const from: string = leg.shuttleLeg?.waypoint?.source?.name || "?";
        const to: string = leg.shuttleLeg?.waypoint?.destination?.name || "?";
        const date: string = leg.departureAt.format("d");
        const departureAt: string = leg.departureAt.format("t");
        const arrivalAt: string = leg.arrivalAt.format("t");
        const checkedIn: number = leg.checkedIn;
        const checkedOut: number = leg.checkedOut;
        const sold: number = leg.sold;
        const capacity: number = leg.capacity;
        const green: boolean = (this.state.checkIn)
            ? (sold > 0) && (checkedIn >= sold)
            : (sold > 0) && (checkedOut >= sold);

        const isOngoingShuttle: boolean = (this.state.closestShuttleLegInstance != null) && (leg.id == this.state.closestShuttleLegInstance.id);
        const inPast: boolean = (!isOngoingShuttle) && (leg.arrivalAt <= Utility.now());

        return (
            <div className={styles.legsInfo}>

                <Button className={styles.left}
                        icon={{name: "far fa-chevron-left"}}
                        type={ButtonType.Primary}
                        disabled={!this.canPrev}
                        iconPosition={Justify.Right}
                        onClick={() => this.onPrevAsync()}
                />

                <div className={this.css(styles.info, inPast && styles.inPast, isOngoingShuttle && styles.ongoingShuttle)}>

                    <div className={styles.row1}>
                        <span>{from}</span>
                        <span>{to}</span>
                    </div>

                    <div className={this.css(styles.row2)}>
                        <span>{departureAt}</span>
                        <span>{date}</span>
                        <span>{arrivalAt}</span>
                    </div>

                    <div className={styles.row3}>
                        <div>
                            <span>{Localizer.inspectorPageShuttleRideExpected}</span>
                            <span className={this.css((sold > 0) && styles.blue)}>{sold}</span>
                        </div>
                        <div>
                            <span>
                            {
                                (this.state.checkIn)
                                    ? Localizer.inspectorPageShuttleRideCheckedIn 
                                    : Localizer.inspectorPageShuttleRideCheckedOut
                            }
                            </span>
                            <span className={this.css(green && styles.green)}>{this.state.checkIn ? checkedIn : checkedOut}</span>
                        </div>
                        <div>
                            <span>{Localizer.inspectorPageShuttleRideCapacity}</span>
                            <span className={styles.blue}>{capacity}</span>
                        </div>
                    </div>
                    
                </div>

                <Button className={styles.right}
                        icon={{name: "far fa-chevron-right"}}
                        type={ButtonType.Primary}
                        iconPosition={Justify.Right}
                        disabled={!this.canNext}
                        onClick={() => this.onNextAsync()}
                />

            </div>
        );
    }

    public renderCheckButtons(): React.ReactNode {
        return (
            <>
                <div className={this.css(styles.checkButtons)}>
                    <div className={this.css(styles.checkIn, !this.state.checkIn ? styles.notActive : "")}
                         onClick={() => this.setCheckAsync(true)}
                    >
                        <p>{Localizer.inspectorPageButtonCheckIn}</p>
                    </div>

                    <div className={this.css(styles.checkOut, this.state.checkIn ? styles.notActive : "")}
                         onClick={() => this.setCheckAsync(false)}
                    >
                        <p>{Localizer.inspectorPageButtonCheckOut}</p>
                    </div>
                </div>

                {/*<div className={this.css(styles.completeCheckInRow)}>*/}
                {/*    <div className={styles.completeCheckIn}*/}
                {/*         onClick={() => this.onCompleteCheckInAsync()}*/}
                {/*    >*/}
                {/*        <p>{Localizer.inspectorPageButtonCompleteCheckIn}</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }

    public renderScanEventStatus(item: ScanTicketResponse): React.ReactNode {
        if (item.repeated) {
            return <Icon className={styles.warning} name={("fas fa-check")} size={IconSize.X2}/>;
        } 
        
        if (item.success) {
            return <Icon className={styles.success} name={("fas fa-check")} size={IconSize.X2}/>;
        }

        if (item.notFound) {
            return <Icon className={styles.failed} name={("fas fa-exclamation-circle")} size={IconSize.X2}/>;
        }

        if (item.failed) {
            return <Icon className={this.css(styles.failed)} name={("fas fa-exclamation-circle")} size={IconSize.X2}/>;
        }

        return <Icon className={this.css(styles.notFound)} name={("fas fa-exclamation-circle")} size={IconSize.X2}/>;
    }

    public renderScanEvents(): React.ReactNode {
        const hasEvents: boolean = (this.state.scanQrEvents.length > 0);

        return (
            <div className={styles.eventContainer}>
                <span className={styles.caption}>
                    {Localizer.inspectorPageEvents}
                    {
                        (ch.isDevelopmentVS) &&
                        (
                            <small>{this.state.scans}</small>
                        )
                    }
                </span>

                <div className={styles.events}>
                    {
                        (hasEvents)
                            ?
                            <table>

                                <thead>

                                <tr>
                                    <th>{Localizer.inspectorPageStatus}</th>
                                    <th>{Localizer.inspectorPageCode}</th>
                                    <th>{Localizer.inspectorPagePassengers}</th>
                                </tr>

                                </thead>

                                <tbody className={styles.item}>
                                
                                {
                                    this.state.scanQrEvents.map((item: ScanTicketResponse, index: number) =>

                                        <tr key={index}>

                                            <td>{this.renderScanEventStatus(item)}</td>

                                            <td>{item.booking?.ticketCode ?? Localizer.inspectorPageNotFound}</td>

                                            <td>{item.booking?.passengers ?? "-"}</td>

                                        </tr>
                                        
                                    )
                                }
                                </tbody>

                            </table>
                            : <p>{Localizer.inspectorPageNoEvents}</p>
                    }

                </div>

            </div>
        );
    }

    public renderQr(): React.ReactNode {
        return (
            <div className={styles.qrContainer}>

                <QrWidget wide stretchContent noAutoCollapse
                          ref={this._qrWidget}
                          id={"qr"}
                          className={styles.qrWidget}
                          highResolution={QrWidgetHighResolutionType.FullHD}
                          type={QrWidgetType.QrCode}
                          zoom={2}
                          //viewFinder={40}
                          onQr={(_, code: string) => this.onQrAsync(code)}
                />

            </div>
        );
    }

    public render(): React.ReactNode {
        const scanningStyle = (this.state.scanning) && styles.scanning;
        const warningStyle = (this.state.warning) && styles.warning;
        const successStyle = (this.state.success && !this.state.warning) && styles.success;
        const failedStyle = (this.state.failed) && styles.failed;
        const emptyStyle = ((!this.state.scanning) && (!this.state.success) && (!this.state.failed) && (!this.state.success) && (!this.state.warning)) && styles.empty;

        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(boutStyles.pageContainer, styles.inspector, this.mobile && styles.mobile, scanningStyle, successStyle, failedStyle, warningStyle, emptyStyle)}
                           alertClassName={boutStyles.alert}
            >

                <PageRow>

                    {
                        (this.selectedShuttleLegInstance)
                            ?
                            (
                                <div className={styles.context}>

                                    {this.renderLegs(this.selectedShuttleLegInstance)}

                                    {this.renderCheckButtons()}

                                    {this.renderQr()}

                                    {this.renderScanEvents()}

                                </div>
                            )
                            :
                            (
                                <p>{Localizer.inspectorPageNoRides}</p>
                            )
                    }


                </PageRow>

            </PageContainer>
        );
    }
}
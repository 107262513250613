// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListCountriesRequest extends ETagRequest {

    public serviceProviderId: string | null = null;

    public activeOnly: boolean = false;

    public asAdmin: boolean = false;

    public asCaptain: boolean = false;

    public withCruisePackagesOnly: boolean = false;

    public readonly isListCountriesRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListCountriesRequest) || ((from as any).isListCountriesRequest === true));
    }

    public static as(from?: object | null): ListCountriesRequest | null {
        return (ListCountriesRequest.is(from)) ? from as ListCountriesRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {TicketType} from "@/models/Enums";
import ShuttleLeg from "@/models/server/shuttle/ShuttleLeg";
import ShuttleTicket from "@/models/server/shuttle/ShuttleTicket";

export default class ShuttleLegInstance {

    public id: string = "";

    public shuttleLegId: string = "";

    public shuttleLeg: ShuttleLeg | null = null;

    public departureAt: Date = new Date();

    public arrivalAt: Date = new Date();

    public capacity: number = 0;

    public sold: number = 0;

    public available: number = 0;

    public expectedCheckedIn: number = 0;

    public checkedIn: number = 0;

    public expectedCheckedOut: number = 0;

    public checkedOut: number = 0;

    public tickets: ShuttleTicket[] | null = null;

    public readonly isShuttleLegInstance: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ShuttleLegInstance) || ((from as any).isShuttleLegInstance === true));
    }

    public static as(from?: object | null): ShuttleLegInstance | null {
        return (ShuttleLegInstance.is(from)) ? from as ShuttleLegInstance : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)
    
    public static getTickets(instance: ShuttleLegInstance, type: TicketType): number {
        return instance?.tickets?.firstOrDefault(item => item.type == type)?.amount ?? 0;
    }

    // #endregion
}
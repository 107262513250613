// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import Area from "@/models/server/bout/Area";

export default class ServicePoint {

    public id: string = "";

    public name: string = "";

    public location: GeoLocation | null = null;

    public area: Area | null = null;

    public areaAlias: string | null = null;

    public createdAt: Date = new Date();

    public isActive: boolean = false;

    public favorite: boolean = false;

    public readonly isServicePoint: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ServicePoint) || ((from as any).isServicePoint === true));
    }

    public static as(from?: object | null): ServicePoint | null {
        return (ServicePoint.is(from)) ? from as ServicePoint : null;
    }

    // #region Placeholder

    public static generateGoogleMapsLink(servicePoint: ServicePoint | GeoLocation | null | undefined): string | null {
        const location: GeoLocation | null = (servicePoint as ServicePoint)?.location ?? (servicePoint as GeoLocation);
        if (location) {
            const baseUrl: string = "https://www.google.com/maps/dir/?api=1";
            const destinationParam: string = `destination=${encodeURIComponent(location.lat)}, ${encodeURIComponent(location.lon)}`;
            return `${baseUrl}&${destinationParam}`;
        }
        return null;
    }

    // #endregion
}
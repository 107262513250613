// autogenerated
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListActiveShuttleServicePointsRequest extends ETagRequest {

    public serviceProviderId: string | null = null;

    public readonly isListActiveShuttleServicePointsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListActiveShuttleServicePointsRequest) || ((from as any).isListActiveShuttleServicePointsRequest === true));
    }

    public static as(from?: object | null): ListActiveShuttleServicePointsRequest | null {
        return (ListActiveShuttleServicePointsRequest.is(from)) ? from as ListActiveShuttleServicePointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
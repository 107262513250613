// autogenerated

export default class UpdateUserStripePaymentMethodRequest {

    public userId: string = "";

    public fingerPrint: string | null = null;

    public readonly isUpdateUserStripePaymentMethodRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof UpdateUserStripePaymentMethodRequest) || ((from as any).isUpdateUserStripePaymentMethodRequest === true));
    }

    public static as(from?: object | null): UpdateUserStripePaymentMethodRequest | null {
        return (UpdateUserStripePaymentMethodRequest.is(from)) ? from as UpdateUserStripePaymentMethodRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
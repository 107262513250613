// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {BookingStatus} from "@/models/Enums";

export default class ListBookingsRequest {

    public bookingStatus: BookingStatus | null = null;

    public serviceProviderId: string | null = null;

    public areaId: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public readonly isListBookingsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListBookingsRequest) || ((from as any).isListBookingsRequest === true));
    }

    public static as(from?: object | null): ListBookingsRequest | null {
        return (ListBookingsRequest.is(from)) ? from as ListBookingsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
import React from "react";
import CruisePackage from "@/models/server/cruise/CruisePackage";
import CruisePackageCard from "@/components/CruisePackageCard/CruisePackageCard";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";

import styles from "./CruisePackagesPanel.module.scss";

export interface ICruisePackagesPanelProps {
    cruisePackages: CruisePackage[]
    onSelectOrderPackage?(sender: CruisePackagesPanel, cruisePackage: CruisePackage): Promise<void>;
    onSelectRequestTrip?(sender: CruisePackagesPanel, cruisePackage: CruisePackage): Promise<void>;
    onSelectSignIn?(sender: CruisePackagesPanel): Promise<void>;
    onSelectSignUp?(sender: CruisePackagesPanel): Promise<void>;
}

interface ICruisePackagesPanelState {
}

export default class CruisePackagesPanel extends BaseComponent<ICruisePackagesPanelProps, ICruisePackagesPanelState> {
    
    state: ICruisePackagesPanelState = {
    };

    protected get cruisePackages(): CruisePackage[] {
        return this.props.cruisePackages;
    }

    public async onSelectOrderPackageAsync(cruisePackage: CruisePackage): Promise<void> {
        if (this.props.onSelectOrderPackage) {
            await this.props.onSelectOrderPackage(this, cruisePackage);
        }
    }

    public async onSelectRequestTripAsync(cruisePackage: CruisePackage): Promise<void> {
        if (this.props.onSelectRequestTrip) {
            await this.props.onSelectRequestTrip(this, cruisePackage);
        }
    }

    public async onSelectSignInAsync(): Promise<void> {
        if (this.props.onSelectSignIn) {
            await this.props.onSelectSignIn(this);
        }
    }

    public async onSelectSignUpAsync(): Promise<void> {
        if (this.props.onSelectSignUp) {
            await this.props.onSelectSignUp(this);
        }
    }

    public renderPackage(cruisePackage: CruisePackage): React.ReactNode {
        return (
            <CruisePackageCard key={cruisePackage.id}
                               hasOrderButton
                               cruisePackage={cruisePackage}
                               className={styles.cruisePackageCard}
                               onOrder={(_, cruisePackage) => this.onSelectOrderPackageAsync(cruisePackage)}
                               onRequestTrip={(_, cruisePackage) => this.onSelectRequestTripAsync(cruisePackage)}
                               onSignIn={(_) => this.onSelectSignInAsync()}
                               onSignUp={(_) => this.onSelectSignUpAsync()}
            />
        );
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.cruisePackagesPanel, this.desktop && styles.desktop)}>
                <div className={this.css(styles.content, (this.mobile) && styles.mobile)}>
                    <div className={styles.container}>
                        {
                            this.cruisePackages.map((cruisePackage) => this.renderPackage(cruisePackage))
                        }
                    </div>
                </div>
            </div>
        );
    }
}
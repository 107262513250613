import React from "react";
import {
    BaseRegexValidatorErrorMessage,
    Button,
    ButtonContainer,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownRequiredType,
    EmailInput,
    Form,
    IButtonProps,
    Icon,
    IconSize,
    Inline,
    InlineType,
    JustifyContent,
    Modal,
    ModalSize,
    PhoneInput,
    TextInput
} from "@reapptor-apps/reapptor-react-components";
import {BaseComponent, ch, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";
import User from "@/models/server/User";
import Country from "@/models/server/bout/Country";
import ProfileModal from "@/pages/Mobile/Profile/ProfileModal/ProfileModal";
import ImageProvider from "@/providers/ImageProvider";
import ValidatePhoneNumberRequest from "@/models/server/requests/ValidatePhoneNumberRequest";
import ValidatePhoneNumberResponse from "@/models/server/responses/ValidatePhoneNumberResponse";
import GeoCoordinate from "@reapptor-apps/reapptor-toolkit/src/models/GeoCoordinate";
import ServiceProviderPolicy from "@/models/server/bout/ServiceProviderPolicy";
import {UserPolicyType} from "@/models/Enums";
import Localizer from "../../../localization/Localizer";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";

import styles from "./PersonalInfo.module.scss";

import thumbnail from "@/img/thumbnail.png";

interface IPersonalInfoProps {
    user: User;
    asCaptain: boolean;
    nextButton?: IButtonProps;
    prevButton?: IButtonProps;
    onNext: (sender: PersonalInfo) => Promise<void>;
    onPrev: (sender: PersonalInfo) => Promise<void>;
}

interface IPersonalInfoState {
    countries: Country[];
    policies: ServiceProviderPolicy[];
    isValidPhoneNumber: boolean;
}

export default class PersonalInfo extends BaseComponent<IPersonalInfoProps, IPersonalInfoState> {

    state: IPersonalInfoState = {
        countries: [],
        policies: [],
        isValidPhoneNumber: true,
    }

    private readonly _profileModalRef: React.RefObject<ProfileModal> = React.createRef();
    private readonly _agreementRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _registrationRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _cancellationPolicyRef: React.RefObject<Checkbox> = React.createRef();
    
    private async validatePhoneNumberAsync(value: string | null): Promise<boolean> {
        if (value) {
            const request = new ValidatePhoneNumberRequest();
            request.value = value;
            request.countryId = (!!this.user.countryId) ? this.user.countryId : null;
            request.personalOnly = true;

            const response: ValidatePhoneNumberResponse = await this.postAsync("/api/user/validatePhoneNumber", request);
            
            if (response.valid) {

                if ((!this.user.countryId) && (response.country != null)) {
                    
                    await this.setCountryAsync(response.country);
                    
                    await this.reRenderAsync();
                }
                
                return true;
            }
        }

        return false;
    }

    private async setAgreementAcceptedAsync(): Promise<void> {
        User.setAgreementAccepted(this.user, ServiceProviderController.serviceProviderSlug, true);
        await this.reRenderAsync();
    }

    private async setRegistrationAcceptedAsync(): Promise<void> {
        User.setRegistrationAccepted(this.user, ServiceProviderController.serviceProviderSlug, true);
        await this.reRenderAsync();
    }

    private async setCancellationPolicyAcceptedAsync(): Promise<void> {
        User.setCancellationPolicyAccepted(this.user, ServiceProviderController.serviceProviderSlug, true);
        await this.reRenderAsync();
    }

    private async setAdultAsync(value: boolean): Promise<void> {
        User.setAdult(this.user, ServiceProviderController.serviceProviderSlug, value);
        await this.reRenderAsync();
    }

    private async setFirstnameAsync(value: string): Promise<void> {
        this.user.firstname = value;
    }

    private async setLastNameAsync(value: string): Promise<void> {
        this.user.lastName = value;
    }

    private async setCountryAsync(country: Country): Promise<void> {
        this.user.countryId = country.id;
        this.user.country = country;
    }

    private async setBusinessIdAsync(value: string): Promise<void> {
        this.user.businessId = value;
    }
    
    private async setEmailAsync(value: string): Promise<void> {
        this.user.email = value;
    }

    private async setPhoneAsync(value: string): Promise<void> {
        this.user.phone = value;
    }

    private async onBlurAsync(sender: PhoneInput): Promise<void> {
        const isValidPhoneNumber: boolean = await this.validatePhoneNumberAsync(this.user.phone);

        if (isValidPhoneNumber !== this.state.isValidPhoneNumber) {
            this.state.isValidPhoneNumber = isValidPhoneNumber;
            await sender.validateAsync();
        }
    }

    private validatePhoneNumber(): string | null {
        return (!this.state.isValidPhoneNumber)
            ? Localizer.get(BaseRegexValidatorErrorMessage.validatorsPhoneLanguageItemName)
            : null;
    }

    private async submitAsync(): Promise<void> {
        if (!this.adult) {
            await ch.alertWarningAsync(Localizer.signUpPageAdultWarning, true, true);
            return;
        }

        if ((!this.agreementAccepted) || (!this.registrationAccepted) || (!this.cancellationPolicyAccepted)) {
            await ch.alertErrorAsync(Localizer.additionalInfoAlertErrorSumbit, true, true);
            return;
        }

        if ((this.asCaptain) && (!this.user.avatar)) {
            await ch.alertWarningAsync(Localizer.signUpPageAlertWarningCaptainAvatarIsMandatory, true, true);
            return;
        }

        await this.props.onNext(this);
    }

    private async openProfileModalAsync(image: FileModel | null): Promise<void> {
        if (this._profileModalRef.current) {
            await this._profileModalRef.current!.onOpenAsync(image);
        }
    }

    private async updateAvatarAsync(avatar: FileModel | null): Promise<void> {
        this.user.avatar = avatar;

        if (avatar == null) {
            this.user.avatarId = "";
        }

        await this.reRenderAsync();
    }
    
    private getPolicyTitle(type: UserPolicyType): string {
        const policy: ServiceProviderPolicy | null = this.state.policies?.firstOrDefault(item => item.type == type) ?? null;
        return LocalizationString.value(policy?.title);
    }
    
    private getPolicyContent(type: UserPolicyType): string {
        const policy: ServiceProviderPolicy | null = this.state.policies?.firstOrDefault(item => item.type == type) ?? null;
        return LocalizationString.value(policy?.content);
    }

    public get user(): User {
        return this.props.user;
    }

    public get adult(): boolean {
        return User.isAdult(this.user, ServiceProviderController.serviceProviderSlug);
    }

    public get agreementAccepted(): boolean {
        return User.isAgreementAccepted(this.user, ServiceProviderController.serviceProviderSlug);
    }

    public get registrationAccepted(): boolean {
        return User.isRegistrationAccepted(this.user, ServiceProviderController.serviceProviderSlug);
    }

    public get cancellationPolicyAccepted(): boolean {
        return User.isCancellationPolicyAccepted(this.user, ServiceProviderController.serviceProviderSlug);
    }

    public get asCaptain(): boolean {
        return this.props.asCaptain;
    }

    public get singleCountry(): boolean {
        return (
            (this.countries.length == 1) ||
            (ServiceProviderController?.serviceProviderSlug?.countryIds?.length == 1)
        );
    }

    public get countries(): Country[] {
        return this.state.countries;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const serviceProviderId: string | null = ServiceProviderController.serviceProviderSlug?.id || null;

        const [countries, policies] = await Promise.all([
            AppController.listCountriesAsync(serviceProviderId, true),
            ServiceProviderController.listServiceProviderPoliciesAsync(),
        ]);
        
        if (countries.length == 1) {
            const country: Country = countries.single();

            await this.setCountryAsync(country);
        } else {
            const coordinates: GeoCoordinate | null = await Utility.getLocationAsync();

            if (coordinates != null) {
                const country: Country | null = await this.postAsync("/api/user/findCountry", coordinates);

                if (country != null) {
                    await this.setCountryAsync(country);
                }
            }
        }

        await this.setState({countries, policies});
    }

    public render(): React.ReactNode {
        
        const termsTitle: string = (this.asCaptain)
            ? this.getPolicyTitle(UserPolicyType.CaptainTerms)
            : this.getPolicyTitle(UserPolicyType.PassengerTerms);
        
        const termsContent: string = (this.asCaptain)
            ? this.getPolicyContent(UserPolicyType.CaptainTerms)
            : this.getPolicyContent(UserPolicyType.PassengerTerms);
        
        const privacyPolicyTitle: string = this.getPolicyTitle(UserPolicyType.PrivacyPolicy);
        const privacyPolicyContent: string = this.getPolicyContent(UserPolicyType.PrivacyPolicy);
        
        const cancellationPolicyTitle: string = this.getPolicyTitle(UserPolicyType.CancellationPolicy);
        const cancellationPolicyContent: string = this.getPolicyContent(UserPolicyType.CancellationPolicy);
        
        const asCaptainStyle = this.asCaptain && styles.asCaptain;
        
        return (
            <div className={this.css(styles.personalInfo, asCaptainStyle)}>
                
                <div className={styles.imageContainer}>

                    <div className={styles.avatar}>

                        <img alt={Localizer.genericProfileImage}
                             src={ImageProvider.getImageSrc(this.user.avatar) || thumbnail}
                             onClick={() => this.openProfileModalAsync(this.user.avatar)}
                        />

                    </div>

                </div>

                <Form className={styles.form} id="form" onSubmit={() => this.submitAsync()}>

                    <Inline justify={JustifyContent.SpaceBetween} className={this.css(styles.inline, styles.icon)}>

                        <Icon name={"far fa-user"} size={IconSize.X2}/>

                        <TextInput id="firstname" trim required autoFocus noAutoComplete
                                   placeholder={Localizer.formInputFirstname + " *"}
                                   value={this.user.firstname}
                                   onChange={(sender, value) => this.setFirstnameAsync(value)}
                        />

                        <TextInput id="lastName" trim required noAutoComplete
                                   placeholder={Localizer.formInputLastname + " *"}
                                   value={this.user.lastName}
                                   onChange={(sender, value) => this.setLastNameAsync(value)}
                        />

                    </Inline>

                    <Inline className={this.css(styles.inline)}>
                        <Checkbox id="adult"
                                  inline
                                  inlineType={InlineType.Right}
                                  label={Localizer.signUpPageAdultLabel}
                                  value={this.adult}
                                  onChange={(_, value) => this.setAdultAsync(value)}
                        />
                    </Inline>

                    {
                        (!this.singleCountry) &&
                        (
                            <Inline justify={JustifyContent.SpaceBetween} className={this.css(styles.inline, styles.icon)}>

                                <Icon name={"far fa-globe-europe"} size={IconSize.X2}/>

                                <Dropdown id="country" required
                                          disabled={(this.countries.length == 0)}
                                          requiredType={DropdownRequiredType.Restricted}
                                          nothingSelectedText={Localizer.formInputCountry + " *"}
                                          items={this.countries}
                                          selectedItem={this.user.country}
                                          onChange={(_, item) => this.setCountryAsync(item!)}
                                />

                            </Inline>
                        )
                    }

                    <Inline justify={JustifyContent.SpaceBetween} className={this.css(styles.inline, styles.icon)}>

                        <Icon name={"far fa-envelope"} size={IconSize.X2}/>

                        <EmailInput id="email" required trim noAutoComplete
                                    placeholder={Localizer.formInputEmail + " *"}
                                    value={this.user.email}
                                    onChange={(sender, value) => this.setEmailAsync(value)}
                        />

                    </Inline>

                    <Inline justify={JustifyContent.SpaceBetween} className={this.css(styles.inline, styles.icon)}>

                        <Icon name={"far fa-mobile-android"} size={IconSize.X2}/>

                        <PhoneInput id="phone" required trim noAutoComplete
                                    placeholder={Localizer.formInputPhone + " *"}
                                    validators={[() => this.validatePhoneNumber()]}
                                    value={this.user.phone}
                                    onBlur={(sender) => this.onBlurAsync(sender as PhoneInput)}
                                    onChange={(_, value) => this.setPhoneAsync(value)}
                        />

                    </Inline>

                    {
                        (this.asCaptain) &&
                        (
                            <Inline justify={JustifyContent.SpaceBetween}
                                    className={this.css(styles.inline, styles.icon)}>

                                <Icon name={"far fa-id-badge"} size={IconSize.X2}/>

                                <TextInput id="businessId" required trim noAutoComplete
                                           placeholder={Localizer.userManagementPageTextInputBusinessIdLabel + " *"}
                                           value={this.user.businessId}
                                           onChange={(sender, value) => this.setBusinessIdAsync(value)}
                                />

                            </Inline>
                        )
                    }

                    <Inline className={this.css(styles.inline)}>

                        <Checkbox ref={this._agreementRef}
                                  id="agreementAccepted"
                                  label={Localizer.myAccountPageAcceptance}
                                  inline
                                  inlineType={InlineType.Right}
                                  value={this.agreementAccepted}
                                  readonly={this.agreementAccepted}
                                  onChange={() => this.setAgreementAcceptedAsync()}
                        />

                        <Button className={"ml-n2"}
                                label={Localizer.myAccountPageAcceptanceTerms}
                                type={ButtonType.Text}
                                disabled={!termsTitle}
                                toggleModal
                                dataTarget="agreementModal"
                        />

                    </Inline>

                    <Inline className={this.css(styles.inline)}>

                        <Checkbox ref={this._registrationRef}
                                  id="registrationAccepted"
                                  label={Localizer.myAccountPageAcceptance}
                                  inline
                                  inlineType={InlineType.Right}
                                  value={this.registrationAccepted}
                                  readonly={this.registrationAccepted}
                                  onChange={() => this.setRegistrationAcceptedAsync()}
                        />

                        <Button className={"ml-n2"}
                                label={Localizer.myAccountPageAcceptancePrivacyNotice}
                                type={ButtonType.Text}
                                disabled={!privacyPolicyTitle}
                                toggleModal
                                dataTarget="privacyNoticeModal"
                        />

                    </Inline>

                    <Inline justify={JustifyContent.Start} className={this.css(styles.inline)}>

                        <Checkbox ref={this._cancellationPolicyRef}
                                  id="cancellationPolicyAccepted"
                                  inline
                                  inlineType={InlineType.Right}
                                  label={Localizer.myAccountPageAcceptance}
                                  value={this.cancellationPolicyAccepted}
                                  readonly={this.cancellationPolicyAccepted}
                                  onChange={() => this.setCancellationPolicyAcceptedAsync()}
                        />

                        <Button className={"ml-n2"}
                                label={Localizer.myAccountPageAcceptanceCancellationPolicy}
                                type={ButtonType.Text}
                                disabled={!cancellationPolicyTitle}
                                toggleModal
                                dataTarget="cancellationPolicyModal"
                        />

                    </Inline>

                    <div className={styles.expander}/>

                    <ButtonContainer className={styles.buttons}>

                        <Button type={ButtonType.Dark}
                                right={false}
                                label={Localizer.additionalInfoButtonSignUpLabel}
                                icon={{name: "fal user-plus"}}
                                {...this.props.nextButton}
                                submit
                        />

                        <Button type={ButtonType.Primary}
                                label={Localizer.genericBack}
                                icon={{name: "fal arrow-alt-left"}}
                                {...this.props.prevButton}
                                onClick={() => this.props.onPrev(this)}
                        />

                    </ButtonContainer>

                </Form>

                <ProfileModal ref={this._profileModalRef}
                              onClose={(image) => this.updateAvatarAsync(image)}
                />

                {
                    (termsContent) &&
                    (
                        <Modal id="agreementModal" info keepTextFormatting
                               title={termsTitle}
                               content={termsContent}
                               size={ModalSize.Large}
                        />
                    )
                }

                {
                    (privacyPolicyContent) &&
                    (
                        <Modal id="privacyNoticeModal" info keepTextFormatting
                               title={privacyPolicyTitle}
                               content={privacyPolicyContent}
                               size={ModalSize.Large}
                        />
                    )
                }

                {
                    (cancellationPolicyContent) &&
                    (
                        <Modal id="cancellationPolicyModal" info keepTextFormatting
                               title={cancellationPolicyTitle}
                               content={cancellationPolicyContent}
                               size={ModalSize.Large}
                        />
                    )
                }

            </div>
        );
    }
}
// autogenerated

export default class ListIntermediateWaypointsRequest {

    public waypointId: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListIntermediateWaypointsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListIntermediateWaypointsRequest) || ((from as any).isListIntermediateWaypointsRequest === true));
    }

    public static as(from?: object | null): ListIntermediateWaypointsRequest | null {
        return (ListIntermediateWaypointsRequest.is(from)) ? from as ListIntermediateWaypointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
import React from "react";
import {BaseComponent, LocalizationString} from "@reapptor-apps/reapptor-react-common";
import {Checkbox, Form, OneColumn, Panel, ThreeColumns,} from "@reapptor-apps/reapptor-react-components";
import LocalizationTextInput, {
    LocalizationTextInputType
} from "@/components/LocalizationTextInput/LocalizationTextInput";
import ServiceProvider from "@/models/server/ServiceProvider";
import Localizer from "@/localization/Localizer";

import styles from "./OutOfServicePanel.module.scss"

interface IOutOfServicePanelProps {
    serviceProvider: ServiceProvider;
}

interface IOutOfServicePanelState {
}

export default class OutOfServicePanel extends BaseComponent<IOutOfServicePanelProps, IOutOfServicePanelState> {

    state: IOutOfServicePanelState = {
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    
    private async setOutOfServiceAsync(value: boolean): Promise<void> {
        this.serviceProvider.outOfService = value;
        
        if (!value) {
            this.serviceProvider.outOfServiceMessage = null;
        }
        
        await this.reRenderAsync();
    }
    
    private async setOutOfServiceMessageAsync(value: LocalizationString): Promise<void> {
        this.serviceProvider.outOfServiceMessage = value;
    }
    
    public get isNew(): boolean {
        return (!this.serviceProvider.id);
    }

    public get serviceProvider(): ServiceProvider {
        return this.props.serviceProvider;
    }

    public async isFormValidAsync(): Promise<boolean> {
        return this._formRef.current?.validateAsync() || false;
    }

    public render(): React.ReactNode {
        return (
            <Panel className={this.css("flex-2", styles.waterOperatorPanel)}>
                <div className="col-12">

                    <Form ref={this._formRef} className={styles.form}>

                        <ThreeColumns>
                            
                            <Checkbox id={nameof(this.serviceProvider.outOfService)}
                                      label={Localizer.serviceProvidersManagementPageOutOfServiceLanguageItemName}
                                      value={this.serviceProvider.outOfService}
                                      onChange={(_, value) => this.setOutOfServiceAsync(value)}
                            />
                            
                        </ThreeColumns>

                        {
                            (this.serviceProvider.outOfService) &&
                            (
                                <OneColumn>

                                    <LocalizationTextInput required noAutoComplete
                                                           id={nameof(this.serviceProvider.outOfServiceMessage)}
                                                           type={LocalizationTextInputType.TextAreaInput}
                                                           label={Localizer.serviceProvidersManagementPageOutOfServiceLabelLanguageItemName}
                                                           value={this.serviceProvider.outOfServiceMessage}
                                                           onChange={(_, value) => this.setOutOfServiceMessageAsync(value)}
                                    />
                                    
                                </OneColumn>
                            )
                        }

                    </Form>

                </div>

            </Panel>
        );
    }
};
import React from "react";
import {BaseComponent, IBaseContainerComponentProps} from "@reapptor-apps/reapptor-react-common";
import SkeletonRectangle from "@/pages/Mobile/BoatSelection/Skeleton/SkeletonRectangle/SkeletonRectangle";
import SkeletonSquare from "@/pages/Mobile/BoatSelection/Skeleton/SkeletonSquare/SkeletonSquare";
import SkeletonCircle from "@/pages/Mobile/BoatSelection/Skeleton/SkeletonCircle/SkeletonCircle";

import styles from "./Skeleton.module.scss";

export interface ISkeletonProps extends IBaseContainerComponentProps {
}

export default class Skeleton extends BaseComponent<ISkeletonProps> {

    public static get Rectangle(): typeof SkeletonRectangle {
        return SkeletonRectangle;
    }

    public static get Square(): typeof SkeletonSquare {
        return SkeletonSquare;
    }

    public static get Circle(): typeof SkeletonCircle {
        return SkeletonCircle;
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={this.css(styles.skeleton, this.props.className)}>
                { this.children }
            </div>
        )
    }
}
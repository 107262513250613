// autogenerated

export default class SaveWaypointRequest {

    public id: string | null = null;

    public sourceId: string = "";

    public destinationId: string = "";

    public readonly isSaveWaypointRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof SaveWaypointRequest) || ((from as any).isSaveWaypointRequest === true));
    }

    public static as(from?: object | null): SaveWaypointRequest | null {
        return (SaveWaypointRequest.is(from)) ? from as SaveWaypointRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";

export default class ListShuttleLegInstancesRequest {

    public serviceProviderId: string | null = null;

    public countryId: string | null = null;

    public areaId: string | null = null;

    public from: Date | null = null;

    public to: Date | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public readonly isListShuttleLegInstancesRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ListShuttleLegInstancesRequest) || ((from as any).isListShuttleLegInstancesRequest === true));
    }

    public static as(from?: object | null): ListShuttleLegInstancesRequest | null {
        return (ListShuttleLegInstancesRequest.is(from)) ? from as ListShuttleLegInstancesRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
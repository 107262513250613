import React from "react";
import {BasePageParameters, LdData, LocalizationString, ReactUtility} from "@reapptor-apps/reapptor-react-common";
import {UserPolicyType} from "@/models/Enums";
import AnonymousPage from "../../models/base/AnonymousPage";
import {PageContainer, PageHeader, PageRow, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import ServiceProviderPolicy from "@/models/server/bout/ServiceProviderPolicy";
import ServiceProviderController from "@/pages/ServiceProviderController";
import AppController from "@/pages/AppController";
import Localizer from "../../localization/Localizer";

import styles from "./PrivacyPolicy.module.scss";

export interface IPrivacyPolicyParameters extends BasePageParameters {
}

interface IPrivacyPolicyState {
    applicationLdData: LdData | null;
    policies: ServiceProviderPolicy[];
}

export default class PrivacyPolicy extends AnonymousPage<IPrivacyPolicyParameters> {

    state: IPrivacyPolicyState = {
        applicationLdData: null,
        policies: [],
    };

    private getPolicyTitle(type: UserPolicyType): string {
        const policy: ServiceProviderPolicy | null = this.state.policies?.firstOrDefault(item => item.type == type) ?? null;
        return LocalizationString.value(policy?.title);
    }

    private getPolicyContent(type: UserPolicyType): string {
        const policy: ServiceProviderPolicy | null = this.state.policies?.firstOrDefault(item => item.type == type) ?? null;
        return LocalizationString.value(policy?.content);
    }
    
    public getTitle(): string {
        return AppController.getFullPageTitle(Localizer.privacyPolicyPageTitle);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const pageUrl: string = window.location.origin;
        
        const [applicationLdData, policies] = await Promise.all([
            this.postAsync<LdData>("/api/application/getApplicationLdData", pageUrl),
            ServiceProviderController.listServiceProviderPoliciesAsync(),
        ]);

        await this.setState({applicationLdData, policies});
    }

    public render(): React.ReactNode {

        const privacyPolicyTitle: string = this.getPolicyTitle(UserPolicyType.PrivacyPolicy);
        const privacyPolicyContent: string = this.getPolicyContent(UserPolicyType.PrivacyPolicy);

        return (
            <PageContainer className={styles.privacyPolicy}>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={"/privacy"}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />

                <PageHeader title={Localizer.privacyPolicyPageTitle} 
                            subtitle={privacyPolicyTitle}
                />

                <PageRow>
                    <div className="col-lg-12">
                        
                        <span className={styles.container}>
                            {ReactUtility.toMultiLines(privacyPolicyContent)}
                        </span>

                    </div>
                </PageRow>
                
            </PageContainer>
        );
    }
}
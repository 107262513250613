// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {OrganizationContractLevel} from "@/models/Enums";
import Organization from "@/models/server/Organization";

export default class OrganizationContract {

    public id: string = "";

    public name: string = "";

    public internalName: string | null = null;

    public externalId: string = "";

    public externalAddress: string | null = null;

    public transactionIdentifier: string | null = null;

    public intermediator: string | null = null;

    public level: OrganizationContractLevel = OrganizationContractLevel.Operator;

    public organizationId: string = "";

    public location: GeoLocation | null = null;

    public virtualAddress: string | null = null;

    public organization: Organization | null = null;

    public readonly isOrganizationContract: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof OrganizationContract) || ((from as any).isOrganizationContract === true));
    }

    public static as(from?: object | null): OrganizationContract | null {
        return (OrganizationContract.is(from)) ? from as OrganizationContract : null;
    }

    // #region Placeholder

    public favorite: boolean = false;

    // #endregion
}
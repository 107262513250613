// autogenerated
import {BoatType} from "@/models/Enums";
import Waypoint from "@/models/server/bout/Waypoint";
import Country from "@/models/server/bout/Country";
import EstimatedBoatBooking from "@/models/server/bout/EstimatedBoatBooking";
import BaseBooking from "@/models/server/bout/BaseBooking";

export default class EstimatedBooking extends BaseBooking {

    public waypoint: Waypoint | null = null;

    public waypointId: string = "";

    public boatPriceId: string = "";

    public boatPriceName: string = "";

    public boatType: BoatType = BoatType.Small;

    public countryId: string = "";

    public country: Country | null = null;

    public passengers: number = 0;

    public children: number = 0;

    public bikesAndTrolleys: number = 0;

    public pensioners: number = 0;

    public minCapacity: number = 0;

    public maxCapacity: number = 0;

    public maxBoatCapacity: number = 0;

    public captainNotAssignedNotification: number = 0;

    public minPrice: number = 0;

    public maxPrice: number = 0;

    public minNetTimeInMinutes: number = 0;

    public maxNetTimeInMinutes: number = 0;

    public minTimeInMinutes: number = 0;

    public maxTimeInMinutes: number = 0;

    public netDistance: number = 0;

    public minDistance: number = 0;

    public maxDistance: number = 0;

    public averageDistance: number = 0;

    public pricePerLiter: number = 0;

    public boats: EstimatedBoatBooking[] = [];

    public readonly isEstimatedBooking: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof EstimatedBooking) || ((from as any).isEstimatedBooking === true));
    }

    public static as(from?: object | null): EstimatedBooking | null {
        return (EstimatedBooking.is(from)) ? from as EstimatedBooking : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
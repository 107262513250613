// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

export default class ImportWaypointsRequest {

    public file: FileModel | null = null;

    public overrideExisting: boolean = false;

    public generateInverseWaypoints: boolean = false;

    public readonly isImportWaypointsRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof ImportWaypointsRequest) || ((from as any).isImportWaypointsRequest === true));
    }

    public static as(from?: object | null): ImportWaypointsRequest | null {
        return (ImportWaypointsRequest.is(from)) ? from as ImportWaypointsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
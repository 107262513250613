// autogenerated
import Country from "@/models/server/bout/Country";

export default class Area {

    public id: string = "";

    public name: string = "";

    public cancelHours: number = 0;

    public cancelPrice: number = 0;

    public margin: number = 0;

    public countryId: string = "";

    public country: Country | null = null;

    public readonly isArea: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof Area) || ((from as any).isArea === true));
    }

    public static as(from?: object | null): Area | null {
        return (Area.is(from)) ? from as Area : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
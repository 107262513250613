// autogenerated

export default class CaptainAvailableDate {

    public id: string | null = null;

    public captainId: string = "";

    public date: Date = new Date();

    public holiday: boolean = false;

    public boutId: string | null = null;

    public startTime: Date | null = null;

    public endTime: Date | null = null;

    public readonly isCaptainAvailableDate: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof CaptainAvailableDate) || ((from as any).isCaptainAvailableDate === true));
    }

    public static as(from?: object | null): CaptainAvailableDate | null {
        return (CaptainAvailableDate.is(from)) ? from as CaptainAvailableDate : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
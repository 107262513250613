import React from "react";
import {GeoCoordinate} from "@reapptor-apps/reapptor-toolkit";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {AddressHelper, GoogleMap, IGoogleMapMarker} from "@reapptor-apps/reapptor-react-components";
import ServicePoint from "@/models/server/bout/ServicePoint";
import Waypoint from "@/models/server/bout/Waypoint";
import IntermediateWaypoint from "@/models/server/bout/IntermediateWaypoint";
import CruisePackagePoint from "@/models/server/cruise/CruisePackagePoint";
import AppConstants from "@/helpers/AppConstants";
import TransformProvider from "@/providers/TransformProvider";

import destinationMarker from "../../img/DestinationServicePointYellow.svg";
import sourceMarker from "../../img/SourceServicePoint.svg";

import styles from "./WaypointMap.module.scss";

interface IWaypointMapProps {
    id?: string;
    className?: string;
    point?: Waypoint | CruisePackagePoint | null;
    center?: GeoCoordinate;
    zoom?: number;
    intermediatePointMarkers?: IGoogleMapMarker[];
    fullscreenControl?: boolean;
    mapTypeControl?: boolean;
    streetViewControl?: boolean;
    readonly?: boolean;

    onServicePointClick?(sender: WaypointMap, servicePoint: ServicePoint): Promise<void>;
    onClick?(coordinates: GeoCoordinate): Promise<void>;
}

interface IWaypointMapState {
}

export default class WaypointMap extends BaseComponent<IWaypointMapProps, IWaypointMapState> {

    state: IWaypointMapState = {
    };

    private async onMarkerClickAsync(servicePoint: ServicePoint): Promise<void> {
        if (this.props.onServicePointClick) {
            await this.props.onServicePointClick(this, servicePoint);
        }
    }

    private async onClickAsync(coordinates: GeoCoordinate): Promise<void> {
        if (this.props.onClick) {
            await this.props.onClick(coordinates);
        }
    }

    public get point(): Waypoint | CruisePackagePoint | null {
        return this.props.point || null;
    }

    public get waypoint(): Waypoint | null {
        return Waypoint.as(this.point);
    }

    public get cruisePackagePoint(): CruisePackagePoint | null {
        return CruisePackagePoint.as(this.point);
    }

    public get intermediateWaypoints(): IntermediateWaypoint[] {
        return this.waypoint?.intermediateWaypoints ?? [];
    }

    public get readonly(): boolean {
        return (this.props.readonly === true);
    }

    public get markers(): IGoogleMapMarker[] {
        if (this.props.intermediatePointMarkers) {
            return this.props.intermediatePointMarkers;
        }
        
        const waypoint: Waypoint | null = this.waypoint;
        
        if (waypoint) {
            const source: ServicePoint = waypoint.source!;
            const destination: ServicePoint = waypoint.destination!;

            return [
                TransformProvider.toMarker(source, sourceMarker, null, null, null, () => this.onMarkerClickAsync(source)),
                TransformProvider.toMarker(destination, destinationMarker, null, null, null, () => this.onMarkerClickAsync(destination)),
            ]
        }

        const cruisePackagePoint: CruisePackagePoint | null = this.cruisePackagePoint;
        if (cruisePackagePoint?.location) {
            return [
                TransformProvider.toMarker(cruisePackagePoint.location, sourceMarker, null, null, null),
            ]
        }

        return [];
    }
    
    public get route(): GeoCoordinate[] {
        return Waypoint.getRoute(this.waypoint)
    }

    public get path(): google.maps.LatLng[] {
        return this.route.map(item => AddressHelper.toGoogleCoordinate(item));
    }

    public get center(): GeoCoordinate {
        const cruisePackagePoint: CruisePackagePoint | null = this.cruisePackagePoint;

        return (this.props.center)
            ? this.props.center
            : (this.waypoint)
                ? AddressHelper.getCenter(this.route)
                : (cruisePackagePoint?.location)
                    ? cruisePackagePoint.location
                    : AppConstants.defaultLocation;
    }

    public get zoom(): number {
        const aspectRate: number = 338 / 120;
        return (this.props.zoom)
            ? this.props.zoom
            : (this.waypoint)
                ? AddressHelper.findZoom(this.route, 2, aspectRate)
                : 13;
    }
    
    public get fullscreenControl(): boolean {
        return (this.props.fullscreenControl === true);
    }
    
    public get mapTypeControl(): boolean {
        return (this.props.mapTypeControl === true);
    }
    
    public get streetViewControl(): boolean {
        return (this.props.streetViewControl === true);
    }

    public render(): React.ReactNode {
        return (
            <div id={this.id} className={styles.waypointMap}>
                
                <GoogleMap height={"100%"}
                           streetViewControl={this.streetViewControl}
                           mapTypeControl={this.mapTypeControl}
                           fullscreenControl={this.fullscreenControl}
                           className={this.css(styles.waypointMap, this.props.className)}
                           center={this.center}
                           zoom={this.zoom}
                           markers={() => this.markers}
                           polyLinePath={() => this.path}
                           draggable={!this.readonly}
                           scrollWheel={!this.readonly}
                           onClick={(_, coordinate: GeoCoordinate) => this.onClickAsync(coordinate)}
                />

            </div>
        )
    }
}
import React from "react";
import {
    ch,
    IGlobalResize,
    LdData,
    PageRoute,
    ReactUtility
} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import {Button, ButtonType, LanguageDropdown, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import PageDefinitions from "@/providers/PageDefinitions";
import ApplicationContext from "@/models/server/ApplicationContext";
import AppController from "@/pages/AppController";
import ServiceProviderController from "@/pages/ServiceProviderController";
import Localizer from "@/localization/Localizer";

import styles from "./Landing.module.scss";

import pjtaLogo from "./images/pjta_logo.svg";
import pjtaSlide1 from "./images/taxi_seine_paris1.jpeg";
import pjtaSlide2 from "./images/taxi_seine_paris3.jpeg";
import pjtaSlide3 from "./images/taxi_seine_paris2.jpeg";
import apple from "./images/apple.png";
import google from "./images/google.png";
import FaqPanel from "@/components/FaqPanel/FaqPanel";

interface ILandingState {
    applicationLdData: LdData | null;
}

export default class Landing extends AnonymousPage implements IGlobalResize {

    state: ILandingState = {
        applicationLdData: null,
    };

    private readonly _homeButtonRef: React.RefObject<Button> = React.createRef();
    private readonly _loginButtonRef: React.RefObject<Button> = React.createRef();
    private readonly _signUpButtonRef: React.RefObject<Button> = React.createRef();

    private static get isAvailable(): boolean {
        return ((ServiceProviderController.isPjta) && (!ch.mobileApp) && (!ch.isAuthenticated));
    }

    public static routeResolver(pathname: string): PageRoute | null {
        pathname = pathname.toLowerCase();
        pathname = `/${ServiceProviderController.trimUrl(pathname)}`;
        if ((pathname.endsWith("/landing")) && (Landing.isAvailable)) {
            return PageDefinitions.landingRoute;
        }
        return null;
    }

    private get isLandscape(): boolean {
        return (window.innerWidth > window.innerHeight);
    }

    public async onGlobalResize(e: React.SyntheticEvent): Promise<void> {
        await this.reRenderAsync();
    }

    public get hasTopNav(): boolean {
        return false;
    }

    public get hasLeftNav(): boolean {
        return false;
    }

    public get hasFooter(): boolean {
        return false;
    }

    public getTitle(): string {
        return ServiceProviderController.applicationName;
    }

    public getInternalLink(pathname: string): string {
        const rootUrl: string = ch.applicationUrl;
        const currentLanguage: string = Localizer.language;
        const defaultLanguage: string = ServiceProviderController.serviceProviderSlug?.language ?? Localizer.defaultLanguage;

        const neutralCanonicalUrl: string = rootUrl.endsWith('/') 
            ? ch.applicationUrl.concat(pathname)
            : ch.applicationUrl.concat('/', pathname);

        return (defaultLanguage != currentLanguage)
            ? `${neutralCanonicalUrl}/?lang=${currentLanguage}`
            : neutralCanonicalUrl;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const context: ApplicationContext = this.getContext();

        const applicationLdData: LdData = await this.getAsync(`/api/application/getLandingPageLdData?versionId=${context.versionId}&lang=${Localizer.language}`);

        await this.setState({applicationLdData});
    }

    private renderAppButtons(): React.ReactNode {
        return (
            <div className={styles.buttons}>

                {
                    (ServiceProviderController.appleAppAvailable) &&
                    (
                        <a href={"https://apps.apple.com/fi/app/taxi-seine-paris/id6496356204"} target={"_blank"}>
                            <img src={apple}
                                 alt={"Download on the AppStore"}
                            />
                        </a>
                    )
                }

                {
                    (ServiceProviderController.androidAppAvailable) &&
                    (
                        <a href={"https://play.google.com/store/apps/details?id=com.app.bout.cop"} target={"_blank"}>
                            <img src={google}
                                 alt={"Get it on GooglePlay"}
                            />
                        </a>
                    )
                }

            </div>
        )
    }

    private renderFooter(): React.ReactNode {
        return (
            <div className={styles.footer}>

                <a href={"https://www.bout.fi/"} title={"Bout Oy Ab"}>
                    {Localizer.landingPageProvidedBy}
                </a>

                <a href={"https://www.reapptor.com/"} title={"ReApptor Oy"}>
                    {Localizer.landingPagePoweredBy}
                </a>

            </div>
        )
    }

    private async preventDefaultAsync(event: React.MouseEvent, buttonRef: React.RefObject<Button>): Promise<void> {
        event.preventDefault();

        if (buttonRef.current?.props.onClick) {
            await buttonRef.current.props.onClick(buttonRef.current, null);
        }
    }

    private renderTopNav(landscape: boolean): React.ReactNode {
        return (
            <div className={styles.topNav}>

                {
                    (landscape)
                        ?
                        <a id="home"
                           href={"https://parisjetaime.com/"}
                        >

                            <Button ref={this._homeButtonRef}
                                    className={styles.home}
                                    icon={"fas fa-home"}
                                    type={ButtonType.Primary}
                            />

                        </a>
                        : <a href="https://parisjetaime.com/" className={styles.logo}>
                            <img src={pjtaLogo} alt={"Paris Je T’aime"}/>
                        </a>
                }

                <div className={styles.expander}/>

                <a id="login"
                   href={this.getInternalLink("login")}
                   onClick={(event) => this.preventDefaultAsync(event, this._loginButtonRef)}>

                    <Button ref={this._loginButtonRef}
                            label={Localizer.landingPageLogIn}
                            type={ButtonType.Link}
                            route={PageDefinitions.loginRoute}
                    />

                </a>

                <a id="signUp"
                   href={this.getInternalLink("signup")}
                   onClick={(event) => this.preventDefaultAsync(event, this._signUpButtonRef)}>

                    <Button ref={this._signUpButtonRef}
                            label={(ServiceProviderController.passengerSignUpAvailable) ? Localizer.landingPageSignUp : Localizer.landingPageLaunchingSoon}
                            disabled={(!ServiceProviderController.passengerSignUpAvailable)}
                            type={ButtonType.Dark}
                            route={PageDefinitions.signUp(true)}
                    />

                </a>

                {
                    (landscape) &&
                    (
                        <a href="https://parisjetaime.com/" className={styles.logo}>
                            <img src={pjtaLogo} alt={"Paris Je T’aime"}/>
                        </a>

                    )
                }

                <LanguageDropdown className={styles.language}
                                  languages={AppController.languages}
                                  currentLanguage={Localizer.language}
                                  onChange={(_, language) => ch.setLanguageAsync(language)}
                />

            </div>
        );
    }

    private renderLandscape(): React.ReactNode {
        return (
            <div className={styles.landscape}>

                <div className={styles.slide1} id="slide1">

                    {this.renderTopNav(true)}

                    <div className={styles.content}>

                        <div className={styles.header1}>

                            <span>{ReactUtility.toMultiLines(Localizer.landingPageSlide1Header1)}</span>
                            <span>{ReactUtility.toMultiLines(Localizer.landingPageSlide1Header2)}</span>

                        </div>

                        <div className={styles.header2}>
                            
                            <span>
                                {ReactUtility.toMultiLines(Localizer.landingPageSlide1Header3)}
                            </span>

                        </div>

                        {this.renderAppButtons()}

                    </div>

                </div>

                <div className={styles.slide2} id="slide2">

                    <div className={styles.left}>

                        <div className={styles.content}>
                            
                            <span className={styles.header1}>
                                {ReactUtility.toMultiLines(Localizer.landingPageSlide2Header1)}
                            </span>

                            <span className={styles.header2}>
                                {ReactUtility.toMultiLines(Localizer.landingPageSlide2Header2)}
                             </span>

                            <span className={styles.header3}>
                                {ReactUtility.toMultiLines(Localizer.landingPageSlide2Header3)}
                            </span>

                        </div>

                        <div className={styles.expander}/>

                        {this.renderAppButtons()}

                    </div>

                    <div className={styles.right}>

                        <img src={pjtaSlide2}
                             alt={""}
                             className={styles.slide2}
                        />

                        <img src={pjtaSlide3}
                             alt={""}
                             className={styles.slide3}
                        />

                        <img src={pjtaSlide1}
                             alt={""}
                             className={styles.slide4}
                        />

                    </div>

                </div>

                <div className={styles.slide3} id="slide3">

                    <FaqPanel className={styles.faq}/>

                </div>

                {this.renderFooter()}

            </div>
        );
    }

    private renderPortrait(): React.ReactNode {
        return (
            <div className={styles.portrait}>

                <div className={styles.slide1} id="slide1">

                    {this.renderTopNav(false)}

                    <div className={styles.content}>

                        <div className={styles.header1}>

                            <span>{Localizer.landingPageSlide1Header1}</span>
                            <span>{Localizer.landingPageSlide1Header2}</span>

                        </div>

                        <div>

                            <img className={styles.image} src={pjtaSlide1}
                                 alt={"Paris Je T’aime"}
                            />

                        </div>

                        <div className={styles.header2}>
                            
                            <span>
                                {Localizer.landingPageSlide1Header3}
                            </span>

                        </div>

                    </div>

                    <div className={styles.expander}/>

                    {this.renderAppButtons()}

                </div>

                <div className={styles.slide2} id="slide2">

                    <div className={styles.content}>
                            
                        <span className={styles.header1}>
                            {ReactUtility.toMultiLines(Localizer.landingPageSlide2Header1)}
                        </span>

                        <span className={styles.header2}>
                                {ReactUtility.toMultiLines(Localizer.landingPageSlide2Header2)}
                        </span>

                        <div className={styles.slides}>

                            <img src={pjtaSlide2}
                                 alt={""}
                                 className={styles.slide1}
                            />

                            <img src={pjtaSlide3}
                                 alt={""}
                                 className={styles.slide2}
                            />

                        </div>

                        <div className={styles.header3}>
                            
                            <span>
                                {ReactUtility.toMultiLines(Localizer.landingPageSlide2Header3)}
                            </span>

                        </div>

                    </div>

                    <div className={styles.expander}/>

                    {this.renderAppButtons()}

                    {this.renderFooter()}

                </div>

                <div className={styles.slide3} id="slide3">

                    <FaqPanel className={styles.faq}/>

                </div>

            </div>
        );
    }

    public render(): React.ReactNode {
        return (
            <div className={this.css(styles.landing, styles.pjta)}>

                <LdDataHeader data={this.state.applicationLdData}
                              canonical={"/"}
                              defaultLanguage={ServiceProviderController.applicationDefaultLanguage}
                />

                {
                    (this.isLandscape)
                        ? this.renderLandscape()
                        : this.renderPortrait()
                }

            </div>
        );
    }
}
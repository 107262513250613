// autogenerated
import BookTripRequest from "./BookTripRequest";

export default class RequestTripRequest extends BookTripRequest {

    public adult: boolean = false;

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public cancellationPolicyAccepted: boolean = false;

    public readonly isRequestTripRequest: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof RequestTripRequest) || ((from as any).isRequestTripRequest === true));
    }

    public static as(from?: object | null): RequestTripRequest | null {
        return (RequestTripRequest.is(from)) ? from as RequestTripRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}
// autogenerated
import {ETagResponse} from "@reapptor-apps/reapptor-react-common";
import EstimatedBooking from "@/models/server/bout/EstimatedBooking";

export default class EstimateBookingsResponse extends ETagResponse {

    public items: EstimatedBooking[] = [];

    public maxAvailableCapacity: number = 0;

    public readonly isEstimateBookingsResponse: true = true;

    public static is(from?: object | null): boolean {
        // noinspection PointlessBooleanExpressionJS
        return (from != null) && ((from instanceof EstimateBookingsResponse) || ((from as any).isEstimateBookingsResponse === true));
    }

    public static as(from?: object | null): EstimateBookingsResponse | null {
        return (EstimateBookingsResponse.is(from)) ? from as EstimateBookingsResponse : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}